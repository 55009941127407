<template>
  <my-dialog v-model="visible" :title="$t('title11006')" width="800px">
    <div class="flex-row">
      <div class="select-view">
        <!-- <div class="warning">{{ $t('title11007') }}</div> -->
        <el-checkbox style="margin-bottom: 20px" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div class="flex-col">
          <div v-for="(checkbox, index) in dataItems" :key="`checkbox${index}`">
            <div class="mb20">{{checkbox.label}}</div>
            <div v-if="checkbox.children && checkbox.children.length">
              <el-checkbox-group v-model="selectArr">
                <el-col :span="8" v-for="item in checkbox.children" :key="item.code" style="padding: 0;margin-bottom: 20px">
                  <el-checkbox :disabled="item.disabled" :label="item.code" @change="(val) => selectItem(item, val)">{{item.label}}</el-checkbox>
                </el-col>
              </el-checkbox-group>
            </div>
            <div v-else>
              <el-checkbox-group v-model="selectArr">
                <el-col :span="8" style="padding: 0;margin-bottom: 20px">
                  <el-checkbox :disabled="checkbox.disabled" :label="checkbox.code" @change="(val) => selectItem(checkbox, val)">{{checkbox.label}}</el-checkbox>
                </el-col>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>
      <div class="sort_view_outside">
        <div class="sort_view flex-col" ref="sort_view">
          <div style="margin-bottom: 16px; color: #999">{{$t('title11008')}}</div>
          <div v-for="item in fixedArr" :key='`fixeditem_${item.code}`' class="sortItem flex-row jc-between">
            <div class="flex-row align-center">
              <img src="@/assets/images/sortIcon.png" style="width: 16px; height: 16px; margin: 0 10px" >
              <span>{{ item.label }}</span>
            </div>
            <div class="flex-row align-center" v-if="!item.disabled">
              <img src="@/assets/images/sort_fixed.png" class="sortIcon" @click="unfixed(item.code)" >
            </div>
          </div>
          <template v-for="(item, index) in sortOption">
            <div class="flex-row jc-between mb10">{{item.label}}</div>
            <div :class="`sort_view${index}`" :key="`sort_view${index}${viewKeys[index]}`">
              <template v-for="(child, j) in item.children">
                <div class="sortItem flex-row jc-between draggable" :class="`draggable${index}`">
                  <div class="flex-row align-center">
                    <img src="@/assets/images/sortIcon.png" style="width: 16px; height: 16px; margin: 0 10px" >
                    <span>{{ child.label }}</span>
                  </div>
                  <div class="flex-row align-center">
                    <img v-if="!child.disabled" src="@/assets/images/sort_delete.png" class="sortIcon" @click="deleteItem(child.code)" >
                    <!-- <img src="@/assets/images/sort_beTop.png" class="sortIcon" @click="rise(child.code, j, index)" > -->
                  </div>
                </div>
              </template>
            </div>
          </template>
          <div class="flex-row jc-between mb10">自定义费用</div>
          <div v-for="item in dataItems.filter(i=>i.code==='totalShopFee')" :key='`fixeditem_${item.code}`' class="sortItem flex-row jc-between">
            <div class="flex-row align-center">
              <img src="@/assets/images/sortIcon.png" style="width: 16px; height: 16px; margin: 0 10px" >
              <span>{{ item.label }}</span>
            </div>
            <div class="flex-row align-center" v-if="!item.disabled">
              <img src="@/assets/images/sort_fixed.png" class="sortIcon" >
            </div>
          </div>
        </div>
      </div>
    </div>
    <template slot="footer">
      <el-button size="mini" @click="cancel">{{ $t('title587') }}</el-button>
      <el-button size="mini" type="primary" @click="submit">{{ $t('title1013') }}</el-button>
    </template>
  </my-dialog>
</template>
<script>
import Sortable from 'sortablejs'
import { deepClone } from '@/utils/index'
export default {
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sortItems: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      sortObj: null,
      sortObjArr: [],
      // 左侧选项
      dataItems: [],
      checkAll: true,
      isIndeterminate: false,
      // 选中code/prop (有空把code改为prop)
      selectArr: [],
      // 右侧选项
      sortOption: [],
      fixedArr: [],
      allChildrenLen: 0,
      viewKeys: []
    }
  },
  created() {
    const arr = this.deepClone(this.sortItems)
    this.dataItems = arr
    this.selectArr = this.setSelectArr(this.dataItems)
    this.fixedArr = this.dataItems.filter(i => i.isFixed)
    let checkedCount = this.selectArr.length
    this.checkAll = checkedCount === this.allChildrenLen
    this.isIndeterminate = checkedCount > 0 && checkedCount < this.allChildrenLen
    this.setSortOption()
  },
  mounted() {
    setTimeout(() => {
      this.getSortable()
    }, 300)
  },
  updated() {
    this.getSortable()
  },
  computed: {
    selectOption() {
      return this.dataItems.filter(i => !i.isFixed)
    },
  },
  methods: {
    deepClone,
    setSelectArr(items) {
      let arr = []
      let defaultArr = items.filter(i => !i.children).map(i => i.code)
      let codes = items.filter(i => i.children && i.children.length).map(i => {
        // i.children = i.children.sort((a, b) => a.sort - b.sort)
        return i.children
      }).flat()
      this.allChildrenLen = codes.length + defaultArr.length
      arr = codes.filter(i => i.sort !== 99)
      return [...defaultArr, ...arr.map(i => i.code)]
    },
    unfixed(code) {
      const target = this.selectOption.find(item => item.code === code)
      this.sortOption.unshift(target)
      this.fixedArr = this.fixedArr.filter(i => i.code !== target.code)
    },
    getSortable () {
      this.sortOption.forEach((item, index) => {
        const _dom = document.querySelector(`.sort_view${index}`)
        const _this = this
        const sortObj = Sortable.create(_dom, {
          draggable: `.draggable${index}`,
          // 拖拽结束
          onEnd ({oldDraggableIndex, newDraggableIndex}) {
            if (oldDraggableIndex !== newDraggableIndex) {
              _this.viewKeys[index]++
              const curRow = item.children.splice(oldDraggableIndex, 1)[0]
              item.children.splice(newDraggableIndex, 0, curRow)
            }
          }
        })
        this.sortObjArr.push(sortObj)
      })
    },
    setSortOption() {
      if (this.selectArr.length > 0) {
        this.sortOption = this.selectOption.map(i => {
          let cloneI = deepClone(i)
          if(cloneI.children&&cloneI.children.length){
            cloneI.children = cloneI.children.filter(child => this.selectArr.includes(child.code)).sort((a, b) => a.sort - b.sort)
          }
          return cloneI
        }).filter(i => i.children && i.children.length)
        this.viewKeys = this.sortOption.map(i => 0)
      }
    },
    handleCheckAllChange(val) {
      const items = this.deepClone(this.sortItems)
      let defaultArr = items.filter(i => !i.children).map(i => i.code)
      let codes = items.filter(i => i.children && i.children.length).map(i => i.children).flat()
      console.log('complex',items)
      if(val){
        this.selectArr = [...defaultArr, ...codes.map(i => i.code)]
      }else{
        this.selectArr = [...defaultArr,...codes.filter(child=>child.disabled).map(i=>i.code)]
      }
      this.setSortOption()
      let checkedCount = this.selectArr.length
      this.checkAll = checkedCount === this.allChildrenLen
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.allChildrenLen
    },
    selectItem(item, val) {
      if (!val) {
        const target = this.fixedArr.find(i => i.code === item.code)
        if (target) {
          this.fixedArr = this.fixedArr.filter(i => i.code !== target.code)
        }
      }
      let checkedCount = this.selectArr.length
      this.checkAll = checkedCount === this.allChildrenLen
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.allChildrenLen
      this.setSortOption()
    },
    deleteItem(code) {
      this.selectArr = this.selectArr.filter(i => i !== code)
      this.setSortOption()
    },
    rise(code, j, index) {
      if (j === 0) return
      let arr = this.sortOption[index].children
      const target = arr[j]
      arr[j] = arr[j-1]
      arr[j-1] = target
      this.sortOption[index].children = arr
      this.viewKeys[index]++
    },
    submit () {
      const {fixedArr,sortOption}=this;
      const sortCodes = [...fixedArr.map(i => `${i.code}:fixed1`),...sortOption.map(i => i.children).flat(1).map(i => i.code),'totalShopFee']
      this.$emit('after-save', sortCodes)
      this.cancel()
    },
    cancel () {
      this.$emit('change', false)
      this.$emit('cancel')
    }
  }
}
</script>
<style lang="scss" scoped>
.align-center {
  align-items: center;
}
.jc-between {
  justify-content: space-between
}
.select-view {
  width: 460px;
  // height: 480px;
  margin-right: 10px;
  ::v-deep .el-checkbox{
    display: flex;
    .el-checkbox__input{
      margin-top: 2px;
    }
    .el-checkbox__label{
      white-space: break-spaces;
      word-break: break-word;
    }
  }
}
.sort_view_outside {
  width: 290px;
  // height: 480px;
  border: 1px solid #D3D3D3;
  border-radius: 8px;
  overflow: hidden;
}
.sort_view {
  width: 290px;
  height: 800px;
  overflow: hidden auto;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}
.warning{
  color:#1377ff;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #EBF3FE;
  border-radius: 4px;
  padding-left:20px;
  margin-bottom:10px;
  box-sizing: border-box;
  div{
    padding-left:24px;
    &:first-child{
      padding-left: 0;
      display:inline-block;
    }
  }
  &:before{
    content:"\e7a3";
    font-family: element-icons!important;
    margin-right:8px;
    font-size: 16px;
    vertical-align: bottom;
  }
}
.sortItem {
  width: 100%;
  height: 32px;
  line-height: 18px;
  min-height: 32px;
  background: #F6F7FB;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  margin-bottom: 10px;
  cursor: move
}
.sortIcon {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  cursor: pointer;
}
</style>
