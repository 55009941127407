import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { setTenantId, setToken, getAccessToken } from '@/utils/auth'
import { isRelogin } from '@/utils/request'
import { forChatplus, sleep } from '@/utils/ruoyi'

NProgress.configure({ showSpinner: false })

// 增加三方登陆 update by 芋艿
const whiteList = ['/login','/xtLogin', '/social-login', '/auth-redirect', '/bind', '/register', '/oauthLogin/gitee','/master/masterDetail','goods/detail','/productDetail']
const whiteArr = ['plusBiWap','login','/xtLogin', 'printPickList','printPurchaseOrder', 'printPicklistSummary', 'setArea','printOrderList', 'printSheetList','printInvoiceList','masterDetail','goodsDetail','productDetail', 'favoriteDetail']

router.beforeEach(async (to, from, next) => {
  if (forChatplus() && to.query.tid) {
    await sleep(2)
    setTenantId(to.query.tid)
    const data = {
      accessToken: to.query.access_token,
      refreshToken: to.query.refresh_token
    }
    setToken(data)
    location.href = location.origin+'/index/home'
  }
  store.commit('setShopHelp', {
    show: false,
    // value: msg
  })
  NProgress.start()
  console.log('开始时间', new Date())
  const routes = store.state?.permission?.routes
  const allChildren = routes.map(i => i.children).flat().filter(i => i !== undefined && i.name).map(i => i.name)
  if (getAccessToken()) {
    to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
    /* has token*/
    if (to.path === '/setArea' || to.path === '/printOrderList') {
      next()
      NProgress.done()
    } else if (to.path === '/login') {
      next({ path: '/index/home' })
      NProgress.done()
    } else {
      store.dispatch('isTenantInfo').then(() => {
        if (store.getters.roles.length === 0) {
          isRelogin.show = true
          // 获取字典数据 add by 芋艿
          store.dispatch('dict/loadDictDatas')
          store.dispatch('crm/getConfig')
          store.dispatch('crm/getKolAuth')
          store.dispatch('loadInitialCostState');
          store.dispatch('getShopAdAuth');
          // 判断当前用户是否已拉取完user_info信息
          store.dispatch('GetInfo').then((res) => {
            isRelogin.show = false
            store.dispatch('GenerateRoutes').then(accessRoutes => {
              // 根据roles权限生成可访问的路由表
              router.addRoutes(accessRoutes) // 动态添加可访问路由表
              console.log('正常跳转', new Date())
              next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
            })
          }).catch(err => {
            store.dispatch('LogOut').then(() => {
              Message.error(err)
              next({ path: '/index/home' })
            })
          })
        } else {
          if (!whiteArr.includes(to.name) && !allChildren.includes(to.name)) {
            next({
              path: '/index/noaccess',
              replace: true
            })
          }
          next()
        }
      }).catch(err => {
        next()
        // const isCheckOver = localStorage.getItem('checkOver')
        // const url = window.location.href
        // if (!isCheckOver&& (!url.includes('erp.xinjianerp.com') && !url.includes('localhost') && !url.includes('erptest.chainfurther.com') && !url.includes('192.168') && !url.includes('bi.chainfurther.com'))) {
        //   next({ path: "/setArea" })
        // }else{
        //   next()
        // }
      })
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      //在免登录白名单，直接进入
      if(forChatplus()){
        const url = window.location.href
        if(url.includes('bi.chatplus')){
          location.href = `https://bi.zhisuitech.com`
        }else{
          location.href = `https://feature-stq-kjds-dataplusbi.ftest.leyantech.com/`
        }
        next() // 否则全部重定向到登录页
      }else{
        next()
      }
      // next()
    } else {
      if(forChatplus()){
        const url = window.location.href
        if(url.includes('bi.chatplus')){
          location.href = `https://bi.zhisuitech.com`
        }else{
          location.href = `https://feature-stq-kjds-dataplusbi.ftest.leyantech.com/`
        }
        next() // 否则全部重定向到登录页
      }else{
        const redirect = encodeURIComponent(to.fullPath) // 编码 URI，保证参数跳转回去后，可以继续带上
        next(`/login?redirect=${redirect}`) // 否则全部重定向到登录页
      }
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  console.log('结束时间', new Date())
  NProgress.done()
})
