<template>
  <my-dialog class="myDlg" v-model="visible" :title="id ? $t('title11209') + $t('title11211') : $t('title11199') + $t('title11211')" width="968px" :showClose="true" :before-close="cancel">
    <div class="flex-row" style="margin-bottom: 5px">
      <el-form ref="baseForm" :model="form" :rules="rules" :inline="true" class="ruleForm" label-position="top">
        <el-form-item :label="$t('title11200')" prop="name" style="width: 309px;">
          <el-input v-model="form.name" style="width: 100%" maxlength="10" show-word-limit :placeholder="$t('title1580') + $t('title11200')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('title11201')" prop="calculation" style="width: 309px;">
          <el-select v-model="form.calculation" style="width:100%" :placeholder="$t('title1246') + $t('title11201')">
            <el-option :label="$t('title11203')" :value="1"/>
            <el-option :label="$t('title11204')" :value="0"/>
            <el-option :label="$t('title11210')" :value="2"/>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('title11202')" prop="show" style="width: 309px; margin: 0">
          <el-select v-model="form.show" style="width:100%" :placeholder="$t('title1246') + $t('title11201')">
            <el-option :label="$t('title11205')" :value="1"/>
            <el-option :label="$t('title11206')" :value="2"/>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="flex-row">
      <div class="white-box" style="width: 250px; height: 580px; overflow: hidden; margin-right: 10px; padding-right: 8px">
        <el-input v-model="filterWord" class="searchIpt" clearable style="width: calc(100% - 8px); margin-bottom: 10px;" :placeholder="$t('title1580') + $t('title10509')">
          <div slot="suffix" class="flex-center" style="height: 36px;">
            <img src="../../assets/images/costomColSearch.png" style="width: 14px; height: 14px; cursor: pointer"></img>
          </div>
        </el-input>
        <div class="codeView" style="width: 100%; overflow: hidden auto; height: 502px;">
          <div class="codeItem flex-row" v-for="(code, index) in codeOptions" :key="`code${index}`" @click="joinToCalc(code)">
            <span>
              {{ code.name }}
              <el-tooltip v-if="code.code === 'saleVolume'" placement="right" content="销量必须与其他金额字段结合计算，不能单独计算。">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </span>
            <img src="@/assets/images/addBtn.png" style="width: 12px; height: 12px; ">
          </div>
        </div>
      </div>

      <div class="white-box" style="width: 688px; height: 580px;">
        <div class="flex-row" style="margin-bottom: 16px;">
          <div v-for="(symbol, index) in symbolList" :key="`symbol_${index}`" class="symbolBtn flex-center" @click="joinToCalc(symbol)">
            <span :style="{'fontSize': ['(', ')'].includes(symbol.code)}">{{ symbol.name }}</span>
          </div>
          <div class="appendIpt flex-row" style="align-items: center; justify-content: space-between;margin-right: 10px;">
            <el-input v-model="costomNum" class="numIpt noborder" type="number" size="mini" style="width: 100px;" clearable :placeholder="$t('title11207')" />
            <div class="appendBtn" @click="joinNum">{{ $t('title10635') }}</div>
          </div>
          <div class="clearBtn" @click="calcList = []; warning = false">{{ $t('title1037') }}</div>
        </div>
        <div class="sort_view_outside flex-col" :style="{'borderColor': warning ? '#FF3141' : '#d3d3d3'}">
          <div class="flex-row sort_view">
            <div class="calcItem draggable flex-center" v-for="(item, index) in calcList" :key="`calc_item_${index}`">
              <el-popover
                width="54"
                placement="top"
                popper-class="myPopper"
                trigger="hover">
                <span style="cursor: pointer" @click="deleteItem(index)">{{ $t('title256') }}</span>
                <span slot="reference">{{ item.name }}</span>
              </el-popover>
            </div>
          </div>
          <div class="flex-row" style="justify-content: space-between">
            <span :style="{'color': warning ? '#FF3141' : '#666'}">{{ warning ? $t('title15486') : $t('title15487') }}</span>
            <span style="color: #999">{{ $t('title15488') }}</span>
          </div>
        </div>
      </div>
    </div>
    <template slot="footer">
      <el-button size="small" @click="cancel">{{ $t('title587') }}</el-button>
      <el-button size="small" type="primary" @click="submit">{{ $t('title1013') }}</el-button>
    </template>
  </my-dialog>
</template>

<script>
import Sortable from 'sortablejs'
import costomColApi from '@/api/erp/costomColApi'
import { build } from 'pdfjs-dist';
export default {
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    sortItems: {
      type: Array,
      default: () => []
    },
    // create参数名
    api: {
      type: String,
      default: ''
    },
    id: [String, Number],
    colInfo: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      form: {
        name: '',
        calculation: 1,
        show: 1
      },
      rules: {
        name: { required: true, message: this.$t('title1580') + this.$t('title11200'), trigger: "blur" },
      },
      filterWord: '',
      calcCodes: [
        'saleVolume',
        'saleAmount',
        'creditedAmount',
        'productCost',
        'packageFee',
        'testExpend',
        'profit',
        'profitRate',
        'sellerDiscountAmount',
        'costProfitRate',
        'totalAmount',
        'buyerPaidAmount',
        'platformDiscount',
        'coins',
        'shopeeShippingRebate',
        'shippingFeeDiscountFrom3pl',
        'buyerPaidShippingFee',
        'actualShippingFee',
        'shippingFee',
        'refundAmount',
        'commission',
        'serviceFee',
        'paymentFee',
        'otherFee',
        'initialCost',
        'finalCost',
        'tax',
        'otherAdjustFee',
        "allTotalCnt",
        "totalCnt",
        "allSaleVolume",
        "avgDaySaleVolume",
        "allSaleAmount",
        "refundCnt",
        "refundRate",
        "testCnt",
        "itemPriceCredit",
        "promotionalChargesVouchers",
        "lazcoinDiscount",
        "flexiCombo",
        "withholdingTax",
        "shippingFeeSaverProgram",
        "saverProgrammeShippingFeeSavings",
        "reverseShippingFee",
        "sponsoredAffiliates",
        "freeShippingMaxFee",
        'sfpServiceFee',
        'affiliatePartnerCommission',
        'affiliateCommission',
        'shippingFeeSubsidy',
      ],
      symbolList: [
        { name: '+', code: '+', codeType: 'symbol' },
        { name: '-', code: '-', codeType: 'symbol' },
        { name: 'x', code: '*', codeType: 'symbol' },
        { name: '÷', code: '/', codeType: 'symbol' },
        { name: '(', code: '(', codeType: 'aggregate_begin' },
        { name: ')', code: ')', codeType: 'aggregate_end' }
      ],
      costomNum: '',
      calcList: [],
      warning: false
    }
  },
  created() {
    this.id && this.init()
  },
  async mounted () {
    setTimeout(() => {
      this.getSortable()
    }, 200)
  },
  computed: {
    codeOptions() {
      let arr = this.sortItems.map(item => {
        return {
          ...item,
          codeType: 'code'
        }
      }).filter(item => this.calcCodes.includes(item.code))
      if (this.filterWord) {
        arr = arr.filter(item => item.name.includes(this.filterWord))
      }
      return arr
    },
    createdCodes() {
      let arr = this.sortItems.map(i => i.name)
      if (this.id) {
        arr = arr.filter(i => i !== this.colInfo.name)
      }
      return arr
    },
    apiFn() {
      let fn = new Function()
      fn = this.id ? costomColApi.updateCol : costomColApi.createCol
      return fn
    }
  },
  watch: {
  },
  methods: {
    init() {
      this.form = Object.assign({}, this.colInfo)
      let arr = this.colInfo.content.split(',')
      this.calcList = arr.map(code => {
        let target = {}
        if (this.calcCodes.includes(code)) {
          const _target = this.codeOptions.find(item => item.code === code)
          target = {
            ..._target,
            visible: false
          }
        } else if (this.symbolList.some(item => item.code === code)) {
          const _target = this.symbolList.find(item => item.code === code)
          target = {
            ..._target,
            visible: false
          }
        } else {
          target = {
            name: Number(code),
            code: Number(code),
            visible: false,
            codeType: 'number'
          }
        }
        return target
      })
    },
    getSortable () {
      const _dom = document.querySelector('.sort_view')
      const _this = this
      this.sortObj = Sortable.create(_dom, {
        draggable: '.draggable',
        ghostClass: 'blue-background-class',
        // 拖拽结束
        onEnd ({oldDraggableIndex, newDraggableIndex}) {
          const currRow = _this.calcList.splice(oldDraggableIndex, 1)[0]
          _this.calcList.splice(newDraggableIndex, 0, currRow)

          const arr = _this.calcList
          _this.calcList = []
          _this.$nextTick(() => {
            _this.calcList = arr
            _this.validateCalcLegal().then(res => {
              _this.warning = false
            }).catch(err => {
              _this.warning = true
            })
          })
        }
      })
    },
    joinNum() {
      if (!this.costomNum) return
      const target = {
        name: this.costomNum,
        code: this.costomNum,
        codeType: 'number'
      }
      this.joinToCalc(target)
    },
    async joinToCalc(item) {
      this.calcList.push({...item, visible: false})
      this.validateCalcLegal().then(res => {
        this.warning = false
      }).catch(err => {
        console.log(err)
        this.warning = true
      })
    },
    deleteItem(i) {
      this.calcList[i].visible = false
      this.calcList.splice(i, 1)
      this.validateCalcLegal().then(res => {
        this.warning = false
      }).catch(err => {
        console.log(err)
        this.warning = true
      })
    },
    validateCalcLegal() {
      return new Promise((resolve, reject) => {
        if (this.calcList.length) {
          const _calcList = this.calcList.map((item, index) => {
            return {
              ...item,
              codeSort: index + 1
            }
          })

          const aggregate_begin = _calcList.filter(i => i.code === '(')
          const aggregate_end = _calcList.filter(i => i.code === ')')
          const codeArr = _calcList.filter(i => i.codeType === 'code').map(i => i.codeSort)
          const symbolArr = _calcList.filter(i => i.codeType === 'symbol').map(i => i.codeSort)
          const aggregate = _calcList.filter(i => i.codeType === 'aggregate_begin' || i.codeType === 'aggregate_end')
          // 没有字段
          if (!codeArr.length && aggregate[0].code !== '(') reject(new Error('没有字段'))
          if (!_calcList.filter(i => i.codeType === 'code' && i.code !== 'saleVolume').length) reject(new Error('只有销量字段'))
          let beginArr = []
          if (_calcList.length > 1) {
            for (let i = 0; i <= _calcList.length - 1; i++) {
              if (i !== _calcList.length - 1) {
                // 字段连续
                if (_calcList[i].codeType === 'code' && _calcList[i + 1].codeType === 'code') {
                  reject(new Error('字段连续'))
                  break
                }
                if (_calcList[i].codeType === 'symbol' && _calcList[i + 1].codeType === 'symbol') {
                  reject(new Error('符号连续'))
                  break
                }
                if (_calcList[i].codeType === 'number' && _calcList[i + 1].codeType === 'number') {
                  reject(new Error('数字连续'))
                  break
                }
                if (_calcList[i].codeType === 'aggregate_begin' && _calcList[i + 1].codeType === 'aggregate_end') {
                  reject(new Error('括号连续'))
                  break
                }
                // 字段\数字后面没有计算符号
                if ((_calcList[i].codeType === 'code' || _calcList[i].codeType === 'number') && (_calcList[i + 1].codeType !== 'symbol' && _calcList[i + 1].codeType !== 'aggregate_end')) {
                  reject(new Error('字段、数字后面没有计算符号'))
                  break
                }


                // 集合符号校验
                if (_calcList[i].code === '(') {
                  beginArr.push(_calcList[i].code)
                } else if (_calcList[i].code === ')') {
                  if (beginArr.length === 0) {
                    reject(new Error('闭合符号开头'))
                  }
                  beginArr.pop()
                }
              }
              if (i > 0) {
                if ((_calcList[i].codeType === 'code' || _calcList[i].codeType === 'number') && (_calcList[i - 1].codeType !== 'symbol' && _calcList[i - 1].codeType !== 'aggregate_begin')) {
                  reject(new Error('字段、数字前面没有计算符号'))
                  break
                }
              }
            }
          }
          // 符号开头
          if (this.calcList[0].codeType === 'symbol') reject(new Error('符号开头'))
          // 符号结尾
          if (symbolArr[symbolArr.length - 1] === _calcList.length) reject(new Error('符号结尾'))
          // 集合符号不完整
          if (aggregate_begin.length !== aggregate_end.length) reject(new Error('集合符号不完整'))
          // 闭合符号开头
          if (aggregate.length && aggregate[0].code === ')') reject(new Error('闭合符号开头'))
          // 集合符号校验

          for(let item of _calcList) {
            if (item.code === '(') {
              beginArr.push(item.code)
            } else if (item.code === ')') {
              if (beginArr.length === 0) {
                reject(new Error('闭合符号开头'))
              }
              beginArr.pop()
            }
          }
          resolve(true)
        } else {
          reject(new Error('没有字段'))
        }
      })
    },
    chunkArray(array, chunkSize) {
      let result = []
      for (let i = 0; i < array.length; i += chunkSize) {
        let chunk = array.slice(i, i + chunkSize)
        result.push(chunk)
      }
      return result
    },
    async submit () {
      if (this.warning) return
      let goon = true
      await this.$refs.baseForm.validate().catch(() => {
        goon = false
      })
      if (!goon) return
      if (this.createdCodes.includes(this.form.name)) {
        this.$message.error(this.$t('title15491'))
        return
      }
      if (!this.calcList.length) {
        this.$message.error(this.$t('title15490'))
        return
      }
      const _calcList = this.calcList.map(item => item.code)
      const params = {
        ...this.form,
        content: _calcList.join(','),
        api: this.api,
      }
      const { data } = await this.apiFn(params)
      this.$message.success(this.$t('title1081'))
      this.$emit('after-save', data, this.form.show)
      this.$emit('cancel')
      this.$emit('change', false)
    },
    cancel () {
      this.$emit('cancel')
      this.$emit('change', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.white-box {
  background: #fff;
  border-radius: 4px;
  padding: 16px;
  box-sizing: border-box;
}
.codeItem {
  align-items: center;
  justify-content: space-between;
  background: #F7F8FA;
  border-radius: 2px;
  box-sizing: border-box;
  height: 32px;
  margin-bottom: 10px;
  padding: 0 16px 0 10px;
  cursor: pointer;
}
.codeItem:last-child {
  margin-bottom: 0
}
.symbolBtn {
  width: 56px;
  height: 36px;
  background: #F7F8FA;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  span {
    font-size: 24px;
    color: #333;
    height: 36px;
    line-height: 32px;
  }
}
.symbolBtn:last-child {
  margin-right: 0;
}
.appendIpt {
  width: 155px;
  border: 1px solid #d3d3d3;
  background: #fff;
  height: 36px;
  box-sizing: border-box;
  border-radius: 4px;
  padding-right: 5px;
}
::v-deep .noborder .el-input__inner {
  border: none;
  padding-right: 26px
}
.appendBtn {
  width: 44px;
  height: 26px;
  background: #F7F8FA;
  border-radius: 2px;
  font-size: 12px;
  color: #333;
  text-align: center;
  line-height: 26px;
  cursor: pointer;
}
.clearBtn {
  width: 90px;
  height: 36px;
  background: #F7F8FA;
  border-radius: 4px;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
  color: #333333;
  font-size: 12px;
  font-weight: 600;
}
.sort_view_outside {
  width: 100%;
  height: 500px;
  border: 1px solid;
  border-radius: 4px;
  padding: 16px;
  box-sizing: border-box;
  justify-content: space-between;
}
.sort_view {
  width: 100%;
  height: 440px;
  gap: 5px;
  flex-wrap: wrap;
  align-content: flex-start;
}
.calcItem {
  padding: 10px;
  font-size: 12px;
  background: #F7F8FA;
  border-radius: 2px;
  min-height: 32px;
  max-height: 32px;
  cursor: pointer
}
.calcItem.symbol {
  padding: 4px;
  font-size: 12px;
  background: #F7F8FA;
  border-radius: 2px;
  min-height: 32px;
  max-height: 32px;
}
::v-deep .el-input__inner::placeholder {
  color: #d3d3d3
}
.blue-background-class {
  background-color: #1377FF;
  color: #fff
}
.searchIpt {

}
.codeView::-webkit-scrollbar {
  padding-right: 8px;
  width: 8px;
}
::v-deep .el-icon-question::before{
  content:'';
  display: inline-block;
  width:12px;
  height:12px;
  margin-left: 4px;
  vertical-align: -1px;
  background: url('../../assets/images/order126.png') no-repeat center;
  background-size: contain;
}
</style>
<style>
.myDlg .el-dialog .el-dialog__body {
  padding: 10px !important;
  padding-top: 0 !important;
  background: #F6F7FB;
}
.myPopper.el-popover {
  min-width: 54px;
  color: #1377FF;
  font-size: 12px;
  text-align: center
}
</style>
