export default {
  menu: {
    "订单列表":"订单列表",
    "订单数据":"订单数据",
    "店铺日报":"店铺日报",
    "智能监控":"智能监控",
    "店铺组管理":"店铺组管理",
    "全部交易明细":"全部交易明细",
    "回款对账":"回款对账",
    "广告监控":"广告监控",
    "店铺广告分析":"店铺广告分析",
    "数据大屏":"数据大屏",
    "商品表现":"商品表现",
    "商品表现分析":"商品表现分析",
    "广告列表":"广告列表",
    "未授权":"未授权",
    "成本管理":"成本管理",
    "利润测算":"利润测算",
    "运营工具":"运营工具",
    "多维分析":"多维分析",
    "店铺监控":"店铺监控",
    "店铺统计":"店铺统计",
    "客户复购分析":"客户复购分析",
    "订单监控":"订单监控",
    "波次管理":"波次管理",
    "退款订单": "退款订单",
    "库存清单": "库存清单",
    "手工订单": "手工订单",
    "综合分析":"综合分析",
    "销量统计":"销量统计",
    "商品统计":"商品统计",
    "供应商关联商品":"供应商关联商品",
    "商品销量统计":"商品销量统计",
    "受限页面": "受限页面",
    "店铺销售详情": "店铺销售详情",
    "店铺销售统计": "店铺销售统计",
    '订单地区分布': '订单地区分布',
    "Shopee店铺数据表": "Shopee店铺数据表",
    "业绩利润报表": "业绩利润报表",
    "店铺利润报表": "店铺利润报表",
    "店铺利润": "店铺利润",
    "商品利润报表": "商品利润报表",
    'Shopee商品数据表': 'Shopee商品数据表',
    '单量套餐': '单量套餐',
    '首页': '首页',
    '合作监控': '合作监控',
    '店铺分析': '店铺分析',
    '店铺销售日历': '店铺销售日历',
    '订单统计': '订单统计',
    '店铺销售分析': '店铺销售分析',
    '店铺利润分析': '店铺利润分析',
    '店铺周期性对比': '店铺周期性对比',
    '账户健康状况': '账户健康状况',
    '客户分析': '客户分析',
    '商品分析': '商品分析',
    '商品数据分析': '商品数据分析',
    '评论分析': '评论分析',
    '分析报告': '分析报告',
    '数据诊断': '数据诊断',
    '订单分析': '订单分析',
    '订单地区分布': '订单地区分布',
    '退货报告': '退货报告',
    '利润分析': '利润分析',
    '利润月报': '利润月报',
    '订单利润': '订单利润',
    '商品利润': '商品利润',
    '订单利润明细': '订单利润明细',
    '广告分析': '广告分析',
    '广告概览': '广告概览',
    '广告业绩': '广告业绩',
    '广告采集': '广告采集',
    '商品配置': '商品配置',
    '本地SKU': '本地SKU',
    '商品配对': '商品配对',
    '报表中心': '报表中心',
    '经营看板': '经营看板',
    '报表下载': '报表下载',
    '单量套餐': '单量套餐',
    '系统设置': '系统设置',
    '系统配置': '系统配置',
    '智能监控': '智能监控',
    '商品监控': '商品监控',
    '订单监控': '订单监控',
    '业绩目标': '业绩目标',
    '店铺钱包': '店铺钱包',
    '交易明细': '交易明细',
    "线下退款": '线下退款',
    "成本修改记录":"成本修改记录",
    "物流监控":"物流监控",
    "打包费设置":"打包费设置",
    "广告花费设置":"广告花费设置",
    "测评参数设置":"测评参数设置",
    // erp
    '运营待办': '运营待办',
    '订单管理': '订单管理',
    '物流管理': '物流管理',
    '物流设置': '物流设置',
    '订单处理': '订单处理',
    '退货退款': '退货退款',
    '退货管理': '退货管理',
    '线上订单': '线上订单',
    '线下订单': '线下订单',
    '运费管理': '运费管理',
    '商品管理': '商品管理',
    '商品SKU': '商品SKU',
    'SKU匹配关系': 'SKU匹配关系',
    '库龄': '库龄',
    '成本中心': '成本中心',
    '仓库管理': '仓库管理',
    '货架位': '货架位',
    '商品推送': '商品推送',
    '店铺库存预警': '店铺库存预警',
    '库存列表': '库存列表',
    '仓库列表': '仓库列表',
    '仓库盘点': '仓库盘点',
    '调拨': '调拨',
    '出库': '出库',
    '入库': '入库',
    '出入库记录': '出入库记录',
    '采购管理': '采购管理',
    '采购单': '采购单',
    '采购建议': '采购建议',
    '供应商列表': '供应商列表',
    '收货管理': '收货管理',
    '分仓设置': '分仓设置',
    '进销存报告': '进销存报告',
    '进销存详情': '进销存详情',
    '自动安排': '自动安排',
    '税费设置': '税费设置',
    '财务设置':'财务设置',
    '销售额设置':'销售额设置',
    // normal
    '站点': '站点',
    '短信渠道': '短信渠道',
    '短信模板': '短信模板',
    '短信日志': '短信日志',
    '文件列表': '文件列表',
    '文件配置': '文件配置',
    'Banner管理': 'Banner管理',
    '令牌管理': '令牌管理',
    '应用管理': '应用管理',
    '通知公告': '通知公告',
    '文章管理': '文章管理',
    '第三方服务配置': '第三方服务配置',
    '业务参数配置': '业务参数配置',
    '支付': '支付',
    '商户信息': '商户信息',
    '应用信息': '应用信息',
    '支付订单': '支付订单',
    '退款订单量': '退款订单量',
    '监控': '监控',
    'API 日志': 'API 日志',
    '访问日志': '访问日志',
    '错误日志': '错误日志',
    '审计日志': '审计日志',
    '登录日志': '登录日志',
    'MySQL 监控': 'MySQL 监控',
    'Redis 监控': 'Redis 监控',
    'Java 监控': 'Java 监控',
    '监控平台': '监控平台',
    'DEV': 'DEV',
    '代码生成': '代码生成',
    '数据源配置': '数据源配置',
    '表单构建': '表单构建',
    '系统接口': '系统接口',
    '数据库文档': '数据库文档',
    '租户': '租户',
    '租户列表': '租户列表',
    '客户列表': '客户列表',
    '客户套餐': '客户套餐',
    '租户套餐': '租户套餐',
    '操作日志': '操作日志',
    '账号管理': '账号管理',
    '角色管理': '角色管理',
    '子账号管理': '子账号管理',
    '授权管理': '授权管理',
    '账号管理': '账号管理',
    '店铺管理': '店铺管理',
    '仓库授权': '仓库授权',
    '设置': '设置',
    '预估利润设置': '预估利润设置',
    '测评参数': '测评参数',
    '汇率设置': '汇率设置',
    '标签管理': '标签管理',
    '订单导出模板': '订单导出模板',
    '下载中心': '下载中心',
    '报表下载': '报表下载',
    '超级管理员': '超级管理员',
    '用户管理': '用户管理',
    '系统角色管理': '系统角色管理',
    '菜单管理': '菜单管理',
    '字典管理': '字典管理',
    '定时任务': '定时任务',
    '公告中心': '公告中心',
    '租户订单': '租户订单',
    '三方仓': '三方仓',
    '三方仓厂商管理': '三方仓厂商管理',
    '三方仓授权仓库管理': '三方仓授权仓库管理',
    '其他': '其他',
    '部门管理': '部门管理',
    '岗位管理': '岗位管理',
    '配置管理': '配置管理',
    '错误码管理': '错误码管理',
    '敏感词管理': '敏感词管理',
    '地区管理': '地区管理',
    '电商平台管理': '电商平台管理',
    '个人中心': '个人中心',
    '字典数据': '字典数据',
    // AI
    '模板管理': '模板管理',
    'AI模板管理': 'AI模板管理',
    'AI模板分类管理': 'AI模板分类管理',
    '会话管理': '会话管理',
    '自由会话': '自由会话',
    '模板会话': '模板会话',
    '我的收藏': '我的收藏',
    '创作训练': '创作训练',
    '知识库': '知识库',
    '授权结果': '授权结果',
    '授权失败': '授权失败',
    '单量套餐': '单量套餐',
    '商品分析详情': '商品分析详情',
    '商品利润': '商品利润',
    '打印设置': '打印设置',
    '自定义面单': '自定义面单',
    '自定义费用': '自定义费用',
    // CRM
    '找达人': '找达人',
    '达人广场': '达人广场',
    '自然出单达人': '自然出单达人',
    '达人管理': '达人管理',
    '达人公海': '达人公海',
    '我的达人': '我的达人',
    "达人建联": "达人建联",
    '跟进记录': '跟进记录',
    '达人订单': '达人订单',
    '黑名单': '黑名单',
    '自定义费用': '自定义费用',
    '店铺商品': '店铺商品',
    '合作管理': '合作管理',
    '样品跟进': '样品跟进',
    '我的合作单': '我的合作单',
    '合作单详情': '合作单详情',
    '商品链接库': '商品链接库',
    '商品链接分组': '商品链接分组',
    "业务配置":"业务配置",
    "店铺授权":"店铺授权",
    "白名单":"白名单",
    "达人详情":"达人详情",
    "配置中心":"配置中心",
    "合作直播":"合作直播",
    "绩效管理":"绩效管理",
    "履约进度":"履约进度",
    "合作视频":"合作视频",
    '重点关注': '重点关注',
    "带货热榜": '带货热榜',
    "达人热榜": '达人热榜',
    "商品热榜": '商品热榜',
    "店铺详情":"店铺详情",
    "店铺热榜": '店铺热榜',
    "邮件邀约": '邮件邀约',
    "收藏夹": '收藏夹',
    "邀约发信": '邀约发信',
    "收藏夹详情": "收藏夹详情",
    "消息中心":"消息中心",
    "CRM管理":"CRM管理",
    "利润汇总": "利润汇总",
    "商品成本管理": "商品成本管理",
    "财务管理": "财务管理",
    "数据概览": "数据概览",
    "财务看板": "财务看板",
  },
  title: {
    '日期': '日期',
    '项目': '项目',
    '总数据': '总数据',
    '商品访客数': '商品访客数',
    '件数': '件数',
    '销售': '销售',
    '测评销量': '测评销量',
    '有效销量': '有效销量',
    '测评金额': '测评金额',
    '平均客单价': '平均客单价',
    '转化率': '转化率',
    '收入': '收入',
    '有效销售额': '有效销售额',
    '支出': '支出',
    '搜索广告费': '搜索广告费',
    '搜索广告转化': '搜索广告转化',
    '搜索广告点击率': '搜索广告点击率',
    '搜索广告转化率': '搜索广告转化率',
    '搜索广告ROI': '搜索广告ROI',
    '关联广告费': '关联广告费',
    '关联广告转化': '关联广告转化',
    '关联广告销售额': '关联广告销售额',
    '关联广告点击率': '关联广告点击率',
    '关联广告转化率': '关联广告转化率',
    '关联广告ROI': '关联广告ROI',
    '推广广告费': '推广广告费',
    '推广广告转化': '推广广告转化',
    '推广广告销售额': '推广广告销售额',
    '推广广告点击率': '推广广告点击率',
    '推广广告转化率': '推广广告转化率',
    '推广广告ROI': '推广广告ROI',
    '测评损耗': '测评损耗',
    '商品成本': '商品成本',
    '平台费用': '平台费用',
    '退款金额': '退款金额',
    '预估运费': '预估运费',
    '产出': '产出',
    '利润': '利润',
    '销售利润率': '销售利润率'
  },
  order:{
    '不在待打单状态':'不在待打单状态',
    '请扫描或输入订单号或运单号':'请扫描或输入订单号或运单号',
    '发货记录':'发货记录',
    '您输入的不存在':'您输入的[{order}]不存在',
    'Free Shipping Max Fee':'包邮促销费用',
    'Item Price Credit':'货款',
    'Payment Fee':'支付手续费',
    'Commission':'佣金',
    'LazCoins Discount':'LazCoins Discount',
    'Shipping Fee Voucher (by Lazada)':'Shipping Fee Voucher (by Lazada)',
    'Shipping Fee (Paid By Customer)':'Shipping Fee (Paid By Customer)',
    'Shipping Fee Paid by Seller':'Shipping Fee Paid by Seller',
    'LazCoins Discount Promotion Fee':'LazCoins Discount Promotion Fee',
    'Campaign Fee':'Campaign Fee',
    'Shipping Fee Subsidy (By Seller)':'Shipping Fee Subsidy \n(By Seller)',
    'Promotional Charges Vouchers':'卖家优惠券',
    'Lazcoin discount':'Lazcoin discount',
    'Sponsored Affiliates':'超级联盟',
    'LazFlash Extra/Everyday Below $9.99 Subsidy':'LazFlash额外/每天低于9.99美元补贴',
    'Promotional Charges Flexi-Combo':'多件多折优惠',
  },
  user: {
    "请输入新手机号码":"请输入新手机号码",
    "输入旧密码":"输入旧密码",
    "请先使用主账号绑定手机号码":"请先使用主账号绑定手机号码",
    "销售地区":"销售地区",
    "时区":"时区",
    "您的套餐还剩天，为避免服务中断，请尽快续订。立即购买":"您的套餐还剩{days}天，为避免服务中断，请尽快续订。立即购买",
  },
  "缺货": "缺货",
  "推送成功": "推送成功",
  "默认日销量": "默认日销量",
  "已完成": "已完成",
  "调拨收货": "调拨收货",
  "采购收货": "采购收货",
  "已取消": "已取消",
  "在途中": "在途中",
  "盘点中": "盘点中",
  "采购计划": "采购计划",
  "部分收货": "部分收货",
  "是": "是",
  "否": "否",
  "亏损商品": "亏损商品",
  "改变主意": "改变主意",
  "台湾": "中国台湾",
  "站外广告费": "站外广告费",
  "站内广告费": "站内广告费",
  "空包费用": "空包费用",
  "平台调整": "平台调整",
  "低销商品": "低销商品",
  "利润偏低": "利润偏低",
  "退货暴增": "退货暴增",
  "商品缺件": "商品缺件",
  "销量暴跌": "销量暴跌",
  "销量暴涨": "销量暴涨",
  "低回应时间": "低回应时间",
  "逾期发货率过高": "逾期发货率过高",
  "商品损坏": "商品损坏",
  "其他": "其他",
  "商品发错了": "商品发错了",
  "商品存在瑕疵": "商品存在瑕疵",
  "商品和描述不一致": "商品和描述不一致",
  "未分组":"未分组",
  "按仓库":"按仓库",
  "在途中": "在途中",
  "按商品SKU":"按商品SKU",
  "亏损商品": "亏损商品",
  "低销商品": "低销商品",
  "利润偏低": "利润偏低",
  "退货暴增": "退货暴增",
  "印尼": "印尼",
  "台湾": "中国台湾",
  "马来西亚": "马来西亚",
  "菲律宾": "菲律宾",
  "新加坡": "新加坡",
  "泰国": "泰国",
  "越南": "越南",
  "打印拣货（含汇总）":"打印拣货（含汇总）",
  "确定批量设置仓库":"确定批量设置仓库",
  "仓库盘点时请勿关闭当前窗口，盘点完成后可关闭窗口":"仓库盘点时请勿关闭当前窗口，盘点完成后可关闭窗口",
  "创建盘点任务时请勿关闭当前窗口，创建完成后可关闭窗口":"创建盘点任务时请勿关闭当前窗口，创建完成后可关闭窗口",
  "正在导入数据":"正在导入数据",
  "设置全部页商品仓库":"设置全部页商品仓库",
  "设置已选商品仓库":"设置已选商品仓库",
  "印度尼西亚":"印度尼西亚",
  "当前账号下无可新增子账号的额度，请升级版本或删除无用的子账号空出名额后再次尝试。":"当前账号下无可新增子账号的额度，请升级版本或删除无用的子账号空出名额后再次尝试。",
  "佣金计算公式":"佣金计算公式",
  "为了准确估算Lazada、TikTok未回款订单的利润，需要设置一个预估的佣金比例。用于订单未回款时利润的估算。":"为了准确估算Lazada、TikTok未回款订单的利润，需要设置一个预估的佣金比例。用于订单未回款时利润的估算。",
  "物流管理":"物流管理",
  "运费正常":"运费正常",
  "运费亏损":"运费亏损",
  "运费盈余":"运费盈余",
  "请选择揽收方式":"请选择揽收方式",
  "线上物流":"线上物流",
  "title0": "选择店铺",
  "title1": "当前时间：",
  "title2": "中国",
  "title3": "初始化引导",
  "title4": "收起",
  "title5": "帮助",
  "title6": "实时销售数据",
  "title7": "更新时间：",
  "title8": "店铺名称",
  "title9": "平台地区",
  "title10": "昨日",
  "title11": "广告实时分析",
  "title12": "综合指标",
  "title13": "本期：",
  "title14": "环比周期：",
  "title15": "环比差异值：",
  "title16": "环比",
  "title17": "商品销售排行榜",
  "title18": "销售榜",
  "title19": "利润榜",
  "title20": "退货榜",
  "title21": "排名",
  "title22": "分析",
  "title23": "商品",
  "title24": "有效销售额",
  "title25": "有效销量",
  "title26": "利润",
  "title27": "利润率",
  "title28": "退款数量",
  "title29": "退款率",
  "title30": "售后分析",
  "title31": "近7日",
  "title32": "退款金额",
  "title33": "退款订单量",
  "title34": "取消订单量",
  "title35": "取消订单率",
  "title36": "智能监控",
  "title37": "销量暴涨",
  "title38": "销量暴跌",
  "title39": "亏损商品",
  "title40": "低销商品",
  "title41": "利润偏低",
  "title42": "退货暴增",
  "title43": "店铺状态预警",
  "title44": "中差评评论",
  "title45": "活动到期",
  "title46": "商品违规下架",
  "title47": "低回应时间",
  "title48": "逾期发货率过高",
  "title49": "店铺状况",
  "title50": "优秀店铺",
  "title51": "良好店铺",
  "title52": "需改进店铺",
  "title53": "表现欠佳",
  "title54": "公告",
  "title55": "系统",
  "title56": "升级",
  "title57": "提醒",
  "title58": "福建象岸科技有限公司",
  "title59": "闽",
  "title60": "备2023007214号",
  "title61": "日",
  "title62": "一",
  "title63": "二",
  "title64": "三",
  "title65": "四",
  "title66": "五",
  "title67": "六",
  "title68": "店铺授权",
  "title69": "将您的店铺授权到系统中，便于店铺订单、商品等同步到系统内",
  "title70": "去授权",
  "title71": "商品管理",
  "title72": "创建本地商品SKU，便于对商品精细化管理",
  "title73": "去设置",
  "title74": "配对管理",
  "title75": "匹配在线商品，将本地商品与线上平台",
  "title76": "匹配",
  "title77": "仓库管理",
  "title78": "初始化库存，便于后续的采购、补货、发货等操作",
  "title79": "账号管理",
  "title80": "为您公司的不同角色在系统配置对应的权限与操作",
  "title81": "插件安装",
  "title82": "辅助获取非接口数据，广告相关数据及商品表现数据",
  "title83": "去安装",
  "title84": "财务数据",
  "title85": "分析店铺、商品、订单等运营数据和自动生成报表数据并下载",
  "title86": "详情",
  "title88": "有效订单量",
  "title89": "广告销售额",
  "title90": "广告费",
  "title91": "广告转化",
  "title92": "广告点击率",
  "title93": "今日",
  "title94": "上周同日",
  "title95": "按订单",
  "title96": "近7天",
  "title97": "近30天",
  "title98": "最少选择一个指标",
  "title99": "领取阿里云通用云产品1888优惠券",
  "title100": "领取腾讯云通用云产品2860优惠券",
  "title101": "阿里云服务器折扣区",
  "title102": "点我进入",
  "title103": "腾讯云服务器秒杀区",
  "title104": "云产品通用红包，可叠加官网常规优惠使用。",
  "title105": "仅限新用户",
  "title106": "芋道后台管理框架",
  "title107": "一直想做一款后台管理系统，看了很多优秀的开源项目但是发现没有合适自己的。于是利用空闲休息时间开始自己写一套后台系统。如此有了芋道管理系统。，她可以用于所有的",
  "title108": "应用程序，如网站管理后台，网站会员中心，",
  "title109": "等等，当然，您也可以对她进行深度定制，以做出更强系统。所有前端后台代码封装过后十分精简易上手，出错概率低。同时支持移动客户端访问。系统会陆续更新一些实用功能。",
  "title110": "当前版本",
  "title111": "免费开源",
  "title112": "访问码云",
  "title113": "访问主页",
  "title114": "技术选型",
  "title115": "后端技术",
  "title116": "前端技术",
  "title117": "联系信息",
  "title118": "官网：",
  "title119": "群：",
  "title120": "满937441",
  "title121": "满887144332",
  "title122": "满180251782",
  "title123": "微信：",
  "title124": "芋道",
  "title125": "支付宝：",
  "title126": "支付宝信息",
  "title127": "更新日志",
  "title128": "新增缓存监控功能",
  "title129": "支持主题风格配置",
  "title130": "修复多级菜单之间切换无法缓存的问题",
  "title131": "多级菜单自动配置组件",
  "title132": "代码生成预览支持高亮显示",
  "title133": "支持",
  "title134": "请求映射",
  "title135": "参数",
  "title136": "删除用户和角色解绑关联",
  "title137": "去除用户手机邮箱部门必填验证",
  "title138": "支持注解",
  "title139": "对齐方式",
  "title140": "支持导入",
  "title141": "型数据",
  "title142": "优化头像样式，鼠标移入悬停遮罩",
  "title143": "代码生成预览提供滚动机制",
  "title144": "代码生成删除多余的数字",
  "title145": "类型",
  "title146": "修正转换字符串的目标字符集属性",
  "title147": "回显数据字典防止空值报错",
  "title148": "日志记录增加过滤多文件场景",
  "title149": "修改缓存",
  "title150": "方法可能导致嵌套的问题",
  "title151": "移除前端一些多余的依赖",
  "title152": "防止安全扫描",
  "title153": "出现的风险提示",
  "title154": "修改",
  "title155": "为",
  "title156": "到最新版本2",
  "title157": "到最新版本4",
  "title158": "到最新版本",
  "title159": "到最新版本1",
  "title160": "到最新版本0",
  "title161": "到最新版本3",
  "title162": "到版本4",
  "title163": "到最新版本6",
  "title164": "到最新版本5",
  "title165": "到最新版本7",
  "title166": "到最新版本10",
  "title167": "其他细节优化",
  "title168": "阻止任意文件下载漏洞",
  "title169": "代码生成支持上传控件",
  "title170": "新增图片上传组件",
  "title171": "调整默认首页",
  "title172": "配置支持分隔符",
  "title173": "权限信息调整",
  "title174": "调整",
  "title175": "默认时间",
  "title176": "解决代码生成没有",
  "title177": "类型的问题",
  "title178": "到最新版1",
  "title179": "版本到2",
  "title180": "提升安全性",
  "title181": "到版本0",
  "title182": "避免",
  "title183": "图标乱码",
  "title184": "代码生成支持同步数据库",
  "title185": "代码生成支持富文本控件",
  "title186": "代码生成页面时不忽略",
  "title187": "属性",
  "title188": "代码生成添加",
  "title189": "必填选项",
  "title190": "导出类型",
  "title191": "支持精度浮点类型",
  "title192": "导出",
  "title193": "优化获取值，防止",
  "title194": "方法不规范",
  "title195": "注解支持自动统计数据总和",
  "title196": "注解支持设置",
  "title197": "精度",
  "title198": "舍入规则",
  "title199": "菜单",
  "title200": "数据权限新增（展开",
  "title201": "折叠",
  "title202": "全选",
  "title203": "全不选",
  "title204": "父子联动）",
  "title205": "允许用户分配到部门父节点",
  "title206": "菜单新增是否缓存",
  "title207": "表格操作列间距调整",
  "title208": "限制系统内置参数不允许删除",
  "title209": "富文本组件优化，支持自定义高度",
  "title210": "图片冲突问题",
  "title211": "富文本工具栏样式对齐",
  "title212": "导入",
  "title213": "整形值校验优化",
  "title214": "修复页签关闭所有时固定标签路由不刷新问题",
  "title215": "表单构建布局型组件新增按钮",
  "title216": "左侧菜单文字过长显示省略号",
  "title217": "修正根节点为子部门时，树状结构显示问题",
  "title218": "修正调用目标字符串最大长度",
  "title219": "修正菜单提示信息错误",
  "title220": "修正定时任务执行一次权限标识",
  "title221": "修正数据库字符串类型",
  "title222": "优化递归子节点",
  "title223": "优化数据权限判断",
  "title224": "表格工具栏右侧添加刷新",
  "title225": "显隐查询组件",
  "title226": "后端支持",
  "title227": "跨域请求",
  "title228": "代码生成支持选择上级菜单",
  "title229": "代码生成支持自定义路径",
  "title230": "代码生成支持复选框",
  "title231": "导出导入支持",
  "title232": "字典类型",
  "title233": "支持分割字符串组内容",
  "title234": "验证码类型支持（数组计算、字符验证）",
  "title235": "版本到4",
  "title236": "表单类型为",
  "title237": "设置整形默认值",
  "title238": "代码生成器默认",
  "title239": "路径与默认",
  "title240": "路径不一致",
  "title241": "优化防重复提交拦截器",
  "title242": "优化上级菜单不能选择自己",
  "title243": "修复角色的权限分配后，未实时生效问题",
  "title244": "修复在线用户日志记录类型",
  "title245": "修复富文本空格和缩进保存后不生效问题",
  "title246": "修复在线用户判断逻辑",
  "title247": "唯一限制条件只返回单条数据",
  "title248": "添加获取当前的环境配置方法",
  "title249": "超时登录后页面跳转到首页",
  "title250": "全局异常状态汉化拦截处理",
  "title251": "过滤器改为将",
  "title252": "转义",
  "title253": "检查字符支持小数点",
  "title254": "降级改成异常提醒",
  "title255": "单应用调整为多模块项目",
  "title256": "删除",
  "title257": "提高编译速度。",
  "title258": "新增菜单默认主类目",
  "title259": "编码文件名修改为",
  "title260": "方式",
  "title261": "定时任务",
  "title262": "表达式验证",
  "title263": "角色权限修改时已有权限未自动勾选异常修复",
  "title264": "防止切换权限用户后登录出现404",
  "title265": "导出排序",
  "title266": "创建用户不允许选择超级管理员角色",
  "title267": "修复代码生成导入表结构出现异常页面不提醒问题",
  "title268": "修复代码生成点击多次表修改数据不变化的问题",
  "title269": "修复头像上传成功二次打开无法改变裁剪框大小和位置问题",
  "title270": "修复布局为",
  "title271": "者",
  "title272": "用户表单显示错位问题",
  "title273": "修复热部署导致的强换异常问题",
  "title274": "修改用户管理复选框宽度，防止部分浏览器出现省略号",
  "title275": "工具，清除",
  "title276": "特殊字符，防止",
  "title277": "注入攻击",
  "title278": "生成",
  "title279": "如果是浮点型",
  "title280": "统一用",
  "title281": "定时任务调整",
  "title282": "防止部署出现错位",
  "title283": "调整表头固定列默认样式",
  "title284": "代码生成模板调整，字段为",
  "title285": "并且必填则加空串条件",
  "title286": "代码生成字典",
  "title287": "使用",
  "title288": "修复",
  "title289": "不可",
  "title290": "为0的问题",
  "title291": "查询返回增加",
  "title292": "升序排序",
  "title293": "修正岗位导出权限注解",
  "title294": "禁止加密密文返回前端",
  "title295": "修复代码生成页面中的查询条件创建时间未生效的问题",
  "title296": "修复首页搜索菜单外链无法点击跳转问题",
  "title297": "修复菜单管理选择图标，",
  "title298": "删除时不过滤数据",
  "title299": "用户管理部门分支节点不可检查",
  "title300": "显示计数",
  "title301": "数据范围过滤属性调整",
  "title302": "修复高危安全漏洞",
  "title303": "启动默认打开浏览器",
  "title304": "使用默认",
  "title305": "报错优化",
  "title306": "当",
  "title307": "滚动关闭右键菜单",
  "title308": "字典管理添加缓存读取",
  "title309": "参数管理支持缓存操作",
  "title310": "支持一级菜单（和主页同级）在",
  "title311": "区域显示",
  "title312": "限制外链地址必须以",
  "title313": "开头",
  "title314": "主题颜色与",
  "title315": "全局",
  "title316": "同步",
  "title317": "修改数据源类型优先级，先根据方法，再根据类",
  "title318": "支持是否需要设置",
  "title319": "属性，自定义返回码消息。",
  "title320": "请求前缀加入配置。",
  "title321": "登录地点设置内容过长则隐藏显示",
  "title322": "修复定时任务执行一次按钮后不提示消息问题",
  "title323": "修改上级部门（选择项排除本身和下级）",
  "title324": "通用",
  "title325": "发送方法增加参数",
  "title326": "编码类型",
  "title327": "更换",
  "title328": "地址查询接口",
  "title329": "修复页签变量",
  "title330": "添加校验部门包含未停用的子部门",
  "title331": "修改定时任务详情下次执行时间日期显示错误",
  "title332": "角色管理查询设置默认排序字段",
  "title333": "添加",
  "title334": "参数控制是否启用",
  "title335": "只对",
  "title336": "类型请求构建可重复读取",
  "title337": "的",
  "title338": "修改代码生成字典字段",
  "title339": "类型没有自动选中问题",
  "title340": "用户名取值修正",
  "title341": "表格树模板去掉多余的",
  "title342": "代码生成序号修正",
  "title343": "全屏情况下不调整上外边距",
  "title344": "代码生成",
  "title345": "字段添加默认格式",
  "title346": "用户管理角色选择权限控制",
  "title347": "修复路由懒加载报错问题",
  "title348": "模板",
  "title349": "添加菜单状态",
  "title350": "设置用户名称不能修改",
  "title351": "属性，防止",
  "title352": "遮罩",
  "title353": "菜单区分状态和显示隐藏功能",
  "title354": "修复安全加固",
  "title355": "修复代码生成如果选择字典类型缺失逗号问题",
  "title356": "登录请求",
  "title357": "更换为",
  "title358": "防止暴露",
  "title359": "日志返回时间格式处理",
  "title360": "控制允许拖动的元素",
  "title361": "布局设置点击扩大范围",
  "title362": "代码生成列属性排序查询",
  "title363": "代码生成列支持拖动排序",
  "title364": "修复时间格式不支持",
  "title365": "问题",
  "title366": "表单构建添加父级",
  "title367": "防止冲突",
  "title368": "定时任务并发属性修正",
  "title369": "角色禁用",
  "title370": "菜单隐藏不查询权限",
  "title371": "系统监控新增定时任务功能",
  "title372": "添加一个打包",
  "title373": "工程",
  "title374": "修复页签鼠标滚轮按下的时候，可以关闭不可关闭的",
  "title375": "修复点击退出登录有时会无提示问题",
  "title376": "修复防重复提交注解无效问题",
  "title377": "修复通知公告批量删除异常问题",
  "title378": "添加菜单时路由地址必填限制",
  "title379": "代码生成字段描述可编辑",
  "title380": "修复用户修改个人信息导致缓存不过期问题",
  "title381": "个人信息创建时间获取正确属性值",
  "title382": "操作日志详细显示正确类型",
  "title383": "导入表单击行数据时选中对应的复选框",
  "title384": "批量替换表前缀逻辑调整",
  "title385": "固定重定向路径表达式",
  "title386": "操作日志排序调整",
  "title387": "切换侧边栏或者缩放窗口显示",
  "title388": "新增表单构建",
  "title389": "代码生成支持树表结构",
  "title390": "新增用户导入",
  "title391": "修复动态加载路由页面刷新问题",
  "title392": "修复地址开关无效问题",
  "title393": "汉化错误提示页面",
  "title394": "代码生成已知问题修改",
  "title395": "修复多数据源下配置关闭出现异常处理",
  "title396": "过滤器，用于去除",
  "title397": "漏洞隐患",
  "title398": "修复上传头像控制台出现异常",
  "title399": "修改用户管理分页不正确的问题",
  "title400": "修复验证码记录提示错误",
  "title401": "缺少",
  "title402": "引用",
  "title403": "修复表格时间为空出现的异常",
  "title404": "日期反序列化时区配置",
  "title405": "调整根据用户权限加载菜单数据树形结构",
  "title406": "调整成功登录不恢复按钮，防止多次点击",
  "title407": "修改用户个人资料同步缓存信息",
  "title408": "修复页面同时出现",
  "title409": "和",
  "title410": "不显示处理",
  "title411": "修复在角色管理页修改菜单权限偶尔未选中问题",
  "title412": "配置文件新增",
  "title413": "密码属性",
  "title414": "设置",
  "title415": "全局的配置文件",
  "title416": "新增代码生成",
  "title417": "新增",
  "title418": "注解，防止重复提交",
  "title419": "新增菜单主目录添加",
  "title420": "删除操作",
  "title421": "日志记录过滤特殊对象，防止转换异常",
  "title422": "修改代码生成路由脚本错误",
  "title423": "用户上传头像实时同步缓存，无需重新登录",
  "title424": "调整切换页签后不重新加载数据",
  "title425": "实现参数的前端加密",
  "title426": "系统退出删除用户缓存记录",
  "title427": "新增在线用户管理",
  "title428": "新增按钮组功能实现（批量删除、导出、清空）",
  "title429": "新增查询条件重置按钮",
  "title430": "配置",
  "title431": "新增后端参数校验",
  "title432": "修复字典管理页面的日期查询异常",
  "title433": "修改时间函数命名防止冲突",
  "title434": "去除菜单上级校验，默认为顶级",
  "title435": "修复用户密码无法修改问题",
  "title436": "修复菜单类型为按钮时不显示权限标识",
  "title437": "芋道前后端分离系统正式发布",
  "title438": "捐赠支持",
  "title439": "你可以请作者喝杯咖啡表示鼓励",
  "title440": "欢迎来到心舰",
  "title441": "注册",
  "title442": "找回密码",
  "title443": "租户",
  "title444": "输入手机号",
  "title445": "用户名",
  "title446": "密码",
  "title447": "登",
  "title448": "录",
  "title449": "中",
  "title450": "天内自动登录",
  "title451": "忘记密码？",
  "title452": "还没有账号？",
  "title453": "去注册",
  "title454": "请输入手机号",
  "title455": "请输入密码",
  "title456": "请输入验证码",
  "title457": "获取验证码",
  "title458": "秒后重发",
  "title459": "阅读并接受",
  "title460": "心舰服务条款》",
  "title461": "已有账号？",
  "title462": "去登录",
  "title463": "输入验证码",
  "title464": "提交",
  "title465": "无需找回？",
  "title466": "输入密码",
  "title467": "再次输入密码",
  "title468": "重置密码",
  "title469": "完成",
  "title470": "开始使用",
  "title471": "返回上一步",
  "title472": "登录",
  "title473": "同意并继续",
  "title474": "个字符",
  "title475": "字母必须包含大写",
  "title476": "字母、数字和字符至少包含2种",
  "title477": "密码不能是相同的用户名",
  "title478": "请再次输入密码",
  "title479": "两次输入密码不一致",
  "title480": "东南亚跨境电商",
  "title481": "精细化运营与数据分析",
  "title482": "验证码不能为空",
  "title483": "手机号不能为空",
  "title484": "手机号格式错误",
  "title485": "租户不能为空",
  "title486": "印尼",
  "title487": "菲律宾",
  "title488": "泰国",
  "title489": "越南",
  "title490": "马来西亚",
  "title491": "新加坡",
  "title492": "中国台湾",
  "title493": "账号不存在",
  "title494": "请阅读并同意条款",
  "title495": "获取验证码成功",
  "title496": "修改成功，请重新登录",
  "title497": "注册成功，请登录",
  "title498": "心舰",
  "title499": "三方授权（",
  "title500": "此第三方应用请求获得以下权限：",
  "title501": "同意授权",
  "title502": "授",
  "title503": "权",
  "title504": "拒绝",
  "title505": "自动授权未通过！",
  "title506": "访问你的个人信息",
  "title507": "修改你的个人信息",
  "title508": "绑定账号",
  "title509": "账号",
  "title510": "记住密码",
  "title511": "用户名不能为空",
  "title512": "密码不能为空",
  "title513": "知识库",
  "title514": "商品访客数",
  "title515": "消息",
  "title516": "金额",
  "title517": "订单",
  "title518": "返回",
  "title519": "错误",
  "title520": "您没有访问权限！",
  "title521": "对不起，您没有访问权限，请不要进行非法操作！您可以返回主页面",
  "title522": "回首页",
  "title523": "该功能需要开通",
  "title524": "套餐",
  "title525": "后方能使用",
  "title526": "前往了解",
  "title527": "或",
  "title528": "咨询客服",
  "title529": "对不起，您正在寻找的页面不存在。尝试检查",
  "title530": "的错误，然后按浏览器上的刷新按钮或尝试在我们的应用程序中找到其他内容。",
  "title531": "返回首页",
  "title532": "找不到网页！",
  "title533": "我的收藏",
  "title534": "问",
  "title535": "答",
  "title536": "复制",
  "title537": "取消收藏",
  "title538": "自由会话",
  "title539": "模板会话",
  "title540": "文档会话",
  "title541": "复制成功",
  "title542": "取消收藏成功",
  "title543": "新建会话",
  "title544": "清除所有会话",
  "title545": "新的会话",
  "title546": "请重新修改",
  "title547": "修改成功！",
  "title548": "确定删除该会话",
  "title549": "警告",
  "title550": "删除成功！",
  "title551": "是否删除全部会话",
  "title552": "智能知识库",
  "title553": "与文档对话，快速从文档中获取，提炼，定位，总结出你所需的信息。",
  "title554": "新建知识库",
  "title555": "创建自己的",
  "title556": "提问模板知识库，快速解析和理解复杂的技术文档，记录常用问题，避免重复提问，提高工作效率。",
  "title557": "编辑模板",
  "title558": "删除模板",
  "title559": "确定删除该模版？",
  "title560": "删除成功",
  "title561": "分类",
  "title562": "请输入分类",
  "title563": "模板类型",
  "title564": "请选择模板类型",
  "title565": "模板标题",
  "title566": "请输入模板标题",
  "title567": "搜索",
  "title568": "重置",
  "title569": "主键",
  "title570": "创建人",
  "title571": "模板介绍",
  "title572": "提问内容",
  "title573": "提问变量",
  "title574": "创建时间",
  "title575": "操作",
  "title576": "请输入创建人",
  "title577": "请输入模板介绍",
  "title578": "请输入提问内容",
  "title579": "提问的变量",
  "title580": "请输入提问的变量，使用",
  "title581": "数组存储。数组中每个对象包含以下字段：变量名",
  "title582": "对应的描述文案",
  "title583": "是否必填",
  "title584": "默认值",
  "title585": "确",
  "title586": "定",
  "title587": "取消",
  "title588": "",
  "title589": "修改成功",
  "title590": "新增成功",
  "title591": "是否确认删除",
  "title592": "模板编号为",
  "title593": "的数据项",
  "title594": "是否确认导出所有",
  "title595": "模板数据项",
  "title596": "专用",
  "title597": "编辑知识库",
  "title598": "林珍",
  "title599": "修改问题",
  "title600": "关闭",
  "title601": "欢迎来到我的",
  "title602": "频道！今天的主题是如何在生活中实现健康的生活方式。",
  "title603": "首先，一个健康的生活方式需要有一个良好的饮食习惯。我们应该尽量食用新鲜、天然的食物，避免摄入过多的加工食品和糖分。另外，我们也需要保持适当的饮食量，不要过量进食或过度节食。",
  "title604": "其次，适量的运动也是保持健康的重要一环。我们可以选择自己喜欢的运动方式，比如跑步、瑜伽、游泳等等。无论是室内还是户外运动，每天坚持30分钟以上的运动可以帮助我们保持身体健康，同时也有助于缓解压力和焦虑感。",
  "title605": "除了饮食和运动之外，我们还需要注意睡眠质量的保证。一个良好的睡眠环境可以帮助我们更好地入睡和保持深度睡眠状态。同时，规律的作息时间也是非常重要的，它可以帮助我们的身体建立一个健康的生物钟。",
  "title606": "最后，我们还可以通过一些放松的方式来缓解生活中的压力和疲劳感。比如听音乐、读书、冥想等等。这些活动可以帮助我们放松身心，让我们更加愉悦地度过每一天。",
  "title607": "以上就是我对如何在生活中实现健康生活方式的一些",
  "title608": "回答中，请稍等",
  "title609": "请输入你的提问内容（换行：",
  "title610": "回车发送）",
  "title611": "消耗1点）",
  "title612": "发送",
  "title613": "帮我写一篇写一篇生活",
  "title614": "脚本",
  "title615": "请描述问题",
  "title616": "图片",
  "title617": "请输入图片",
  "title618": "分类名称",
  "title619": "请输入分类名称",
  "title620": "分类层级",
  "title621": "请输入分类层级",
  "title622": "父级",
  "title623": "请输入父级",
  "title624": "开始日期",
  "title625": "结束日期",
  "title626": "模板分类",
  "title627": "模板分类编号为",
  "title628": "模板分类数据项",
  "title629": "不知道写啥？试试插入示例",
  "title630": "清空输入",
  "title631": "开始生成",
  "title632": "请输入内容",
  "title633": "全部",
  "title634": "收藏成功",
  "title635": "广告投放状态",
  "title636": "采集状态",
  "title637": "采集完整性",
  "title638": "最后一次采集时间",
  "title639": "请参考“采集完整性”以了解是否已成功采集了最近一个月的广告数据。如果数据不完整，您可以通过查看采集记录来获取具体情况",
  "title640": "查看操作教程",
  "title641": "店铺",
  "title642": "未采集",
  "title643": "暂停投放",
  "title644": "正在投放",
  "title645": "采集中",
  "title646": "投放中（",
  "title647": "已暂停（",
  "title648": "离线",
  "title649": "开启",
  "title650": "关闭",
  "title651": "采集周期",
  "title652": "采集记录",
  "title653": "请注意，系统并未采集到所有广告数据。为了获得完整数据，请启用广告插件并重新执行采集过程",
  "title654": "商品表现",
  "title655": "广告数据",
  "title656": "日期",
  "title657": "完整",
  "title658": "不完整",
  "title659": "已采集",
  "title660": "无广告",
  "title661": "统计时间",
  "title662": "广告分析",
  "title663": "排序",
  "title664": "地区",
  "title665": "趋势图",
  "title666": "店铺广告花费",
  "title667": "商品广告销售额",
  "title668": "商品",
  "title669": "广告活动效果",
  "title670": "广告活动",
  "title671": "广告点击数",
  "title672": "按日",
  "title673": "按周",
  "title674": "按月",
  "title675": "巴西",
  "title676": "墨西哥",
  "title677": "点击率",
  "title678": "广告浏览数",
  "title679": "广告浏览数",
  "title680": "今天",
  "title681": "昨天",
  "title682": "最近7天",
  "title683": "最近15天",
  "title684": "最近30天",
  "title685": "广告点击数",
  "title686": "较昨天",
  "title687": "较上一天",
  "title688": "较上",
  "title689": "天",
  "title690": "业绩",
  "title691": "店铺数据",
  "title692": "商品数据",
  "title693": "运营数据",
  "title694": "店铺利润报表",
  "title695": "统计店铺利润，用于分析店铺的经营状况",
  "title696": "业绩利润报表",
  "title697": "统计运营人员的业绩报表，包括成本和利润的报告",
  "title698": "商品数据表",
  "title699": "统计商品关键指标，了解商品整体的运营表现",
  "title700": "店铺数据表",
  "title701": "统计店铺运营数据，用于分析店铺整体情况",
  "title702": "选择地区",
  "title703": "当前周期",
  "title704": "对比周期",
  "title705": "销售数据对比",
  "title706": "成本数据对比",
  "title707": "对比明细数据",
  "title708": "最新导出数据",
  "title709": "报表名称",
  "title710": "下载",
  "title711": "处理中",
  "title712": "暂无数据",
  "title713": "销售数据",
  "title714": "成本数据",
  "title715": "商品成本",
  "title716": "广告成本",
  "title717": "打包费用",
  "title718": "测评支出",
  "title719": "卖家优惠",
  "title720": "运费支出",
  "title721": "平台费用",
  "title722": "平台其它费用",
  "title723": "至少选择一个店铺",
  "title724": "多选店铺",
  "title725": "个",
  "title726": "店铺周期对比-",
  "title727": "是否确认导出成本组成概况",
  "title728": "成本组成概况-",
  "title729": "确认订单",
  "title730": "订单详情",
  "title731": "订购服务",
  "title732": "评论分析",
  "title733": "次",
  "title734": "点",
  "title735": "购买",
  "title736": "数量",
  "title737": "时长",
  "title738": "套餐合计",
  "title739": "套餐数量",
  "title740": "套餐时长",
  "title741": "有效期",
  "title742": "套餐单量",
  "title743": "单",
  "title744": "支付方式",
  "title745": "应付金额",
  "title746": "我已阅读并接受",
  "title747": "心舰用户服务条款》",
  "title748": "提交订单",
  "title749": "订单支付",
  "title750": "支付金额",
  "title751": "支付剩余时间",
  "title752": "到期未支付订单将自动取消",
  "title753": "打开手机",
  "title754": "支付宝",
  "title755": "微信",
  "title756": "扫一扫继续付款",
  "title757": "付款方式：对公转账（转账前请不要关闭当前页面，转账成功请点击已完成转账）",
  "title758": "收款公司名称：",
  "title759": "收款银行账号：",
  "title760": "开户银行名称：",
  "title761": "中国建设银行股份有限公司福州师大支行",
  "title762": "已完成转账",
  "title763": "支付成功",
  "title764": "您可以在【个人中心】-【我的订单】中查看订单状态",
  "title765": "入账时间预计需要5-10分钟，请稍等片刻",
  "title766": "您可以在【个人中心】-【我的订单】中查看",
  "title767": "订单编号",
  "title768": "支付宝支付",
  "title769": "对公转账",
  "title770": "用户分析总览",
  "title771": "复购率",
  "title772": "较上一周期",
  "title773": "销售表现",
  "title774": "有效订单量",
  "title776": "客单价",
  "title777": "新用户",
  "title778": "复购用户",
  "title779": "用户列表",
  "title780": "输入搜索信息",
  "title781": "买家支付金额",
  "title782": "客户列表",
  "title783": "买家信息",
  "title784": "最后下单时间",
  "title785": "购买记录",
  "title786": "用户【",
  "title787": "商品记录",
  "title788": "商品信息",
  "title790": "订单记录",
  "title791": "订单号",
  "title792": "商品种类",
  "title793": "下单时间",
  "title794": "通过",
  "title795": "搜索用户",
  "title796": "是",
  "title797": "否",
  "title798": "近15天",
  "title799": "总用户",
  "title800": "复购订单数",
  "title801": "过去7天",
  "title802": "日期：",
  "title803": "是否确认导出所有数据项",
  "title804": "店铺销售数据",
  "title805": "综合数据",
  "title806": "时间对比",
  "title807": "指标对比",
  "title808": "本期",
  "title809": "环比周期",
  "title810": "环比差异值",
  "title811": "按时",
  "title812": "按时统计是统计店铺当天24小时的数据表现。",
  "title813": "商品成本组成",
  "title814": "成本与有效销售额对比",
  "title815": "库存报告",
  "title816": "全部仓库",
  "title817": "三方仓",
  "title818": "自有仓",
  "title819": "货值",
  "title820": "库龄情况",
  "title821": "天数",
  "title822": "占比",
  "title823": "订单地区分布",
  "title824": "请选择地区",
  "title825": "退货",
  "title826": "城市",
  "title827": "有效订单量",
  "title828": "退款订单量",
  "title829": "有效订单量占比",
  "title830": "退款订单量占比",
  "title831": "可用库存",
  "title832": "冻结库存",
  "title833": "在途中",
  "title834": "超过",
  "title835": "按店铺",
  "title836": "按平台",
  "title837": "按地区",
  "title838": "销售额",
  "title839": "总销量",
  "title840": "订单量",
  "title841": "测评损耗",
  "title842": "总数量",
  "title843": "总货值",
  "title844": "商品总成本",
  "title845": "账户信息",
  "title846": "基本资料",
  "title847": "登录日志",
  "title848": "订购信息",
  "title849": "我的套餐",
  "title850": "我的订单",
  "title851": "我的账号",
  "title852": "账号：",
  "title853": "密码：",
  "title854": "设置密码",
  "title855": "姓名：",
  "title856": "手机号码：",
  "title857": "更换手机",
  "title858": "登录时间",
  "title859": "登录账号",
  "title860": "所属角色",
  "title861": "手机号",
  "title862": "登录地址",
  "title863": "您的套餐额度已用完，购买新套餐以继续服务。",
  "title864": "立即购买",
  "title865": "套餐情况",
  "title866": "当前版本：",
  "title867": "有效时长",
  "title868": "剩余单量：",
  "title869": "购买套餐",
  "title870": "基础权益",
  "title871": "店铺数",
  "title872": "子账号",
  "title873": "(点)",
  "title874": "套餐名称",
  "title875": "剩余单量",
  "title876": "剩余时间",
  "title877": "订购时间",
  "title878": "状态",
  "title879": "备注",
  "title880": "加油包权益",
  "title881": "加油包类型",
  "title882": "购买内容",
  "title883": "加油包数量",
  "title884": "剩余数量",
  "title885": "剩余天数",
  "title886": "订单类型",
  "title887": "请输入订单号",
  "title888": "交易时间",
  "title889": "订购内容",
  "title890": "加油包",
  "title891": "支付状态",
  "title892": "立即支付",
  "title893": "取消订单",
  "title894": "请联系客服",
  "title895": "发票",
  "title896": "支付时间",
  "title897": "订购内容",
  "title898": "未支付",
  "title899": "支付成功",
  "title900": "支付关闭",
  "title901": "确认取消订单？",
  "title902": "取消订单成功",
  "title903": "上传成功",
  "title904": "选择状态",
  "title905": "店铺健康状态",
  "title906": "表现欠佳店铺",
  "title907": "本季度计分平均值",
  "title908": "筛选店铺本季计分均值。累积的计分将保留直到季度结束。",
  "title909": "计分将在每个季度的第一个星期一（1月，4月，7月，10月）重置。鼠标移入计分分数可查看重要指标计分均值。",
  "title910": "指标",
  "title911": "均值",
  "title912": "分",
  "title913": "违反上架规范",
  "title914": "严重违规商品",
  "title915": "预售商品数量",
  "title916": "其他违规",
  "title917": "订单完成率",
  "title918": "订单未完成率",
  "title919": "迟出货率",
  "title920": "准备时间",
  "title921": "客服满意度",
  "title922": "回复率",
  "title923": "买家评价",
  "title924": "重要指标计分均值",
  "title925": "店铺预警",
  "title926": "目前处分",
  "title927": "您的店铺经营正常，暂无预警",
  "title928": "店铺状态",
  "title929": "店铺授权过期，请",
  "title930": "重新授权",
  "title931": "本季计分",
  "title932": "预警处分等级",
  "title933": "处分触发分数-1分时触发预警，即2、5、8、11、14时触发预警",
  "title934": "无预警",
  "title935": "级",
  "title936": "店铺处罚分数预警分布",
  "title937": "暂无预警",
  "title938": "您的店铺现处于健康状态，暂无处分",
  "title939": "处分名称",
  "title940": "处分等级",
  "title941": "处分剩余天数",
  "title942": "店铺处分等级分布",
  "title943": "店铺情况",
  "title944": "更新数据",
  "title945": "列表项设置",
  "title946": "店铺整体表现的评价等级，1-差 2-需要改进 3-好 4-极好",
  "title947": "本季计分分数",
  "title948": "累积的计分将保留直到季度结束，计分将在每个季度的第一个星期一（1月，4月，7月，10月）重置",
  "title949": "未履行违规行为",
  "title950": "订单取消或退货导致的非履约情况所引起的处罚分数",
  "title951": "延迟出货违规行为",
  "title952": "因订单发货延迟导致的处罚分数",
  "title953": "因违反上架政策而引起的处罚分数",
  "title954": "其他违规行为",
  "title955": "其他类型的处罚分数",
  "title956": "严重违反",
  "title957": "政策的商品将被删除",
  "title958": "我的店铺",
  "title959": "目标",
  "title960": "计分",
  "title961": "劣质刊登",
  "title962": "试图操纵搜索结果以不公平优势的行为都将受到惩罚。例如上架重复商品、关键词滥用、误导性定价等",
  "title963": "仿冒或侵权商品",
  "title964": "非真品或侵权产品",
  "title965": "违禁商品",
  "title966": "不被允许在",
  "title967": "上架的商品",
  "title968": "预购商品",
  "title969": "预购商品数量",
  "title970": "上架的预售商品占上架商品总数的百分比",
  "title971": "预购商品的天数",
  "title972": "超过目标",
  "title973": "在过去的30天内，卖家的预售商品的占比超过规定占比的天数",
  "title974": "其他规范",
  "title975": "违反其他上架规范",
  "title976": "平台规定的其他上架规范，违反可能受到惩罚。例如商品被列入错误的类别、商品有差质量的图片等",
  "title977": "指在过去的7天内取消或退货订单的百分比",
  "title978": "订单取消率",
  "title979": "指卖家在过去7天内取消订单的百分比",
  "title980": "退款率",
  "title981": "指买家在过去7天内申请退货",
  "title982": "退款的订单百分比",
  "title983": "指卖家在过去7天内逾期出货的订单数量百分比",
  "title984": "指卖家准备商品和出货所需的平均天数",
  "title985": "客服",
  "title986": "满意度",
  "title987": "指卖家收到新聊天讯息或出价后在12小时内回应的占比",
  "title988": "回复速度",
  "title989": "卖家回应买家讯息所需的平均时间",
  "title990": "买家综合评价",
  "title991": "买家提交所有订单的评价总平均值",
  "title992": "本季计分-本季计分分数",
  "title993": "本季计分-未履行违规行为",
  "title994": "本季计分-延迟出货违规行为",
  "title995": "本季计分-未返商家规范",
  "title996": "本季计分-其他违规行为",
  "title997": "严重违规商品-严重违规商品",
  "title998": "严重违规商品-劣质刊登",
  "title999": "严重违规商品-仿冒或侵权商品",
  "title1000": "严重违规商品-违禁商品",
  "title1001": "预购商品-预购商品数量",
  "title1002": "预购商品-预购商品的天数",
  "title1003": "预购商品-违反其他上架规范",
  "title1004": "订单完成率-订单完成率",
  "title1005": "订单完成率-订单取消率",
  "title1006": "订单完成率-退货",
  "title1007": "订单完成率-迟出货率",
  "title1008": "订单完成率-准备时间",
  "title1009": "客服满意度-回复率",
  "title1010": "客服满意度-回复速度",
  "title1011": "客服满意度-买家综合评价",
  "title1012": "恢复默认",
  "title1013": "确定",
  "title1014": "优秀",
  "title1015": "良好",
  "title1016": "需改进",
  "title1017": "回款汇率不能为空",
  "title1018": "更新数据成功",
  "title1019": "您是否想重新授权店铺，若是请点击“确定”进行授权",
  "title1020": "是否确认导出店铺情况",
  "title1021": "店铺情况-",
  "title1022": "单量套餐",
  "title1023": "当前套餐",
  "title1024": "免费版",
  "title1025": "套餐有效期为1年。",
  "title1026": "从套餐开始使用单量消耗开始计算，直到单量消耗完或超过有效期截止。",
  "title1027": "如果您需要线下实施服务或线上演示帮助，请联系我们的客服团队。",
  "title1028": "年",
  "title1029": "账号数",
  "title1030": "流程表单",
  "title1031": "表单名",
  "title1032": "请输入表单名",
  "title1033": "开启状态",
  "title1034": "请输入备注",
  "title1035": "保存",
  "title1036": "查看",
  "title1037": "清空",
  "title1038": "从左侧拖入或点选组件进行表单设计",
  "title1039": "输入型组件",
  "title1040": "选择型组件",
  "title1041": "布局型组件",
  "title1042": "表单名不能为空",
  "title1043": "开启状态不能为空",
  "title1044": "成功",
  "title1045": "代码已复制到剪切板，可粘贴。",
  "title1046": "代码复制失败",
  "title1047": "确定要清空所有组件吗？",
  "title1048": "提示",
  "title1049": "工作流",
  "title1050": "编号",
  "title1051": "表单详情",
  "title1052": "是否确认删除工作表单的编号为",
  "title1053": "定义编号",
  "title1054": "定义名称",
  "title1055": "定义分类",
  "title1056": "表单信息",
  "title1057": "暂无表单",
  "title1058": "流程版本",
  "title1059": "未部署",
  "title1060": "激活",
  "title1061": "挂起",
  "title1062": "部署时间",
  "title1063": "定义描述",
  "title1064": "分配规则",
  "title1065": "流程图",
  "title1066": "组名",
  "title1067": "请输入组名",
  "title1068": "请选择状态",
  "title1069": "描述",
  "title1070": "成员",
  "title1071": "请输入描述",
  "title1072": "请选择成员",
  "title1073": "组名不能为空",
  "title1074": "描述不能为空",
  "title1075": "成员不能为空",
  "title1076": "状态不能为空",
  "title1077": "添加用户组",
  "title1078": "修改用户组",
  "title1079": "是否确认删除用户组编号为",
  "title1080": "未知",
  "title1081": "保存成功",
  "title1082": "流程标识",
  "title1083": "请输入流程标识",
  "title1084": "流程名称",
  "title1085": "请输入流程名称",
  "title1086": "流程分类",
  "title1087": "新建流程",
  "title1088": "导入流程",
  "title1089": "最新部署的流程定义",
  "title1090": "激活状态",
  "title1091": "修改流程",
  "title1092": "设计流程",
  "title1093": "发布流程",
  "title1094": "流程定义",
  "title1095": "请输入流标标识",
  "title1096": "新建后，流程标识不可修改！",
  "title1097": "流程标识不可修改！",
  "title1098": "请选择流程分类",
  "title1099": "流程描述",
  "title1100": "表单类型",
  "title1101": "表单提交路由",
  "title1102": "请输入表单提交路由",
  "title1103": "自定义表单的提交路径，使用",
  "title1104": "的路由地址，例如说：",
  "title1105": "表单查看路由",
  "title1106": "请输入表单查看路由",
  "title1107": "自定义表单的查看路径，使用",
  "title1108": "将文件拖到此处，或",
  "title1109": "点击上传",
  "title1110": "提示：仅允许导入“",
  "title1111": "或“",
  "title1112": "格式文件！",
  "title1113": "流程标识不能为空",
  "title1114": "流程名称不能为空",
  "title1115": "业务表单不能为空",
  "title1116": "流程分类不能为空",
  "title1117": "表单提交路由不能为空",
  "title1118": "表单查看路由不能为空",
  "title1119": "新建模型",
  "title1120": "修改模型",
  "title1121": "修改模型成功",
  "title1122": "新建模型成功！",
  "title1123": "后续需要执行如下",
  "title1124": "个步骤：",
  "title1125": "点击【修改流程】按钮，配置流程的分类、表单信息",
  "title1126": "点击【设计流程】按钮，绘制流程图",
  "title1127": "点击【分配规则】按钮，设置每个用户任务的审批人",
  "title1128": "点击【发布流程】按钮，完成流程的最终发布",
  "title1129": "另外，每次流程修改后，都需要点击【发布流程】按钮，才能正式生效！！！",
  "title1130": "重要提示",
  "title1131": "是否删除该流程！！",
  "title1132": "是否部署该流程！！",
  "title1133": "部署成功",
  "title1134": "是否确认",
  "title1135": "流程名字为",
  "title1136": "导入流程成功！请点击【设计流程】按钮，进行编辑保存后，才可以进行【发布流程】",
  "title1137": "选择",
  "title1138": "申请信息【",
  "title1139": "选择其它流程",
  "title1140": "发起流程成功",
  "title1141": "审批任务【",
  "title1142": "流程名",
  "title1143": "流程发起人",
  "title1144": "审批建议",
  "title1145": "请输入审批建议",
  "title1146": "不通过",
  "title1147": "转办",
  "title1148": "委派",
  "title1149": "退回",
  "title1150": "点击查看",
  "title1151": "审批记录",
  "title1152": "任务：",
  "title1153": "审批人：",
  "title1154": "创建时间：",
  "title1155": "审批时间：",
  "title1156": "耗时：",
  "title1157": "转派审批人",
  "title1158": "新审批人",
  "title1159": "审批建议不能为空",
  "title1160": "新审批人不能为空",
  "title1161": "未传递",
  "title1162": "参数，无法查看流程信息",
  "title1163": "查询不到流程信息！",
  "title1164": "审批通过成功！",
  "title1165": "审批不通过成功！",
  "title1166": "转派任务成功！",
  "title1167": "暂不支持【委派】功能，可以使用【转派】替代！",
  "title1168": "暂不支持【退回】功能！",
  "title1169": "请输入流程名",
  "title1170": "所属流程",
  "title1171": "请输入流程定义的编号",
  "title1172": "提交时间",
  "title1173": "结果",
  "title1174": "请选择流结果",
  "title1175": "发起流程",
  "title1176": "当前审批任务",
  "title1177": "结束时间",
  "title1178": "请输入取消原因？",
  "title1179": "取消流程",
  "title1180": "取消原因不能为空",
  "title1181": "取消成功",
  "title1182": "任务编号",
  "title1183": "任务名称",
  "title1184": "审批意见",
  "title1185": "审批时间",
  "title1186": "耗时",
  "title1187": "审批",
  "title1188": "任务分配规则",
  "title1189": "任务名",
  "title1190": "任务标识",
  "title1191": "规则类型",
  "title1192": "规则范围",
  "title1193": "修改任务规则",
  "title1194": "指定角色",
  "title1195": "指定部门",
  "title1196": "请选择指定部门",
  "title1197": "指定岗位",
  "title1198": "指定用户",
  "title1199": "指定用户组",
  "title1200": "指定脚本",
  "title1201": "规则类型不能为空",
  "title1202": "指定角色不能为空",
  "title1203": "指定部门不能为空",
  "title1204": "指定岗位不能为空",
  "title1205": "指定用户不能为空",
  "title1206": "指定用户组不能为空",
  "title1207": "指定脚本不能为空",
  "title1208": "销售额数据对比",
  "title1209": "订单数据对比",
  "title1210": "流量数据对比",
  "title1211": "广告转化数据",
  "title1212": "平台评论",
  "title1213": "请输入平台评论",
  "title1214": "平台商品",
  "title1215": "请输入平台商品",
  "title1216": "请输入商品",
  "title1217": "评论时间",
  "title1218": "评论",
  "title1219": "买家",
  "title1220": "视频",
  "title1221": "评论内容",
  "title1222": "卖家回复",
  "title1223": "评分",
  "title1224": "请输入买家",
  "title1225": "请输入视频",
  "title1226": "请输入评论内容",
  "title1227": "请输入卖家回复",
  "title1228": "请输入评分",
  "title1229": "选择评论时间",
  "title1230": "不能为空",
  "title1231": "订单号不能为空",
  "title1232": "评论时间不能为空",
  "title1233": "添加商品评论",
  "title1234": "修改商品评论",
  "title1235": "是否确认删除商品评论编号为",
  "title1236": "是否确认导出所有商品评论数据项",
  "title1237": "商品评论",
  "title1238": "录入自定义费用",
  "title1239": "成本修改记录",
  "title1240": "批量导入商品成本",
  "title1241": "导入商品成本",
  "title1242": "导入历史成本",
  "title1243": "新增自定义费用",
  "title1244": "批量导入自定义费用",
  "title1245": "批量删除",
  "title1246": "请选择",
  "title1247": "销售状态",
  "title1248": "有无商品成本",
  "title1249": "费用类型",
  "title1250": "导出商品成本",
  "title1251": "在售",
  "title1252": "停售",
  "title1253": "有商品成本",
  "title1254": "无商品成本",
  "title1255": "历史成本",
  "title1256": "商品成本导入模板",
  "title1257": "自定义费用导入模板",
  "title1258": "导入自定义费用",
  "title1259": "自定义费用表",
  "title1260": "温馨提示",
  "title1261": "是否确认导出",
  "title1262": "商品成本明细-",
  "title1263": "打开",
  "title1264": "置顶",
  "title1265": "切换币种",
  "title1266": "页面浏览数",
  "title1268": "广告访客数",
  "title1269": "自然访客数",
  "title1270": "点赞数",
  "title1271": "点赞率",
  "title1272": "加购数",
  "title1273": "加购转化率",
  "title1274": "广告销量",
  "title1275": "自然销量",
  "title1276": "自然销售额",
  "title1277": "转化率",
  "title1278": "广告费",
  "title1279": "数据错误，请联系管理员",
  "title1280": "置顶成功",
  "title1281": "取消置顶成功",
  "title1282": "自然订单占比",
  "title1283": "是否确认导出所有出入库记录",
  "title1285": "广告流量分析可以收集和分析店铺商品流量和转化率数据，可及时查看商品的买家访问量、转化率、销量、销售额等关键指标，了解运营中商品表现。",
  "title1286": "请输入商品属性搜索",
  "title1287": "广告类别",
  "title1288": "广告点击数",
  "title1289": "销售金额",
  "title1290": "明细",
  "title1291": "广告明细",
  "title1292": "取消置顶",
  "title1293": "商品名称",
  "title1294": "是否确认导出所有商品",
  "title1295": "数据项",
  "title1296": "诊断数据",
  "title1297": "过去",
  "title1298": "店铺信息",
  "title1299": "组",
  "title1301": "有效销量环比",
  "title1302": "利润（",
  "title1303": "请选择搜索类型",
  "title1304": "请输入搜索内容",
  "title1305": "选择时间",
  "title1306": "条评价",
  "title1307": "营销指标",
  "title1308": "评论数：",
  "title1309": "总评分：",
  "title1310": "重要卖点",
  "title1311": "竟品信息",
  "title1312": "核心关键词记录",
  "title1313": "商品状态",
  "title1314": "下架",
  "title1315": "上架",
  "title1316": "商品评价",
  "title1317": "用户名称",
  "title1318": "订单编号：",
  "title1319": "商品数据明细",
  "title1320": "查看趋势图",
  "title1321": "总访客",
  "title1322": "总单量",
  "title1323": "关键词广告访客",
  "title1324": "关键词点击率",
  "title1325": "关键词广告单量",
  "title1326": "关键词转化率",
  "title1327": "平均每单成本",
  "title1328": "关键词",
  "title1329": "关键词广告",
  "title1330": "关联广告访客",
  "title1331": "关联广告单量",
  "title1332": "关联广告",
  "title1333": "自然访客",
  "title1334": "自然点击率",
  "title1335": "自然单量",
  "title1336": "自然转化率",
  "title1337": "商品数据趋势分析图",
  "title1338": "点击标签开启或关闭曲线",
  "title1339": "卖点备注",
  "title1340": "链接",
  "title1341": "竟品热销",
  "title1342": "价格",
  "title1343": "竟品月销量",
  "title1344": "当地关键词",
  "title1345": "中文翻译",
  "title1346": "搜索量",
  "title1347": "请输入卖点信息",
  "title1348": "竟品链接",
  "title1349": "请输入竟品链接",
  "title1350": "请输入竟品热销",
  "title1351": "请输入竟品月销量",
  "title1352": "请输入当地关键词",
  "title1353": "关键词自动翻译",
  "title1354": "请输入关键词搜索量",
  "title1355": "重要卖点备注",
  "title1356": "新增重要卖点备注",
  "title1357": "竞品链接记录",
  "title1358": "新增竞品链接",
  "title1359": "新增核心关键词",
  "title1360": "产品名称",
  "title1361": "产品",
  "title1362": "已下架",
  "title1363": "已上架",
  "title1364": "自定义",
  "title1365": "请输入卖点备注",
  "title1366": "平均每单成本（关键词）",
  "title1367": "每单花费成本（关联广告）",
  "title1368": "月",
  "title1369": "是否确认导出所有订单数据项",
  "title1370": "行李箱升级款23寸密码黑色加厚黑色加厚黑",
  "title1371": "输入关键词搜索",
  "title1372": "显示位置",
  "title1373": "转化",
  "title1374": "商品已售出",
  "title1375": "条件设置",
  "title1377": "诊断规则",
  "title1378": "请选择时间",
  "title1379": "小时",
  "title1380": "设置项未完成",
  "title1381": "大于",
  "title1382": "大于等于",
  "title1383": "小于",
  "title1384": "小于等于",
  "title1385": "开始月份",
  "title1386": "结束月份",
  "title1387": "基础数据",
  "title1388": "客户数",
  "title1389": "取消订单金额",
  "title1390": "变化趋势",
  "title1391": "取消订单量",
  "title1392": "最近14天",
  "title1393": "本月",
  "title1394": "上月",
  "title1395": "最近半年",
  "title1396": "最近1年",
  "title1397": "近三个月",
  "title1398": "近六个月",
  "title1399": "近一年",
  "title1400": "按年",
  "title1401": "较前天",
  "title1402": "添加产品链接",
  "title1403": "产品信息",
  "title1404": "平台",
  "title1405": "分析时间",
  "title1406": "操作超时，请重新进行分析",
  "title1407": "分析完成",
  "title1408": "分析中",
  "title1409": "重新分析",
  "title1410": "分析报告",
  "title1411": "商品链接",
  "title1412": "请输入线上产品链接，用于分析评论内容，每次只能添加一条产品链接。",
  "title1413": "输入产品链接",
  "title1414": "如：",
  "title1415": "产品名称",
  "title1416": "产品编号",
  "title1417": "请输入商品链接",
  "title1418": "请输入有效地址",
  "title1419": "添加成功",
  "title1420": "刷新成功",
  "title1421": "确认删除？",
  "title1422": "价格：",
  "title1423": "总销量：",
  "title1424": "月销量：",
  "title1425": "星级：",
  "title1426": "留评率：",
  "title1427": "上架时间：",
  "title1428": "打开店铺链接",
  "title1429": "总览",
  "title1430": "优点",
  "title1431": "缺点",
  "title1432": "客户期望",
  "title1433": "购买动机",
  "title1434": "当前在售变体有",
  "title1435": "个，历史有评论的变体",
  "title1436": "个，有评论变体清单如下：",
  "title1437": "变体",
  "title1438": "评论数",
  "title1439": "评论占比",
  "title1440": "查看评论",
  "title1441": "原因",
  "title1442": "当前分类占比",
  "title1443": "当前商品总评论占比",
  "title1444": "查看买家评论",
  "title1445": "评论列表",
  "title1446": "输入搜索内容",
  "title1447": "星级",
  "title1448": "评论类型",
  "title1449": "翻译内容",
  "title1450": "下载评论",
  "title1451": "星",
  "title1452": "文字",
  "title1453": "其他",
  "title1454": "是否确认导出产品评论",
  "title1455": "产品评论",
  "title1456": "选择搜索类型",
  "title1457": "商品销售额",
  "title1458": "店铺评分",
  "title1459": "发货时效",
  "title1460": "逾期发货率",
  "title1461": "未完成率",
  "title1462": "响应时间",
  "title1463": "罚分",
  "title1464": "今日新增评价数",
  "title1465": "林明芳-",
  "title1466": "用户",
  "title1467": "请输入用户",
  "title1468": "诊断名称",
  "title1469": "请输入诊断名称",
  "title1470": "诊断类型",
  "title1471": "请选择诊断类型",
  "title1472": "提醒类型",
  "title1473": "请选择提醒类型",
  "title1474": "自增编号",
  "title1475": "诊断描述",
  "title1476": "请输入诊断规则",
  "title1477": "诊断名称不能为空",
  "title1478": "诊断类型不能为空",
  "title1479": "提醒类型不能为空",
  "title1480": "添加数据诊断",
  "title1481": "修改数据诊断",
  "title1482": "是否确认删除数据诊断编号为",
  "title1483": "是否确认导出所有数据诊断数据项",
  "title1484": "数据诊断",
  "title1485": "排行榜",
  "title1486": "更新时间",
  "title1487": "日榜",
  "title1488": "周榜",
  "title1489": "月榜",
  "title1490": "线上链接",
  "title1491": "链接信息",
  "title1492": "信息",
  "title1493": "账号信息",
  "title1494": "平台信息",
  "title1495": "商品销售额排行榜",
  "title1496": "商品利润率排行榜",
  "title1497": "商品退货率排行榜",
  "title1498": "业绩排行榜",
  "title1499": "平台排行榜",
  "title1500": "店铺排行榜",
  "title1501": "全部销量",
  "title1502": "商品规格",
  "title1503": "名称",
  "title1504": "请输入名称",
  "title1505": "模板名称",
  "title1506": "条码类型",
  "title1507": "标签尺寸",
  "title1508": "宽",
  "title1509": "高，",
  "title1510": "是否默认",
  "title1511": "请选择条码类型",
  "title1512": "宽度",
  "title1513": "请输入宽度",
  "title1514": "高度",
  "title1515": "请输入高度",
  "title1516": "模板定义内容",
  "title1517": "名称不能为空",
  "title1518": "条码类型不能为空",
  "title1519": "宽度不能为空",
  "title1520": "高度不能为空",
  "title1521": "是否默认不能为空",
  "title1522": "添加标签模板",
  "title1523": "修改标签模板",
  "title1524": "是否确认删除标签模板编号为",
  "title1525": "是否确认导出所有标签模板数据项",
  "title1526": "标签模板",
  "title1527": "输入商品",
  "title1528": "查看排名",
  "title1529": "请输入平台名称",
  "title1530": "平台名称",
  "title1531": "平台图标",
  "title1532": "平台链接",
  "title1533": "请输入平台链接",
  "title1534": "平台名称不能为空",
  "title1535": "平台图标不能为空",
  "title1536": "平台链接不能为空",
  "title1537": "添加电商平台",
  "title1538": "修改电商平台",
  "title1539": "确认删除",
  "title1540": "是否确认导出所有电商平台数据项",
  "title1541": "电商平台",
  "title1542": "请输入类目名称",
  "title1543": "类目",
  "title1544": "父类目",
  "title1545": "类目名称",
  "title1546": "显示顺序",
  "title1547": "级别",
  "title1548": "请输入父类目",
  "title1549": "请输入显示顺序",
  "title1550": "请输入级别",
  "title1551": "类目名称不能为空",
  "title1552": "显示顺序不能为空",
  "title1553": "级别不能为空",
  "title1554": "添加商品类目",
  "title1555": "修改商品类目",
  "title1556": "是否确认删除商品类目",
  "title1557": "是否确认导出所有商品类目数据项",
  "title1558": "商品类目",
  "title1559": "您有",
  "title1560": "个店铺授权过期，请",
  "title1561": "点击重新授权",
  "title1562": "待办事项",
  "title1563": "待处理",
  "title1564": "即将过期",
  "title1565": "缺货",
  "title1566": "待配对",
  "title1567": "安排失败",
  "title1568": "三方仓推送失败",
  "title1569": "待揽收",
  "title1570": "库存",
  "title1571": "无库存",
  "title1572": "低于安全库存",
  "title1573": "店铺库存预警",
  "title1574": "采购",
  "title1575": "采购建议",
  "title1576": "采购计划",
  "title1577": "店铺授权数",
  "title1578": "店铺授权过期",
  "title1579": "退款订单量",
  "title1580": "请输入",
  "title1581": "竟品",
  "title1582": "月销量",
  "title1583": "请输入链接",
  "title1584": "请输入价格",
  "title1585": "请输入月销量",
  "title1586": "添加竟品链接",
  "title1587": "修改竟品链接",
  "title1588": "是否确认删除竟品链接编号为",
  "title1589": "是否确认导出所有竟品链接数据项",
  "title1590": "单商品",
  "title1591": "组合商品",
  "title1592": "全部店铺",
  "title1593": "编辑",
  "title1594": "组合商品名称",
  "title1595": "请选择商品",
  "title1596": "请选择店铺",
  "title1597": "商品不能为空",
  "title1598": "店铺不能为空",
  "title1599": "添加商品配对",
  "title1600": "是否确认导出所有商品配对数据项",
  "title1601": "商品配对",
  "title1602": "配对详情",
  "title1603": "刪除",
  "title1604": "是否确认删除商品配对",
  "title1605": "请输入店铺",
  "title1606": "请输入平台",
  "title1607": "请输入地区",
  "title1608": "最近",
  "title1609": "请输入最近",
  "title1610": "自增",
  "title1611": "币种",
  "title1612": "汇率",
  "title1613": "退款单量",
  "title1614": "退款订单量环比",
  "title1615": "取消单量",
  "title1616": "退货率环比",
  "title1617": "取消单量环比",
  "title1618": "取消率",
  "title1619": "取消率环比",
  "title1620": "测评单量",
  "title1621": "测评单量环比",
  "title1622": "有效订单量环比",
  "title1623": "订单金额",
  "title1624": "买家实付",
  "title1625": "有效销售额环比",
  "title1626": "买家支付运费",
  "title1627": "成本环比",
  "title1628": "其它费用",
  "title1629": "结算金额",
  "title1630": "测评结算金额",
  "title1631": "测评付款金额",
  "title1632": "测评成本",
  "title1633": "测评支出环比",
  "title1634": "测评付款汇率",
  "title1635": "平台佣金",
  "title1636": "广告费用",
  "title1637": "退货金额环比",
  "title1638": "总收入",
  "title1639": "总支出",
  "title1640": "总成本",
  "title1641": "预估利润",
  "title1642": "利润环比",
  "title1643": "平均利润",
  "title1644": "利润率环比",
  "title1645": "成本利润率",
  "title1646": "成本利润率环比",
  "title1647": "请输入币种",
  "title1648": "请输入汇率",
  "title1649": "请输入退款单量",
  "title1650": "请输入退款订单量环比",
  "title1651": "请输入取消单量",
  "title1652": "请输入退货率环比",
  "title1653": "请输入取消单量环比",
  "title1654": "请输入取消率",
  "title1655": "请输入取消率环比",
  "title1656": "请输入测评单量",
  "title1657": "请输入测评单量环比",
  "title1658": "请输入有效订单量",
  "title1659": "请输入有效订单量环比",
  "title1660": "请输入有效销量",
  "title1661": "请输入有效销量环比",
  "title1662": "请输入订单金额",
  "title1663": "请输入买家实付金额",
  "title1664": "请输入有效销售额环比",
  "title1665": "请输入买家支付运费",
  "title1666": "请输入商品总成本",
  "title1667": "请输入成本环比",
  "title1668": "请输入其它费用",
  "title1669": "请输入运费支出",
  "title1670": "请输入打包费用",
  "title1671": "请输入结算金额",
  "title1672": "请输入测评结算金额",
  "title1673": "请输入测评付款金额",
  "title1674": "请输入测评成本",
  "title1675": "请输入测评支出",
  "title1676": "请输入测评支出环比",
  "title1677": "请输入测评付款汇率",
  "title1678": "请输入平台佣金",
  "title1679": "请输入广告费用",
  "title1680": "请输入退货金额",
  "title1681": "请输入退货金额环比",
  "title1682": "请输入卖家优惠",
  "title1683": "请输入总收入",
  "title1684": "请输入总支出",
  "title1685": "请输入总成本",
  "title1686": "请输入预估利润",
  "title1687": "请输入利润环比",
  "title1688": "请输入平均利润",
  "title1689": "请输入利润率",
  "title1690": "请输入利润率环比",
  "title1691": "请输入成本利润率",
  "title1692": "请输入成本利润率环比",
  "title1693": "平台不能为空",
  "title1694": "地区不能为空",
  "title1695": "天不能为空",
  "title1696": "币种不能为空",
  "title1697": "汇率不能为空",
  "title1698": "退款单量不能为空",
  "title1699": "退款订单量环比不能为空",
  "title1700": "取消单量不能为空",
  "title1701": "退货率环比不能为空",
  "title1702": "取消单量环比不能为空",
  "title1703": "取消率不能为空",
  "title1704": "取消率环比不能为空",
  "title1705": "测评单量不能为空",
  "title1706": "测评单量环比不能为空",
  "title1707": "有效订单量不能为空",
  "title1708": "有效订单量环比不能为空",
  "title1709": "有效销量不能为空",
  "title1710": "有效销量环比不能为空",
  "title1711": "订单金额不能为空",
  "title1712": "买家实付金额不能为空",
  "title1713": "有效销售额环比不能为空",
  "title1714": "买家支付运费不能为空",
  "title1715": "商品总成本不能为空",
  "title1716": "成本环比不能为空",
  "title1717": "其它费用不能为空",
  "title1718": "运费支出不能为空",
  "title1719": "打包费用不能为空",
  "title1720": "结算金额不能为空",
  "title1721": "测评结算金额不能为空",
  "title1722": "测评付款金额不能为空",
  "title1723": "测评成本不能为空",
  "title1724": "测评支出不能为空",
  "title1725": "测评支出环比不能为空",
  "title1726": "测评付款汇率不能为空",
  "title1727": "平台佣金不能为空",
  "title1728": "广告费用不能为空",
  "title1729": "退货金额不能为空",
  "title1730": "退货金额环比不能为空",
  "title1731": "卖家优惠不能为空",
  "title1732": "总收入不能为空",
  "title1733": "总支出不能为空",
  "title1734": "总成本不能为空",
  "title1735": "预估利润不能为空",
  "title1736": "利润环比不能为空",
  "title1737": "平均利润不能为空",
  "title1738": "利润率不能为空",
  "title1739": "利润率环比不能为空",
  "title1740": "成本利润率不能为空",
  "title1741": "成本利润率环比不能为空",
  "title1742": "添加最近",
  "title1743": "天商品利润",
  "title1744": "修改最近",
  "title1745": "是否确认删除最近",
  "title1746": "天商品利润编号为",
  "title1747": "是否确认导出所有最近",
  "title1748": "天商品利润数据项",
  "title1749": "数据日期",
  "title1750": "关键词广告单",
  "title1751": "平均每单成本（关联广告）",
  "title1752": "选择数据日期",
  "title1753": "请输入总访客",
  "title1754": "请输入总单量",
  "title1755": "请输入关键词广告访客",
  "title1756": "请输入关键词点击率",
  "title1757": "请输入关键词广告单",
  "title1758": "请输入关键词转化率",
  "title1759": "请输入平均每单成本",
  "title1760": "请输入关键词广告",
  "title1761": "请输入关联广告访客",
  "title1762": "请输入关联广告单量",
  "title1763": "请输入关联广告",
  "title1764": "请输入平均每单成本（关联广告）",
  "title1765": "请输入自然访客",
  "title1766": "请输入自然点击率",
  "title1767": "请输入自然单量",
  "title1768": "请输入自然转化率",
  "title1769": "数据日期不能为空",
  "title1770": "添加商品数据",
  "title1771": "修改商品数据",
  "title1772": "是否确认删除商品数据编号为",
  "title1773": "是否确认导出所有商品数据数据项",
  "title1774": "请输入中文翻译",
  "title1775": "请输入搜索量",
  "title1776": "添加商品关键词",
  "title1777": "修改商品关键词",
  "title1778": "是否确认删除商品关键词编号为",
  "title1779": "是否确认导出所有商品关键词数据项",
  "title1780": "商品关键词",
  "title1781": "卖点",
  "title1782": "卖点内容",
  "title1783": "添加卖点备注",
  "title1784": "修改卖点备注",
  "title1785": "是否确认删除卖点备注编号为",
  "title1786": "是否确认导出所有卖点备注数据项",
  "title1787": "批量操作",
  "title1788": "设置销售状态",
  "title1789": "编辑分组",
  "title1790": "设置分组",
  "title1791": "导入导出",
  "title1792": "导入新增组合",
  "title1793": "导出组合商品",
  "title1794": "导入配对关系",
  "title1795": "导出配对关系",
  "title1796": "双击批量搜索",
  "title1797": "所有分组",
  "title1798": "子商品信息",
  "title1799": "展开",
  "title1800": "已匹配商品店铺",
  "title1801": "展开已匹配",
  "title1802": "分组",
  "title1803": "时间",
  "title1804": "配对店铺",
  "title1805": "请输入商品名称",
  "title1806": "请输入商品",
  "title1807": "商品分组",
  "title1808": "商品分组设置",
  "title1809": "请选择销售状态",
  "title1810": "是否组合商品",
  "title1811": "请选择是否组合商品",
  "title1812": "取消选择",
  "title1813": "已选",
  "title1814": "批量搜索",
  "title1815": "请输入多个",
  "title1816": "用换行分割",
  "title1817": "例如",
  "title1818": "组合",
  "title1819": "商品导入新增",
  "title1820": "商品导入模板",
  "title1821": "商品配对导入",
  "title1822": "商品配对导入模板",
  "title1823": "子商品",
  "title1824": "子商品名称",
  "title1825": "商品名称不能为空",
  "title1826": "是否组合商品不能为空",
  "title1827": "销售状态不能为空",
  "title1828": "请选择至少一个商品",
  "title1829": "设置成功",
  "title1830": "请选择商品操作",
  "title1831": "是否确认删除商品？删除后不可恢复，同时会同步删除关联的库存及配对关系！",
  "title1832": "是否确认导出商品配对关系",
  "title1833": "新增组合商品",
  "title1834": "修改组合商品",
  "title1835": "设置销售状态成功",
  "title1836": "请填选择子商品",
  "title1837": "请输入子商品数量",
  "title1838": "是否确认删除商品为",
  "title1839": "添加库存",
  "title1840": "推送三方仓",
  "title1841": "设置仓库",
  "title1842": "设置活跃度",
  "title1843": "导入单商品",
  "title1844": "导入更新商品",
  "title1845": "导出商品",
  "title1846": "活跃度",
  "title1847": "商品完整性",
  "title1848": "选择全部",
  "title1849": "已选择：",
  "title1850": "全部：",
  "title1851": "仓库",
  "title1852": "平均利润率",
  "title1853": "预测日销量",
  "title1854": "库存明细（",
  "title1855": "已配对店铺",
  "title1856": "商品重量",
  "title1857": "商品尺寸",
  "title1858": "商品日志",
  "title1859": "线上销售数据",
  "title1860": "选择仓库",
  "title1861": "请输入仓库名称",
  "title1862": "仓库名称",
  "title1863": "商品导入更新",
  "title1864": "商品更新导入模板",
  "title1865": "爆款",
  "title1866": "旺款",
  "title1867": "平款",
  "title1868": "滞销款",
  "title1869": "请使用主账号或管理员账号进行商品导入和授权",
  "title1870": "请选择仓库",
  "title1871": "设置仓库成功",
  "title1872": "是否确认删除商品",
  "title1873": "删除后不可恢复，同时会同步删除关联的库存及配对关系",
  "title1874": "是否确认推送商品",
  "title1875": "编号为",
  "title1876": "推送成功",
  "title1877": "基本信息",
  "title1878": "选择类目",
  "title1879": "供应商货源链接",
  "title1880": "输入货源链接",
  "title1881": "复制链接",
  "title1882": "产品来源链接",
  "title1883": "输入来源链接",
  "title1884": "商品描述",
  "title1885": "插入图片",
  "title1886": "提示：产品描述可插入12张图片",
  "title1887": "已插入0张",
  "title1888": "商品属性",
  "title1889": "品牌",
  "title1890": "更多品牌",
  "title1891": "销售信息",
  "title1892": "产品类型",
  "title1893": "单品",
  "title1894": "多变体",
  "title1895": "促销价",
  "title1896": "选择活动--",
  "title1897": "刷新",
  "title1898": "输入库存数量",
  "title1899": "批发价",
  "title1900": "新增批发价",
  "title1901": "图片媒体",
  "title1902": "商品图片",
  "title1903": "选择图片",
  "title1904": "图片格式：请上传大小不超过",
  "title1905": "格式为",
  "title1906": "的文件；图片尺寸不得小于100",
  "title1907": "添加视频",
  "title1908": "视频格式：请上传大小不超过",
  "title1909": "时长为10",
  "title1910": "的文件",
  "title1911": "请在视频上传后7天内发布产品，否则视频会被自动删除。",
  "title1912": "包装物流",
  "title1913": "运费",
  "title1914": "输入运费",
  "title1915": "输入商品重量",
  "title1916": "长",
  "title1917": "高",
  "title1918": "是否预购",
  "title1919": "物品状况",
  "title1920": "新品",
  "title1921": "二手",
  "title1922": "智能生成商品名称",
  "title1923": "免责声明：本服务生成的所有内容均由",
  "title1924": "模型自动产生。我们无法保证其准确性、完整性，生成的内容仅供参考，不代表新舰的观点和态度。因使用本服务而产生的任何直接或间接损失，新舰概不承担任何责任。",
  "title1925": "时尚大方",
  "title1926": "新增关键词",
  "title1927": "描绘你脑海中的画面，关键词涵盖品牌、材质、主要功能特点和使用场景等要素。",
  "title1928": "生成结果语言",
  "title1929": "今日免费可用次",
  "title1930": "次，付费可用",
  "title1931": "请先在左侧点击“开始生成”，生成结果会自动在此处展示",
  "title1932": "序号",
  "title1933": "应用",
  "title1934": "智能生成商品描述",
  "title1935": "生成结果",
  "title1936": "请选择类目",
  "title1937": "请输入关键词搜索",
  "title1938": "批量编辑分类",
  "title1939": "将所选的商品统一设置为相同的分类和属性。",
  "title1940": "批量编辑属性",
  "title1941": "共选择了3个产品，分类如下，请分别编辑各自的属性。",
  "title1942": "批量编辑图片",
  "title1943": "每个产品最多支持9张产品图。拖动图像进行排序",
  "title1944": "尺码图",
  "title1945": "商品图",
  "title1946": "拖动可排序",
  "title1947": "本地添加",
  "title1948": "选择空间图片",
  "title1949": "选择采集图片",
  "title1950": "添加采集图片",
  "title1951": "选项1",
  "title1952": "黄金糕",
  "title1953": "选项2",
  "title1954": "双皮奶",
  "title1955": "选项3",
  "title1956": "蚵仔煎",
  "title1957": "选项4",
  "title1958": "龙须面",
  "title1959": "选项5",
  "title1960": "北京烤鸭",
  "title1961": "超大号】猫砂盆",
  "title1962": "箱子：猫咪专属私人空间，让它们更健康，生活更美好！",
  "title1963": "其它",
  "title1964": "选择商品",
  "title1965": "发布",
  "title1966": "新增商品",
  "title1967": "编辑分类",
  "title1968": "编辑属性",
  "title1969": "添加水印",
  "title1970": "在发布前，请务必修改商品信息，以避免与原商品的信息完全相同，从而避免平台处罚。",
  "title1971": "子",
  "title1972": "展开所有变体",
  "title1973": "定时发布",
  "title1974": "已推送，请稍后查看推送情况",
  "title1975": "当前页面仅推送商品",
  "title1976": "到三方仓，组合商品只推送子",
  "title1977": "若",
  "title1978": "编码不在三方仓，则会创建此商品。若存在则更新此商品。",
  "title1979": "服务商的不同，商品",
  "title1980": "命名规范会存在差异，不符合的命名推送会失败。",
  "title1981": "更新信息",
  "title1982": "推送时间",
  "title1983": "商品信息已更新",
  "title1984": "同步线上商品",
  "title1985": "快速生成匹配",
  "title1986": "生成商品",
  "title1987": "合并商品",
  "title1988": "自动配对",
  "title1989": "设置匹配规则",
  "title1990": "解除配对",
  "title1991": "是否配对",
  "title1992": "是否上架",
  "title1993": "线上商品信息",
  "title1994": "已配对",
  "title1995": "未配对",
  "title1996": "本地商品信息",
  "title1997": "配对",
  "title1998": "重新配对",
  "title1999": "新增规则",
  "title2000": "规则执行顺序从上到下依次执行。",
  "title2001": "输入规则名称",
  "title2002": "忽略前缀",
  "title2003": "字符",
  "title2004": "位数",
  "title2005": "分隔符",
  "title2006": "当找到多个分隔符时以",
  "title2007": "最左侧为准",
  "title2008": "最右侧为准",
  "title2009": "忽略后缀",
  "title2010": "不忽略",
  "title2011": "前",
  "title2012": "后",
  "title2013": "替换字符",
  "title2014": "输入字符",
  "title2015": "替换为",
  "title2016": "输入替换字符",
  "title2017": "忽略字符",
  "title2018": "输入忽略字符，一行一条",
  "title2019": "例如：",
  "title2020": "忽略空格",
  "title2021": "规则测试",
  "title2022": "请输入产品",
  "title2023": "编码",
  "title2024": "测试",
  "title2025": "测试结果",
  "title2026": "翻译",
  "title2027": "合并编辑",
  "title2028": "合",
  "title2029": "并",
  "title2030": "保",
  "title2031": "存",
  "title2032": "类别",
  "title2033": "默认仓库",
  "title2034": "已生成并完成配对",
  "title2035": "正在同步线上商品，请稍后",
  "title2036": "确认开始同步线上商品",
  "title2037": "预计1-3分钟完成线上商品同步，同步完成后，您可以方便地查看更新后的商品信息。",
  "title2038": "自动识别配对",
  "title2039": "配对规则",
  "title2040": "匹配【店铺",
  "title2041": "和【商品",
  "title2042": "完全相同",
  "title2043": "按店铺",
  "title2044": "配对，如果店铺",
  "title2045": "为空则无法匹配。",
  "title2046": "忽略前缀、后缀后的【店铺",
  "title2047": "相同",
  "title2048": "输入忽略内容，多个请用逗号“",
  "title2049": "分隔",
  "title2050": "截取后的【店铺",
  "title2051": "截取第",
  "title2052": "输入截取位数",
  "title2053": "到",
  "title2054": "位字符",
  "title2055": "忽略指定字符后的【店铺",
  "title2056": "指定字符",
  "title2057": "输入指定内容，多个请用逗号“",
  "title2058": "不区分大小写",
  "title2059": "配对商品范围",
  "title2060": "当前页商品",
  "title2061": "筛选结果全部商品",
  "title2062": "已勾选商品",
  "title2063": "开始",
  "title2064": "自动识别配对结果",
  "title2065": "自动配对成功",
  "title2066": "商品，确定保存",
  "title2067": "批量移除",
  "title2068": "配对本地商品信息",
  "title2069": "移除",
  "title2070": "上一步",
  "title2071": "未上架",
  "title2072": "前固定字符",
  "title2073": "前固定位数",
  "title2074": "固定分隔符前字符",
  "title2075": "规则名称不能为空",
  "title2076": "是否确认自动配对所选商品",
  "title2077": "个商品",
  "title2078": "含有重复",
  "title2079": "是否解除配对",
  "title2080": "配对解除成功",
  "title2081": "正在同步线上商品，请稍后查看",
  "title2082": "请先配置配对规则",
  "title2083": "成功配对",
  "title2084": "规则保存成功",
  "title2085": "请使用主账号或管理员账号生成商品和授权",
  "title2086": "配对成功",
  "title2087": "盘",
  "title2088": "单个新增",
  "title2089": "批量新增",
  "title2090": "打印货架标签",
  "title2091": "现有库存",
  "title2092": "点击收起",
  "title2093": "展开剩余",
  "title2094": "所属仓库",
  "title2095": "货架位名称",
  "title2096": "种类：",
  "title2097": "货架位编号",
  "title2098": "已存满",
  "title2099": "已存放",
  "title2100": "空置",
  "title2101": "创建",
  "title2102": "更新",
  "title2103": "清理",
  "title2104": "请输入货架位名称",
  "title2105": "最大容量",
  "title2106": "请输入最大容量",
  "title2107": "前缀",
  "title2108": "列数",
  "title2109": "层数",
  "title2110": "连字符",
  "title2111": "预览图",
  "title2112": "可清理",
  "title2113": "货架位编号不能为空",
  "title2114": "请输入列数",
  "title2115": "请输入层数",
  "title2116": "创建货架位",
  "title2117": "确定删除货架位吗？",
  "title2118": "修改货架信息",
  "title2119": "确定清理数量为0的",
  "title2120": "吗？",
  "title2121": "清理成功",
  "title2122": "失败",
  "title2123": "同步三方仓库存",
  "title2124": "打印标签",
  "title2125": "修改商品成本",
  "title2126": "修改打包费用",
  "title2127": "商品成本导入",
  "title2128": "商品打包费导入",
  "title2129": "商品库存导出",
  "title2130": "导入成本",
  "title2131": "库存状态",
  "title2132": "库存总量：",
  "title2133": "库存总货值：",
  "title2134": "冻结总量：",
  "title2135": "冻结总货值：",
  "title2136": "在途总量：",
  "title2137": "在途总货值：",
  "title2138": "采",
  "title2139": "货架位",
  "title2140": "库存价值",
  "title2141": "平均日销量",
  "title2142": "日销量（动态）",
  "title2143": "安全库存量",
  "title2144": "断货日期",
  "title2145": "断货建议采购数量",
  "title2146": "缺货建议采购数量",
  "title2147": "缺货数量",
  "title2148": "建议采购日期",
  "title2149": "最近一批预估到货时间",
  "title2150": "设置货架位",
  "title2151": "请输入商品成本",
  "title2152": "批量修改近期销量",
  "title2153": "近期销量",
  "title2154": "请输入近期销量",
  "title2155": "批量修改库存参数",
  "title2156": "生产天数",
  "title2157": "请输入生产天数",
  "title2158": "海运天数",
  "title2159": "请输入海运天数",
  "title2160": "额外备货天数",
  "title2161": "请输入额外备货天数",
  "title2162": "安全库存计算公式",
  "title2163": "额外备货天数）",
  "title2164": "近15天平均日销量",
  "title2165": "商品库存导入",
  "title2166": "商品库存导入模板",
  "title2167": "商品打包费导入模板",
  "title2168": "三方仓总库存",
  "title2169": "双方差异库存",
  "title2170": "预更新可用库存",
  "title2171": "库存锁定情况",
  "title2172": "已锁总数：",
  "title2173": "已锁",
  "title2174": "请输入多个商品",
  "title2175": "用换行分割",
  "title2176": "用换行或英文逗号分",
  "title2177": "批量修改商品成本",
  "title2178": "低于警戒",
  "title2179": "默认日销量",
  "title2180": "更新成功",
  "title2181": "请选择数据操作",
  "title2182": "批量修改打包费",
  "title2183": "修改安全库存",
  "title2184": "的库存",
  "title2185": "是否确认删除选中的商品库存",
  "title2186": "是否确认删除商品库存编号为",
  "title2187": "确定设置货架位",
  "title2188": "操作成功",
  "title2189": "商品库存-",
  "title2190": "上传填好的信息表",
  "title2191": "请先",
  "title2192": "导出表单数据",
  "title2193": "表头名称不可更改，表头行不能删除",
  "title2194": "文件后缀名必须为",
  "title2195": "（即Excel格式）格式的文件，大小不超过",
  "title2196": "格式，文件大小不得大于",
  "title2197": "最多支持导入",
  "title2198": "条数据",
  "title2199": "上传文件",
  "title2200": "下载模板",
  "title2201": "导入成功！您已成功导入",
  "title2202": "条数据。",
  "title2203": "文件没有数据，请检查。",
  "title2204": "导入失败！请查看错误报告，或检查模板是否错误后重新导入。",
  "title2205": "下载错误报告",
  "title2206": "重新导入",
  "title2207": "错误文件",
  "title2208": "请上传文件",
  "title2209": "在库货值",
  "title2210": "可售天数",
  "title2211": "在途中货值",
  "title2212": "数据预览",
  "title2213": "导入数据",
  "title2214": "导入完成",
  "title2215": "库龄设置",
  "title2216": "总库存",
  "title2217": "库存总成本",
  "title2218": "平均库龄",
  "title2219": "库存成本",
  "title2220": "组数",
  "title2221": "最小天数",
  "title2222": "最大天数",
  "title2223": "第一组",
  "title2224": "第二组",
  "title2225": "第三组",
  "title2226": "第四组",
  "title2227": "第五组",
  "title2228": "天以上",
  "title2229": "天数为大于0的整数",
  "title2230": "第一组最大天数不能为空",
  "title2231": "第二组最大天数不能为空",
  "title2232": "第三组最大天数不能为空",
  "title2233": "第四组最大天数不能为空",
  "title2234": "生成采购单",
  "title2235": "建议采购日",
  "title2236": "预计断货日",
  "title2237": "最近到货时间",
  "title2238": "主图",
  "title2239": "采购规格",
  "title2240": "海外仓库存",
  "title2241": "日均",
  "title2242": "周转天数",
  "title2243": "预计断货日期",
  "title2244": "建议采购量",
  "title2245": "在途中数量",
  "title2246": "最近一批预估到货数量",
  "title2247": "商品成本不能为空",
  "title2248": "近15天销量",
  "title2249": "实际库存",
  "title2250": "建议采购数量",
  "title2251": "安全库存-在途库存-实际库存",
  "title2252": "采购在途",
  "title2253": "调拨在途",
  "title2254": "是否确认导出所有商品库存数据项",
  "title2255": "商品库存",
  "title2256": "平台上库存",
  "title2257": "打开页面",
  "title2258": "已处理",
  "title2259": "确认运营已操作",
  "title2260": "店铺利润月报",
  "title2261": "运营利润月报",
  "title2262": "收货管理",
  "title2263": "仓库为三方仓采购单不支持手动收货，系统会自动同步三方仓的收货结果。",
  "title2264": "发货仓库",
  "title2265": "收货仓库",
  "title2266": "发货时间",
  "title2267": "收货时间",
  "title2268": "调拨数量",
  "title2269": "收货数量",
  "title2270": "调拨单号",
  "title2271": "调拨备注",
  "title2272": "运单号",
  "title2273": "调拨总数",
  "title2274": "收货状态",
  "title2275": "待收货",
  "title2276": "部分收货",
  "title2277": "已收货",
  "title2278": "确认收货",
  "title2279": "采购数量",
  "title2280": "采购单名称",
  "title2281": "采购备注",
  "title2282": "采购单号",
  "title2283": "采购总数",
  "title2284": "修改备注",
  "title2285": "请填写备注信息",
  "title2286": "调拨收货",
  "title2287": "调拨单号：",
  "title2288": "已收货数量",
  "title2289": "无货架位",
  "title2290": "采购收货",
  "title2291": "接收仓库",
  "title2292": "货运日期",
  "title2293": "头程费用",
  "title2294": "税费",
  "title2295": "其它费用",
  "title2296": "商品数量：",
  "title2297": "总数：",
  "title2298": "总额：",
  "title2299": "体积",
  "title2300": "重量",
  "title2301": "发货仓库不能为空",
  "title2302": "收货仓库不能为空",
  "title2303": "请选择项目",
  "title2304": "更换发货仓库会清空已添加的商品，确定要更新吗",
  "title2305": "请输入收货数量",
  "title2306": "收货成功",
  "title2307": "调拨",
  "title2308": "数据？",
  "title2309": "请输入采购单名称",
  "title2310": "创建日期",
  "title2311": "单价",
  "title2312": "采购总额",
  "title2313": "入库数量",
  "title2314": "选择采购单",
  "title2315": "缺货建议",
  "title2316": "采购单",
  "title2317": "新增采购单",
  "title2318": "推送采购单",
  "title2319": "批量提交",
  "title2320": "暂不采购",
  "title2321": "还原",
  "title2322": "设置规则",
  "title2323": "断货建议",
  "title2324": "采购天数",
  "title2325": "从采购下单到采购入库的预估时间，采购天数",
  "title2326": "运输天数",
  "title2327": "安全天数",
  "title2328": "为防止采购的产品不能准时到达而设置的缓冲天数",
  "title2329": "总销量3天",
  "title2330": "日均销量3天",
  "title2331": "建议数量",
  "title2332": "缺货建议与断货建议较大者",
  "title2333": "备货时长",
  "title2334": "备货时长-可用库存-在途中数量",
  "title2335": "计划采购",
  "title2336": "采购总数量",
  "title2337": "预计到货",
  "title2338": "商品列表",
  "title2339": "请输入商品列表",
  "title2340": "预计到货日期",
  "title2341": "请选择日期",
  "title2342": "请输入运单号",
  "title2343": "请输入运费",
  "title2344": "选择分摊方式",
  "title2345": "请输入头程费",
  "title2346": "请输入税费",
  "title2347": "请输入其他费",
  "title2348": "审核意见",
  "title2349": "驳回时需输入审核意见",
  "title2350": "请输入审核意见",
  "title2351": "请输入备注信息",
  "title2352": "分摊费用",
  "title2353": "入库成本",
  "title2354": "采购单导入",
  "title2355": "采购单导入模板",
  "title2356": "仓库不能为空",
  "title2357": "采购单名称不能为空",
  "title2358": "商品列表不能为空",
  "title2359": "添加采购单",
  "title2360": "查看采购单",
  "title2361": "修改采购单",
  "title2362": "提交成功",
  "title2363": "是否确认取消采购单",
  "title2364": "是否确认收货并入库",
  "title2365": "是否确认删除名称为",
  "title2366": "的采购单",
  "title2367": "是否确认导出所有采购单数据项",
  "title2368": "是否确认导出采购建议",
  "title2369": "退货总览",
  "title2370": "退货报告",
  "title2371": "退货明细",
  "title2372": "店铺利润数据统计",
  "title2373": "轻松统计分析店铺经营状况、帮助店主了解店铺的盈利情况，优化经营策略以提高利润。",
  "title2374": "默认全部",
  "title2375": "请选择运营人员",
  "title2376": "选择日期",
  "title2377": "订单收入",
  "title2378": "收入明细",
  "title2379": "数据",
  "title2380": "商品金额",
  "title2382": "平台补贴",
  "title2383": "运费补贴",
  "title2384": "成本",
  "title2385": "支出明细",
  "title2386": "推广费",
  "title2387": "推广明细",
  "title2388": "站内广告费",
  "title2389": "站外广告费",
  "title2390": "测评付款金额",
  "title2391": "测评回款金额",
  "title2392": "操作费",
  "title2393": "空包费",
  "title2394": "测评付款金额-测评回款金额",
  "title2395": "其他明细",
  "title2396": "跨月调整",
  "title2397": "订单有跨月结算情况的调整总和",
  "title2398": "平台调整",
  "title2399": "平台调账部分的费用总和",
  "title2400": "店铺费用",
  "title2401": "利润明细",
  "title2402": "销售利润率",
  "title2403": "各项支出占比分布",
  "title2404": "支出占销售额分布",
  "title2405": "店铺利润数据",
  "title2406": "按回款时间",
  "title2407": "按下单时间",
  "title2408": "请选择要更新的月份",
  "title2409": "利润月报-",
  "title2410": "店铺授权成功",
  "title2411": "恭喜您，您已完成店铺授权",
  "title2412": "买家签收前",
  "title2413": "对于发货仓库指定为三方仓的订单，退货时接收仓库会默认为发货仓库。在三方仓库成功收到退货后，系统会自动同步三方仓库的收货结果。",
  "title2414": "同步订单",
  "title2415": "推送退货单",
  "title2416": "入库状态",
  "title2417": "截止时间",
  "title2418": "商品订单号：",
  "title2419": "退货原因",
  "title2420": "订单时间",
  "title2421": "售后时间",
  "title2422": "平台状态",
  "title2423": "在",
  "title2424": "天内回应",
  "title2425": "请在",
  "title2426": "前回应",
  "title2427": "退货单号：",
  "title2428": "退回订单号",
  "title2429": "请输入退回订单号",
  "title2430": "请输入原因",
  "title2431": "退回状态",
  "title2432": "请选择字典生成",
  "title2433": "退回本地状态",
  "title2434": "退回订单创建时间",
  "title2435": "选择退回订单创建时间",
  "title2436": "退回订单更新时间",
  "title2437": "选择退回订单更新时间",
  "title2438": "退货运单号",
  "title2439": "未入库",
  "title2440": "已入库",
  "title2441": "退回订单号不能为空",
  "title2442": "原因不能为空",
  "title2443": "退回状态不能为空",
  "title2444": "退回本地状态不能为空",
  "title2445": "退回订单创建时间不能为空",
  "title2446": "退回订单更新时间不能为空",
  "title2447": "添加退回订单",
  "title2448": "修改退回订单",
  "title2449": "是否确认删除退回订单编号为",
  "title2450": "是否确认导出所有退回订单数据项",
  "title2451": "退回订单",
  "title2452": "全部站点",
  "title2453": "是否",
  "title2454": "处理人",
  "title2455": "处理入库",
  "title2456": "查看详情",
  "title2457": "添加备注",
  "title2458": "退货原因：",
  "title2459": "已入库详情",
  "title2460": "入库处理",
  "title2461": "是否入库",
  "title2462": "入库",
  "title2463": "全部不入库",
  "title2464": "一旦处理入库，退货单是无法取消入库，",
  "title2465": "请谨慎操作",
  "title2466": "包裹号",
  "title2467": "关",
  "title2468": "闭",
  "title2469": "赔付金额",
  "title2470": "输入备注",
  "title2471": "备注日期",
  "title2472": "备注信息",
  "title2473": "退回原因",
  "title2474": "请输入正整数",
  "title2475": "售后订单号",
  "title2476": "商品订单号",
  "title2477": "请选择是否入库",
  "title2478": "退货退款-",
  "title2479": "输入角色名称",
  "title2480": "角色状态",
  "title2481": "角色名称",
  "title2482": "功能权限",
  "title2483": "配置权限",
  "title2484": "数据权限",
  "title2485": "请输入角色名称",
  "title2486": "角色标识",
  "title2487": "请输入角色标识",
  "title2488": "角色顺序",
  "title2489": "输入备注信息",
  "title2490": "菜单权限",
  "title2491": "加载中，请稍后",
  "title2492": "分配数据权限",
  "title2493": "权限范围",
  "title2494": "父子联动",
  "title2495": "选中父节点，自动选择子节点",
  "title2496": "角色名称不能为空",
  "title2497": "角色标识不能为空",
  "title2498": "角色顺序不能为空",
  "title2499": "启用",
  "title2500": "停用",
  "title2501": "确认要",
  "title2502": "角色吗",
  "title2503": "添加角色",
  "title2504": "编辑角色",
  "title2505": "编辑成功",
  "title2506": "是否确认删除角色为：",
  "title2507": "是否确认导出所有角色数据项",
  "title2508": "角色数据",
  "title2509": "请输入关键词",
  "title2510": "请选择平台",
  "title2511": "已过期",
  "title2512": "已授权",
  "title2513": "店铺别名",
  "title2514": "授权时间",
  "title2515": "刷新时间",
  "title2516": "授权状态",
  "title2517": "点击“授权”后",
  "title2518": "将为你打开",
  "title2519": "授权页面进行授权",
  "title2520": "请输入店铺别名",
  "title2521": "授权",
  "title2522": "店铺别名不能为空",
  "title2523": "添加店铺授权",
  "title2524": "编辑店铺",
  "title2525": "刷新授权成功",
  "title2526": "刷新授权失败，请重新授权",
  "title2527": "新增店铺授权成功",
  "title2528": "请谨慎操作，删除店铺后，将删除以下数据：店铺的产品信息将会彻底删除且无法恢复。店铺的订单信息将被清空且无法恢复。店铺的相关设置信息将被彻底删除且无法恢复。",
  "title2529": "是否确认导出所有店铺数据项",
  "title2530": "创建成功",
  "title2531": "动销率",
  "title2532": "新增评价数",
  "title2533": "请输入店铺评分",
  "title2534": "选择发货时效",
  "title2535": "请输入逾期发货率",
  "title2536": "请输入未完成率",
  "title2537": "请输入回复率",
  "title2538": "选择响应时间",
  "title2539": "请输入动销率",
  "title2540": "请输入新增评价数",
  "title2541": "添加店铺数据",
  "title2542": "修改店铺数据",
  "title2543": "是否确认删除店铺数据编号为",
  "title2544": "是否确认导出所有店铺数据数据项",
  "title2545": "选择费用月份",
  "title2546": "请选择费用类型",
  "title2547": "费用月份",
  "title2548": "请输入金额（正数表示支出，负数表示收入",
  "title2549": "费用月份不能为空",
  "title2550": "费用类型不能为空",
  "title2551": "金额不能为空",
  "title2552": "添加店铺费用",
  "title2553": "修改店铺费用",
  "title2554": "是否确认删除店铺费用为：",
  "title2555": "未处理",
  "title2556": "物流名称",
  "title2557": "包裹类型",
  "title2558": "运费亏损未处理金额：",
  "title2559": "运费亏损已处理金额：",
  "title2561": "运费差额",
  "title2562": "卖家运费折扣",
  "title2563": "退货运费",
  "title2564": "运费调整",
  "title2565": "运费状态",
  "title2566": "正常",
  "title2567": "亏损",
  "title2568": "盈余",
  "title2569": "物流",
  "title2570": "单号",
  "title2571": "买家指定物流",
  "title2572": "处理",
  "title2573": "请输入多个订单号，用换行分割",
  "title2574": "处理亏损",
  "title2575": "确认操作后，运费状态将变更为【亏损已处理】，无法恢复。是否确定继续？",
  "title2576": "平台订单",
  "title2577": "物流公司",
  "title2578": "单品单数",
  "title2579": "多品多数",
  "title2580": "已备注",
  "title2581": "未备注",
  "title2582": "是否确认发货所选订单",
  "title2583": "发货成功",
  "title2584": "请填写备注",
  "title2585": "备注成功",
  "title2586": "暂无权限",
  "title2587": "是否确认导出订单信息",
  "title2588": "全部订单",
  "title2589": "测评订单",
  "title2590": "已搁置",
  "title2591": "审核",
  "title2592": "发货",
  "title2593": "撤回",
  "title2594": "新增手工单",
  "title2595": "推送订单",
  "title2596": "修改揽货方式",
  "title2597": "刷新选中最新成本",
  "title2598": "搁置",
  "title2599": "取消搁置",
  "title2600": "标记测评",
  "title2601": "取消标记测评",
  "title2602": "匹配关系",
  "title2603": "移入待安排",
  "title2604": "追加商品",
  "title2605": "批量打印",
  "title2606": "打印拣货单",
  "title2607": "打印面单",
  "title2608": "打印面单和配货单",
  "title2609": "标记已打印面单",
  "title2610": "标记未打印面单",
  "title2611": "标记已打印拣货单",
  "title2612": "标记未打印拣货单",
  "title2613": "刷新库存",
  "title2614": "打印状态",
  "title2615": "完整性",
  "title2616": "全部状态",
  "title2617": "标记",
  "title2618": "导出订单列表",
  "title2619": "导出买家信息",
  "title2620": "撤回失败的订单实际还在三方仓履约，请尽快联系三方仓储服务商核实订单状态，以避免因发货导致的经济损失。",
  "title2621": "买家备注",
  "title2622": "添加标记",
  "title2623": "实付金额",
  "title2624": "收入",
  "title2625": "总计",
  "title2626": "实际收入",
  "title2627": "支出",
  "title2628": "试算",
  "title2629": "打包费",
  "title2630": "平台优惠",
  "title2631": "收货人",
  "title2632": "未知状态",
  "title2633": "预购",
  "title2634": "测评",
  "title2635": "到期时间",
  "title2636": "根据不同平台政策，过期时间不一定代表订单被平台取消，订单是否被平台取消以平台状态",
  "title2637": "为准",
  "title2638": "物流设置",
  "title2639": "移除说明：",
  "title2640": "订单移除，并加入推送成功的列表中。",
  "title2641": "已打印面单",
  "title2642": "标记时间：",
  "title2643": "已打印拣货单",
  "title2644": "已打印发票",
  "title2645": "待安排",
  "title2646": "安排中",
  "title2647": "安排成功",
  "title2648": "修改匹配",
  "title2649": "更改当前订单所有相同",
  "title2650": "更改所有",
  "title2651": "匹配关系（仅对该店铺下的待处理订单生效，并同时进行",
  "title2652": "匹配关系的更新）",
  "title2653": "总额",
  "title2654": "订单同步时间设置",
  "title2655": "开始同步",
  "title2656": "订单同步",
  "title2657": "批量同步",
  "title2658": "全部平台",
  "title2659": "请输入店铺名称",
  "title2660": "同步中",
  "title2661": "正在同步订单数据",
  "title2662": "进度",
  "title2663": "稍后查看",
  "title2664": "添加手工订单",
  "title2665": "请选择类型",
  "title2666": "线上手工订单",
  "title2667": "线下手工订单",
  "title2668": "订单信息",
  "title2669": "付款方式",
  "title2670": "请选择付款方式",
  "title2671": "物流名称",
  "title2672": "请输入物流名称",
  "title2673": "请输入买家指定物流",
  "title2674": "其他附件",
  "title2675": "上传附件",
  "title2676": "收件人",
  "title2677": "请输入收件人",
  "title2678": "手机号码",
  "title2679": "请输入手机号码",
  "title2680": "国家",
  "title2681": "选择地区",
  "title2682": "省",
  "title2683": "州",
  "title2684": "输入省",
  "title2685": "请输入城市",
  "title2686": "区",
  "title2687": "县",
  "title2688": "请输入区",
  "title2689": "邮编",
  "title2690": "请输入邮编",
  "title2691": "详细地址",
  "title2692": "请输入详细地址",
  "title2693": "买家留言",
  "title2694": "请输入买家留言",
  "title2695": "选择币种",
  "title2696": "添加商品",
  "title2697": "总价",
  "title2698": "请输入大于0的正整数",
  "title2699": "未打印",
  "title2700": "面单",
  "title2701": "已打印",
  "title2702": "拣货单",
  "title2703": "非预购",
  "title2704": "单品多数",
  "title2705": "多品混包",
  "title2706": "买家支付",
  "title2707": "卖家支付",
  "title2708": "最近2个月",
  "title2709": "自定义日期",
  "title2710": "系统订单号不能为空",
  "title2711": "平台订单号不能为空",
  "title2712": "区域不能为空",
  "title2713": "买家信息不能为空",
  "title2714": "物流跟踪信息不能为空",
  "title2715": "是否预购不能为空",
  "title2716": "是否缺货不能为空",
  "title2717": "系统状态不能为空",
  "title2718": "标记不能为空",
  "title2719": "付款方式不能为空",
  "title2720": "请选择国家",
  "title2721": "请输入省",
  "title2722": "已超时",
  "title2723": "已取消",
  "title2724": "待打单",
  "title2725": "运输中",
  "title2726": "已妥投",
  "title2727": "运输失败",
  "title2728": "退货订单",
  "title2729": "退货退款中",
  "title2730": "请选择订单操作",
  "title2731": "是否确认同步订单",
  "title2732": "同步请求成功，请稍候刷新查看",
  "title2733": "是否确认刷新最新成本",
  "title2734": "刷新成本成功",
  "title2735": "标记测评成功",
  "title2736": "取消标记测评成功",
  "title2737": "保存仓库成功",
  "title2738": "是否确认标记测评所选订单",
  "title2739": "是否确认审核所选订单",
  "title2740": "是否确认发货所选订单？",
  "title2741": "所选订单？",
  "title2742": "是否确认推送所选订单？",
  "title2743": "正在推送中，请稍后查看",
  "title2744": "是否确认取消标记测评所选订单",
  "title2745": "是否确认搁置所选订单",
  "title2746": "搁置成功",
  "title2747": "是否确认取消搁置所选订单",
  "title2748": "取消搁置成功",
  "title2749": "标记成功",
  "title2750": "是否将所选订单移入待安排",
  "title2751": "添加标记成功",
  "title2752": "是否确认删除标记：",
  "title2753": "删除标记成功",
  "title2754": "同步成功",
  "title2755": "取消拆单后所有子包裹将回滚到主包裹中，确定取消拆单？",
  "title2756": "取消拆单成功",
  "title2757": "是否确认安排编号为",
  "title2758": "的订单",
  "title2759": "是否确认撤回所选订单",
  "title2760": "撤回成功",
  "title2761": "是否确认移除所选订单",
  "title2762": "移除成功",
  "title2763": "是否确认搁置编号为",
  "title2764": "是否确认取消搁置编号为",
  "title2765": "是否确认标记编号为",
  "title2766": "的订单为测评订单",
  "title2767": "系统提示",
  "title2768": "标记测评订单成功",
  "title2769": "是否确认取消标记编号为",
  "title2770": "取消标记测评订单成功",
  "title2771": "拣货备注",
  "title2772": "是否确认删除订单编号为",
  "title2773": "是否确认删除追加的商品",
  "title2774": "请选择揽货方式",
  "title2775": "保存揽货方式成功",
  "title2776": "请选择商品或取消",
  "title2777": "追加成功",
  "title2778": "是否确认导出买家信息",
  "title2779": "请输入属采购单属性",
  "title2780": "请选择是否预售",
  "title2781": "请选择商品状况",
  "title2782": "商品视频",
  "title2783": "尺码表",
  "title2784": "是否预售",
  "title2785": "商品状况",
  "title2786": "简要描述",
  "title2787": "详情描述",
  "title2788": "售价",
  "title2789": "广告浏览数",
  "title2790": "收藏量",
  "title2791": "请输入类目",
  "title2792": "请输入品牌",
  "title2793": "请输入商品图片",
  "title2794": "请输入商品视频",
  "title2795": "请输入属性",
  "title2796": "请输入商品重量",
  "title2797": "请输入商品尺寸",
  "title2798": "请输入尺码表",
  "title2799": "请输入简要描述",
  "title2800": "请输入详情描述",
  "title2801": "请输入售价",
  "title2802": "请输入广告浏览数",
  "title2803": "请输入收藏量",
  "title2805": "请输入评论数",
  "title2806": "类目不能为空",
  "title2807": "品牌不能为空",
  "title2808": "是否预售不能为空",
  "title2809": "商品状况不能为空",
  "title2810": "类型不能为空",
  "title2811": "售价不能为空",
  "title2812": "广告浏览数不能为空",
  "title2813": "收藏量不能为空",
  "title2815": "评分不能为空",
  "title2816": "评论数不能为空",
  "title2817": "添加线上商品",
  "title2818": "修改线上商品",
  "title2819": "是否确认删除线上商品",
  "title2820": "是否确认导出所有线上商品数据项",
  "title2821": "线上商品",
  "title2823": "买家运费",
  "title2824": "卖家运费",
  "title2825": "毛利率",
  "title2826": "订单列表",
  "title2827": "物流卖家信息",
  "title2828": "实际收入：",
  "title2829": "实际利润：",
  "title2830": "预估收入：",
  "title2832": "合计",
  "title2833": "是否确认导出商品利润表",
  "title2834": "商品利润",
  "title2835": "请输入线上商品",
  "title2836": "父",
  "title2837": "请输入父",
  "title2838": "请输入库存",
  "title2839": "请选择平台状态",
  "title2840": "本地状态",
  "title2841": "请输入本地状态",
  "title2842": "库存不能为空",
  "title2843": "平台状态不能为空",
  "title2844": "本地状态不能为空",
  "title2845": "是否确认导出所有线上商品",
  "title2846": "更新成本",
  "title2847": "更新商品成本",
  "title2848": "添加标记订单",
  "title2849": "订单回款核对",
  "title2850": "选择时间类型",
  "title2851": "回款状态",
  "title2852": "订单完整性",
  "title2853": "正常订单",
  "title2854": "未更新运费",
  "title2855": "未更新成本",
  "title2856": "当前搜索结果中有",
  "title2857": "个未回款订单",
  "title2858": "下单：",
  "title2859": "回款：",
  "title2860": "标签",
  "title2861": "回款金额",
  "title2862": "已回款",
  "title2863": "未回款",
  "title2864": "回款对账",
  "title2865": "上传回款表",
  "title2866": "订单数：",
  "title2867": "回款金额：",
  "title2868": "平台订单数：",
  "title2869": "复制订单号",
  "title2870": "多出来订单",
  "title2871": "店铺后台多出来订单",
  "title2872": "店铺回款金额",
  "title2873": "全部类型",
  "title2874": "回款金额不一致",
  "title2875": "开始对账",
  "title2876": "上传商品成本",
  "title2877": "请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除",
  "title2878": "格式",
  "title2879": "文件大小不得大于",
  "title2880": "通过商品",
  "title2881": "查询订单信息，可以修改商品的成本价格。请注意，不支持修改没有商品",
  "title2882": "的订单的成本。测评、搁置、取消、已操作入库的订单商品成本不做处理",
  "title2883": "按",
  "title2884": "双击批量输入",
  "title2885": "单商品成本",
  "title2886": "批量编辑",
  "title2887": "回款时间",
  "title2888": "所有订单",
  "title2889": "亏损订单",
  "title2890": "搁置订单",
  "title2891": "缺失订单",
  "title2892": "跨月调整订单",
  "title2893": "确定取消",
  "title2894": "请输入成本",
  "title2895": "未检测到已修改的商品成本，请修改商品成本后再操作",
  "title2896": "成本更新成功",
  "title2897": "对账模板",
  "title2898": "更新商品成本导入模板",
  "title2899": "请选择月份",
  "title2900": "请选择下单时间",
  "title2901": "订单利润明细-",
  "title2902": "选择产品",
  "title2903": "请选择产品",
  "title2904": "请输入入库单号",
  "title2905": "入库单价",
  "title2906": "入库单号",
  "title2907": "已驳回",
  "title2908": "待提交",
  "title2909": "入库仓库",
  "title2910": "库存单价",
  "title2911": "入库单导入",
  "title2912": "入库单导入模板",
  "title2913": "请先选择仓库",
  "title2914": "确认删除所选商品？",
  "title2915": "添加入库单",
  "title2916": "修改入库单",
  "title2917": "查看入库单",
  "title2918": "是否确认入库",
  "title2919": "入库成功",
  "title2920": "请输入入库数量",
  "title2921": "是否确认删除库存单为：",
  "title2922": "是否确认导出所有库存单数据项",
  "title2923": "库存单",
  "title2924": "操作时间",
  "title2925": "变动库存",
  "title2926": "原冻结库存",
  "title2927": "新冻结库存",
  "title2928": "原可用库存",
  "title2929": "新可用库存",
  "title2930": "出入库信息",
  "title2932": "请先选择时间范围",
  "title2933": "上传盘点",
  "title2934": "导入成功！您已成功导入1000条数据。",
  "title2935": "请输入出库单号",
  "title2936": "出库数量",
  "title2937": "出库单号",
  "title2938": "出库",
  "title2939": "出库仓库",
  "title2940": "出库单导入",
  "title2941": "出库单导入模板",
  "title2942": "添加出库单",
  "title2943": "修改出库单",
  "title2944": "查看出库单",
  "title2945": "是否确认出库",
  "title2946": "出库成功",
  "title2947": "请输入出库数量",
  "title2948": "区域",
  "title2949": "请输入区域",
  "title2950": "请输入退货率",
  "title2951": "退货率不能为空",
  "title2952": "天店铺利润",
  "title2953": "天店铺利润编号为",
  "title2954": "天店铺利润数据项",
  "title2955": "创建盘点任务",
  "title2956": "盘点类型",
  "title2957": "盘点编号",
  "title2958": "盘点",
  "title2959": "数",
  "title2960": "盘点进度",
  "title2961": "结束",
  "title2962": "开始盘点",
  "title2963": "结束盘点",
  "title2964": "请选择盘点类型",
  "title2965": "当前仓库可盘点",
  "title2966": "导入商品",
  "title2967": "盘点中",
  "title2968": "导出盘点数",
  "title2969": "导入盘点",
  "title2970": "盘点数",
  "title2971": "盘点结果",
  "title2972": "导入模板",
  "title2973": "盘点导入",
  "title2974": "盘点导入模板",
  "title2975": "盘点类型不能为空",
  "title2976": "盘盈",
  "title2977": "盘平",
  "title2978": "盘亏",
  "title2979": "新建盘点任务",
  "title2980": "修改盘点",
  "title2981": "查看盘点",
  "title2982": "仓库盘点",
  "title2983": "是否确定结束盘点",
  "title2984": "结束盘点成功",
  "title2985": "新建盘点成功",
  "title2986": "是否确认删除编号为",
  "title2987": "的盘点",
  "title2988": "删除盘点成功",
  "title2989": "是否确认导出盘点列表",
  "title2990": "盘点列表",
  "title2991": "是否确认导出所有仓库盘点数据项",
  "title2992": "新建调拨单",
  "title2993": "导入调拨单",
  "title2994": "导出调拨单",
  "title2995": "收货",
  "title2996": "确认发货",
  "title2997": "请选择发货仓库",
  "title2998": "请选择收货仓库",
  "title2999": "费用分摊方式",
  "title3000": "请选择费用分摊方式",
  "title3001": "批量修改",
  "title3002": "数量：",
  "title3003": "调拨价",
  "title3004": "最终调拨价",
  "title3005": "批量修改调拨数量",
  "title3006": "等于可用库存",
  "title3007": "设置统一库存值",
  "title3008": "输入统一库存值",
  "title3009": "调拨单导入",
  "title3010": "调拨单导入模板",
  "title3011": "请先选择发货仓库",
  "title3012": "是否确认发货",
  "title3013": "未收货的库存会被还原到发货仓库，是否确认取消",
  "title3014": "修改调拨单",
  "title3015": "请输入调拨数量",
  "title3016": "可用库存不够调拨",
  "title3017": "的调拨单",
  "title3018": "是否确认导出所有调拨数据",
  "title3019": "调拨单",
  "title3020": "请选择回款方式",
  "title3021": "供应商名称",
  "title3022": "联系人",
  "title3023": "联系电话",
  "title3024": "回款方式",
  "title3025": "请输入供应商名称",
  "title3026": "采购渠道",
  "title3027": "请输入采购渠道",
  "title3028": "公司名称",
  "title3029": "请输入公司名称",
  "title3030": "公司地址",
  "title3031": "请输入公司地址",
  "title3032": "供应商网址",
  "title3033": "请输入供应商网址",
  "title3034": "开户银行",
  "title3035": "请输入开户银行",
  "title3036": "收款账号",
  "title3037": "请输入收款账号",
  "title3038": "请输入联系人",
  "title3039": "请输入联系电话",
  "title3040": "请输入微信",
  "title3041": "邮箱",
  "title3042": "请输入邮箱",
  "title3043": "供应商名称不能为空",
  "title3044": "采购渠道不能为空",
  "title3045": "添加供应商",
  "title3046": "编辑供应商",
  "title3047": "是否确认删除供应商",
  "title3048": "是否确认导出所有供应商数据项",
  "title3049": "供应商",
  "title3050": "请输入授权",
  "title3051": "站点编号",
  "title3052": "请输入站点编号",
  "title3053": "仓库编号",
  "title3054": "请输入仓库编号",
  "title3055": "是否启用",
  "title3056": "仓库地址",
  "title3057": "请输入仓库地址",
  "title3058": "仓库名称不能为空",
  "title3059": "是否启用不能为空",
  "title3060": "站点编号不能为空",
  "title3061": "仓库编号不能为空",
  "title3062": "添加三方仓授权仓库",
  "title3063": "修改三方仓授权仓库",
  "title3064": "是否确认删除三方仓授权仓库编号为",
  "title3065": "是否确认导出所有三方仓授权仓库数据项",
  "title3066": "三方仓授权仓库",
  "title3067": "付款汇率",
  "title3068": "修改测评参数",
  "title3069": "添加测评参数",
  "title3070": "请输入操作费",
  "title3071": "请输入空包费",
  "title3072": "请先选择店铺",
  "title3073": "请输入付款汇率",
  "title3074": "您设置的付款汇率波动较大，请核对后再提交",
  "title3075": "操作费不能为空",
  "title3076": "空包费不能为空",
  "title3077": "是否确认删除测评参数",
  "title3078": "是否确认导出所有测评参数数据项",
  "title3079": "测评参数",
  "title3080": "请搜索仓库名称",
  "title3081": "密钥",
  "title3082": "密钥对应的密钥",
  "title3083": "帐号",
  "title3084": "请输入帐号",
  "title3085": "授权信息",
  "title3086": "未授权",
  "title3087": "添加仓库",
  "title3088": "授权信息请与第三方服务商获取，绑定多个",
  "title3089": "会导致订单多次推送，造成订单多发错发，建议只绑定当前账号。",
  "title3090": "自定义名称",
  "title3091": "输入仓库自定义名称",
  "title3092": "第三方服务商名称",
  "title3093": "输入",
  "title3094": "客户编码",
  "title3095": "请输入客户编码",
  "title3096": "自定义名称不能为空",
  "title3097": "授权三方仓服务商",
  "title3098": "授权成功",
  "title3099": "是否确认删除三方仓配置信息为",
  "title3100": "是否确认导出所有三方仓配置信息数据项",
  "title3101": "三方仓配置信息",
  "title3102": "厂商名称",
  "title3103": "请输入厂商名称",
  "title3104": "厂商编号",
  "title3105": "请输入厂商编号",
  "title3106": "厂商",
  "title3107": "未开启",
  "title3108": "已开启",
  "title3109": "官网",
  "title3110": "商标",
  "title3111": "不开启",
  "title3112": "厂商名称不能为空",
  "title3113": "厂商编号不能为空",
  "title3114": "添加三方仓厂商",
  "title3115": "修改三方仓厂商",
  "title3116": "是否确认切换三方仓厂商状态，编号为",
  "title3117": "切换状态成功",
  "title3118": "是否确认删除三方仓厂商编号为",
  "title3119": "是否确认导出所有三方仓厂商数据项",
  "title3120": "三方仓厂商",
  "title3121": "角色管理",
  "title3122": "请输入账号信息",
  "title3123": "全部角色",
  "title3124": "姓名",
  "title3125": "角色",
  "title3126": "分配店铺",
  "title3127": "分配仓库",
  "title3128": "分配角色",
  "title3129": "角色扮演",
  "title3130": "请输入用户名",
  "title3131": "请输入姓名",
  "title3132": "添加用户",
  "title3133": "登录密码",
  "title3134": "请输6",
  "title3135": "位登录密码",
  "title3136": "创建子账号",
  "title3137": "输入登录用户",
  "title3138": "真实姓名",
  "title3139": "确认密码",
  "title3140": "角色权限",
  "title3141": "创建角色",
  "title3142": "店铺权限",
  "title3143": "仓库权限",
  "title3144": "是否更新已经存在的用户数据",
  "title3145": "仅允许导入",
  "title3146": "格式文件。",
  "title3147": "未授权店铺",
  "title3148": "仓库授权",
  "title3149": "未授权仓库",
  "title3150": "新登录密码",
  "title3151": "新密码确认",
  "title3152": "姓名不能为空",
  "title3153": "登录密码不能为空",
  "title3154": "角色不能为空",
  "title3155": "请输入正确的手机号码",
  "title3156": "用户吗",
  "title3157": "修改用户",
  "title3158": "修改成功，新密码是：",
  "title3159": "是否确认扮演用户",
  "title3160": "是否确认修改登录后缀",
  "title3161": "分配角色成功",
  "title3162": "是否确认删除用户为：",
  "title3163": "是否确认导出所有用户数据项",
  "title3164": "用户数据",
  "title3165": "用户导入",
  "title3166": "用户导入模板",
  "title3167": "创建成功数量：",
  "title3168": "更新成功数量：",
  "title3169": "更新失败数量：",
  "title3170": "导入结果",
  "title3171": "仓库类型",
  "title3172": "自有",
  "title3173": "服务仓",
  "title3174": "三方仓仓库",
  "title3175": "服务商",
  "title3176": "仓库类型不能为空",
  "title3177": "服务商不能为空",
  "title3178": "三方仓仓库不能为空",
  "title3179": "修改仓库",
  "title3180": "是否确认删除仓库为：",
  "title3181": "是否确认导出所有仓库数据项",
  "title3182": "系统日志",
  "title3183": "用户编号",
  "title3184": "请输入用户编号",
  "title3185": "用户类型",
  "title3186": "请选择用户类型",
  "title3187": "应用名",
  "title3188": "请输入应用名",
  "title3189": "请求地址",
  "title3190": "请输入请求地址",
  "title3191": "请求时间",
  "title3192": "执行时长",
  "title3193": "请输入执行时长",
  "title3194": "结果码",
  "title3195": "请输入结果码",
  "title3196": "日志编号",
  "title3197": "请求方法名",
  "title3198": "操作结果",
  "title3199": "详细",
  "title3200": "访问日志详细",
  "title3201": "日志主键：",
  "title3202": "链路追踪：",
  "title3203": "应用名：",
  "title3204": "用户信息：",
  "title3205": "请求信息：",
  "title3206": "请求参数：",
  "title3207": "开始时间：",
  "title3208": "操作结果：",
  "title3209": "访问日志数据项",
  "title3210": "访问日志",
  "title3211": "生成类型",
  "title3212": "文件名",
  "title3213": "请输入文件名",
  "title3214": "生成类型不能为空",
  "title3215": "页面",
  "title3216": "弹窗",
  "title3217": "异常时间",
  "title3218": "处理状态",
  "title3219": "请选择处理状态",
  "title3220": "异常发生时间",
  "title3221": "异常名",
  "title3222": "已忽略",
  "title3223": "异常日志详细",
  "title3224": "异常时间：",
  "title3225": "处理时间",
  "title3226": "确认标记为",
  "title3227": "错误日志数据项",
  "title3228": "错误日志",
  "title3229": "没有与",
  "title3230": "匹配的",
  "title3231": "选择图标",
  "title3232": "请输入图标名称",
  "title3233": "文件",
  "title3234": "复制代码",
  "title3235": "选择生成类型",
  "title3236": "资源引用",
  "title3237": "格式不能识别，仅支持修改",
  "title3238": "的对象内容",
  "title3239": "请使用",
  "title3240": "错误：",
  "title3241": "导出文件",
  "title3242": "格式错误，请检查",
  "title3243": "外部资源引用",
  "title3244": "资源路径",
  "title3245": "添加其他",
  "title3246": "资源已存在",
  "title3247": "表名称",
  "title3248": "表描述",
  "title3249": "实体类名称",
  "title3250": "默认去除表名的前缀。如果存在重复，则需要手动添加前缀，避免",
  "title3251": "报",
  "title3252": "重复的问题。",
  "title3253": "作者",
  "title3254": "请输入表名称",
  "title3255": "请输入表描述",
  "title3256": "请输入实体类名称",
  "title3257": "请输入作者",
  "title3258": "字段信息",
  "title3259": "字段列名",
  "title3260": "字段描述",
  "title3261": "物理类型",
  "title3262": "插入",
  "title3263": "列表",
  "title3264": "查询",
  "title3265": "查询方式",
  "title3266": "允许空",
  "title3267": "显示类型",
  "title3268": "文本框",
  "title3269": "文本域",
  "title3270": "下拉框",
  "title3271": "单选框",
  "title3272": "复选框",
  "title3273": "日期控件",
  "title3274": "图片上传",
  "title3275": "文件上传",
  "title3276": "富文本控件",
  "title3277": "示例",
  "title3278": "生成信息",
  "title3279": "表单校验未通过，请重新检查提交内容",
  "title3280": "选项名",
  "title3281": "请输入选项名",
  "title3282": "选项值",
  "title3283": "请输入选项值",
  "title3284": "字符串",
  "title3285": "数字",
  "title3286": "生成模板",
  "title3287": "生成场景",
  "title3288": "模块名",
  "title3289": "模块名，即一级目录，例如",
  "title3290": "等等",
  "title3291": "业务名",
  "title3292": "业务名，即二级目录，例如",
  "title3293": "类名称",
  "title3294": "类名称（首字母大写），例如",
  "title3295": "类描述",
  "title3296": "用作类描述，例如",
  "title3297": "上级菜单",
  "title3298": "分配到指定菜单下，例如",
  "title3299": "系统管理",
  "title3300": "请选择系统菜单",
  "title3301": "自定义路径",
  "title3302": "填写磁盘绝对路径，若不填写，则生成到当前",
  "title3303": "项目下",
  "title3304": "最近路径快速选择",
  "title3305": "恢复默认的生成基础路径",
  "title3306": "其他信息",
  "title3307": "树编码字段",
  "title3308": "树显示的编码字段名，",
  "title3309": "树父编码字段",
  "title3310": "树显示的父编码字段名，",
  "title3311": "树名称字段",
  "title3312": "树节点的显示名称字段名，",
  "title3313": "关联信息",
  "title3314": "关联子表的表名",
  "title3315": "关联子表的表名，",
  "title3316": "子表关联的外键名",
  "title3317": "子表关联的外键名，",
  "title3318": "请选择生成模板",
  "title3319": "请选择生成场景",
  "title3320": "请输入生成模块名",
  "title3321": "请输入生成业务名",
  "title3322": "请输入生成业务包",
  "title3323": "请输入生成类名称",
  "title3324": "请输入生成类描述",
  "title3325": "暂时不考虑支持【树形】和【主子表】的代码生成。原因是：导致",
  "title3326": "模板过于复杂，不利于胖友二次开发",
  "title3327": "导入表",
  "title3328": "数据源",
  "title3329": "请选择数据源",
  "title3330": "导入成功",
  "title3331": "单元测试",
  "title3332": "实体",
  "title3333": "预览",
  "title3334": "生成代码",
  "title3335": "代码预览",
  "title3336": "确认要强制同步",
  "title3337": "表结构吗？",
  "title3338": "生成配置",
  "title3339": "是否确认删除表名称为",
  "title3340": "未知【",
  "title3341": "配置中心",
  "title3342": "参数名称",
  "title3343": "请输入参数名称",
  "title3344": "参数键名",
  "title3345": "请输入参数键名",
  "title3346": "系统内置",
  "title3347": "参数主键",
  "title3348": "参数分类",
  "title3349": "参数键值",
  "title3350": "是否可见",
  "title3351": "请输入参数分类",
  "title3352": "请输入参数键值",
  "title3353": "参数分类不能为空",
  "title3354": "参数名称不能为空",
  "title3355": "参数键名不能为空",
  "title3356": "参数键值不能为空",
  "title3357": "添加参数",
  "title3358": "修改参数",
  "title3359": "是否确认删除参数编号为",
  "title3360": "是否确认导出所有参数数据项",
  "title3361": "参数配置",
  "title3362": "组件属性",
  "title3363": "表单属性",
  "title3364": "查看组件文档",
  "title3365": "组件类型",
  "title3366": "请选择组件类型",
  "title3367": "字段名",
  "title3368": "请输入字段名（",
  "title3369": "组件名",
  "title3370": "标题",
  "title3371": "请输入标题",
  "title3372": "占位提示",
  "title3373": "请输入占位提示",
  "title3374": "开始占位",
  "title3375": "结束占位",
  "title3376": "表单栅格",
  "title3377": "栅格间隔",
  "title3378": "布局模式",
  "title3379": "水平排列",
  "title3380": "请选择水平排列",
  "title3381": "垂直排列",
  "title3382": "标签宽度",
  "title3383": "请输入标签宽度",
  "title3384": "组件宽度",
  "title3385": "请输入组件宽度",
  "title3386": "请输入默认值",
  "title3387": "至少应选",
  "title3388": "最多可选",
  "title3389": "请输入前缀",
  "title3390": "后缀",
  "title3391": "请输入后缀",
  "title3392": "前图标",
  "title3393": "请输入前图标名称",
  "title3394": "后图标",
  "title3395": "请输入后图标名称",
  "title3396": "按钮图标",
  "title3397": "请输入按钮图标名称",
  "title3398": "选项分隔符",
  "title3399": "请输入选项分隔符",
  "title3400": "最小行数",
  "title3401": "最大行数",
  "title3402": "最小值",
  "title3403": "最大值",
  "title3404": "组件高度",
  "title3405": "步长",
  "title3406": "步数",
  "title3407": "按钮位置",
  "title3408": "默认",
  "title3409": "右侧",
  "title3410": "最多输入",
  "title3411": "请输入字符长度",
  "title3412": "开启提示",
  "title3413": "请输入开启提示",
  "title3414": "关闭提示",
  "title3415": "请输入关闭提示",
  "title3416": "开启值",
  "title3417": "请输入开启值",
  "title3418": "关闭值",
  "title3419": "请输入关闭值",
  "title3420": "时间类型",
  "title3421": "请选择时间类型",
  "title3422": "文件字段名",
  "title3423": "请输入上传文件字段名",
  "title3424": "文件类型",
  "title3425": "请选择文件类型",
  "title3426": "主键编号",
  "title3427": "数据源名称",
  "title3428": "数据源连接",
  "title3429": "请输入数据源连接",
  "title3430": "数据源名称不能为空",
  "title3431": "数据源连接不能为空",
  "title3432": "添加数据源配置",
  "title3433": "修改数据源配置",
  "title3434": "是否确认删除数据源配置编号为",
  "title3435": "数据库文档",
  "title3436": "上传下载",
  "title3437": "文件路径",
  "title3438": "请输入文件路径",
  "title3439": "文件大小",
  "title3440": "文件内容",
  "title3441": "无法预览，点击",
  "title3442": "上传时间",
  "title3443": "提示：仅允许导入",
  "title3444": "是否确认删除文件编号为",
  "title3445": "配置名",
  "title3446": "请输入配置名",
  "title3447": "存储器",
  "title3448": "请选择存储器",
  "title3449": "主配置",
  "title3450": "基础路径",
  "title3451": "请输入基础路径",
  "title3452": "主机地址",
  "title3453": "请输入主机地址",
  "title3454": "主机端口",
  "title3455": "请输入主机端口",
  "title3456": "连接模式",
  "title3457": "主动模式",
  "title3458": "节点地址",
  "title3459": "请输入节点地址",
  "title3460": "存储",
  "title3461": "自定义域名",
  "title3462": "请输入自定义域名",
  "title3463": "配置名不能为空",
  "title3464": "存储器不能为空",
  "title3465": "基础路径不能为空",
  "title3466": "主机地址不能为空",
  "title3467": "主机端口不能为空",
  "title3468": "连接模式不能为空",
  "title3469": "节点地址不能为空",
  "title3470": "自定义域名不能为空",
  "title3471": "添加文件配置",
  "title3472": "修改文件配置",
  "title3473": "是否确认删除文件配置编号为",
  "title3474": "是否确认修改配置编号为",
  "title3475": "的数据项为主配置",
  "title3476": "测试通过，上传文件成功！访问地址：",
  "title3477": "数据库",
  "title3478": "多数据源（读写分离）",
  "title3479": "异步任务",
  "title3480": "消息队列",
  "title3481": "处理器的名字",
  "title3482": "请输入处理器的名字",
  "title3483": "开始执行时间",
  "title3484": "选择开始执行时间",
  "title3485": "结束执行时间",
  "title3486": "选择结束执行时间",
  "title3487": "任务状态",
  "title3488": "请选择任务状态",
  "title3489": "处理器的参数",
  "title3490": "第几次执行",
  "title3491": "执行时间",
  "title3492": "毫秒",
  "title3493": "调度日志详细",
  "title3494": "日志编号：",
  "title3495": "任务编号：",
  "title3496": "处理器的名字：",
  "title3497": "处理器的参数：",
  "title3498": "第几次执行：",
  "title3499": "执行时间：",
  "title3500": "执行时长：",
  "title3501": "任务状态：",
  "title3502": "执行结果：",
  "title3503": "是否确认导出所有定时任务日志数据项",
  "title3504": "定时任务日志",
  "title3505": "请输入任务名称",
  "title3506": "执行日志",
  "title3507": "表达式",
  "title3508": "暂停",
  "title3509": "更多",
  "title3510": "执行一次",
  "title3511": "任务详细",
  "title3512": "调度日志",
  "title3513": "请输入处理器的参数",
  "title3514": "生成表达式",
  "title3515": "重试次数",
  "title3516": "请输入重试次数。设置为",
  "title3517": "时，不进行重试",
  "title3518": "重试间隔",
  "title3519": "请输入重试间隔，单位：毫秒。设置为",
  "title3520": "时，无需间隔",
  "title3521": "监控超时时间",
  "title3522": "请输入监控超时时间，单位：毫秒",
  "title3523": "表达式生成器",
  "title3524": "任务名称：",
  "title3525": "表达式：",
  "title3526": "重试次数：",
  "title3527": "重试间隔：",
  "title3528": "监控超时时间：",
  "title3529": "后续执行时间：",
  "title3530": "任务名称不能为空",
  "title3531": "处理器的名字不能为空",
  "title3532": "表达式不能为空",
  "title3533": "重试次数不能为空",
  "title3534": "重试间隔不能为空",
  "title3535": "确认要立即执行一次",
  "title3536": "任务吗",
  "title3537": "执行成功",
  "title3538": "添加任务",
  "title3539": "修改任务",
  "title3540": "是否确认删除定时任务编号为",
  "title3541": "定时任务编号为",
  "title3542": "是否确认导出所有定时任务数据项",
  "title3543": "服务监控",
  "title3544": "接口文档",
  "title3545": "参数分组",
  "title3546": "是否敏感",
  "title3547": "请输入参数分组",
  "title3548": "参数分组不能为空",
  "title3549": "缓存",
  "title3550": "本地缓存",
  "title3551": "版本",
  "title3552": "运行模式",
  "title3553": "单机",
  "title3554": "集群",
  "title3555": "端口",
  "title3556": "客户端数",
  "title3557": "运行时间",
  "title3558": "使用内存",
  "title3559": "内存配置",
  "title3560": "是否开启",
  "title3561": "是否成功",
  "title3562": "网络入口",
  "title3563": "出口",
  "title3564": "命令统计",
  "title3565": "内存信息",
  "title3566": "超时时间",
  "title3567": "秒",
  "title3568": "键名列表",
  "title3569": "缓存键名",
  "title3570": "缓存内容",
  "title3571": "清理全部",
  "title3572": "命令",
  "title3573": "峰值",
  "title3574": "内存消耗",
  "title3575": "正在加载缓存监控数据，请稍后！",
  "title3576": "刷新键名列表成功",
  "title3577": "清理缓存键名",
  "title3578": "名字",
  "title3579": "请输入名字",
  "title3580": "名字不能为空",
  "title3581": "分类不能为空",
  "title3582": "添加字典类型",
  "title3583": "修改字典类型",
  "title3584": "是否确认删除字典类型编号为",
  "title3585": "是否确认导出所有字典类型数据项",
  "title3586": "第三方服务类型",
  "title3587": "请选择第三方服务类型",
  "title3588": "配置编码",
  "title3589": "请输入配置编码",
  "title3590": "请选择开启状态",
  "title3591": "配置内容",
  "title3592": "第三方服务类型不能为空",
  "title3593": "配置编码不能为空",
  "title3594": "配置内容不能为空",
  "title3595": "添加第三方服务配置",
  "title3596": "修改第三方服务配置",
  "title3597": "是否确认删除第三方服务配置编号为",
  "title3598": "是否确认导出所有第三方服务配置数据项",
  "title3599": "优惠券类",
  "title3600": "请选择优惠券类型",
  "title3601": "满减",
  "title3602": "折扣",
  "title3603": "随机",
  "title3604": "优惠券名称",
  "title3605": "请输入优惠券名称",
  "title3606": "请选择状态（1进行中2已结束-1已关闭）",
  "title3607": "有效日期结束时间",
  "title3608": "优惠券类型",
  "title3609": "优惠券图片",
  "title3610": "发放数量",
  "title3611": "已领取数量",
  "title3612": "已使用数量",
  "title3613": "使用门槛",
  "title3614": "优惠金额",
  "title3615": "有效期限",
  "title3616": "领取上限",
  "title3617": "优惠叠加",
  "title3618": "是否显示",
  "title3619": "订单的优惠总金额",
  "title3620": "用券总成交额",
  "title3621": "是否禁止发放",
  "title3622": "使用优惠券购买的商品数量",
  "title3623": "名称备注",
  "title3624": "请输入名称备注",
  "title3625": "请输入发放数量",
  "title3626": "适用商品类型",
  "title3627": "适用商品",
  "title3628": "请输入适用商品",
  "title3629": "满多少元使用",
  "title3630": "请输入满多少元使用",
  "title3631": "代表无限制",
  "title3632": "发放面额",
  "title3633": "请输入发放面额",
  "title3634": "时需要添加",
  "title3635": "请输入1",
  "title3636": "最多折扣金额",
  "title3637": "请输入最多折扣金额",
  "title3638": "时可选择性添加",
  "title3639": "最低金额",
  "title3640": "请输入最低金额",
  "title3641": "最大金额",
  "title3642": "请输入最大金额",
  "title3643": "过期类型",
  "title3644": "使用开始日期",
  "title3645": "过期类型1时必填",
  "title3646": "选择使用开始日期",
  "title3647": "使用结束日期",
  "title3648": "选择使用结束日期",
  "title3649": "为2或者3时需要添加",
  "title3650": "领取之日起或者次日",
  "title3651": "天内有效",
  "title3652": "请输入当",
  "title3653": "是否无限制0-否",
  "title3654": "每人最大领取个数",
  "title3655": "请输入每人最大领取个数",
  "title3656": "是否开启过期提醒0-不开启",
  "title3657": "过期前",
  "title3658": "天提醒",
  "title3659": "请输入过期前",
  "title3660": "不限制",
  "title3661": "优惠券仅原价购买商品时可用",
  "title3662": "请输入是否显示",
  "title3663": "请输入订单的优惠总金额",
  "title3664": "请输入用券总成交额",
  "title3665": "是否禁止发放0-否",
  "title3666": "请输入使用优惠券购买的商品数量",
  "title3667": "选择有效日期结束时间",
  "title3668": "随机不能为空",
  "title3669": "优惠券名称不能为空",
  "title3670": "发放数量不能为空",
  "title3671": "已领取数量不能为空",
  "title3672": "已使用数量不能为空",
  "title3673": "适用商品类型1-全部商品可用；2-指定商品可用；3-指定商品不可用不能为空",
  "title3674": "使用门槛0-无门槛",
  "title3675": "有门槛不能为空",
  "title3676": "代表无限制不能为空",
  "title3677": "时需要添加不能为空",
  "title3678": "时可选择性添加不能为空",
  "title3679": "过期类型1-时间范围过期",
  "title3680": "领取之日固定日期后过期",
  "title3681": "领取次日固定日期后过期不能为空",
  "title3682": "天内有效不能为空",
  "title3683": "是不能为空",
  "title3684": "每人最大领取个数不能为空",
  "title3685": "开启不能为空",
  "title3686": "天提醒不能为空",
  "title3687": "优惠券仅原价购买商品时可用不能为空",
  "title3688": "是否显示不能为空",
  "title3689": "订单的优惠总金额不能为空",
  "title3690": "用券总成交额不能为空",
  "title3691": "使用优惠券购买的商品数量不能为空",
  "title3692": "状态（1进行中2已结束-1已关闭）不能为空",
  "title3693": "添加优惠券模板",
  "title3694": "修改优惠券模板",
  "title3695": "是否确认删除优惠券模板编号为",
  "title3696": "是否确认导出所有优惠券模板数据项",
  "title3697": "优惠券模板",
  "title3698": "身份证号",
  "title3699": "请输入身份证号",
  "title3700": "审核状态",
  "title3701": "请选择审核状态",
  "title3702": "审核时间",
  "title3703": "选择审核时间",
  "title3704": "关联的用户编号不能为空",
  "title3705": "身份证号不能为空",
  "title3706": "审核状态不能为空",
  "title3707": "审核时间不能为空",
  "title3708": "添加会员实名认证",
  "title3709": "修改会员实名认证",
  "title3710": "是否确认删除会员实名认证编号为",
  "title3711": "是否确认导出所有会员实名认证数据项",
  "title3712": "请输入推荐人编号",
  "title3713": "邀请人手机号",
  "title3714": "请输入邀请人手机号",
  "title3715": "会员编号",
  "title3716": "邀请人编号",
  "title3717": "关系路径",
  "title3718": "请输入邀请人编号",
  "title3719": "请输入关系路径",
  "title3720": "邀请人编号不能为空",
  "title3721": "关系路径不能为空",
  "title3722": "添加用户邀请关系",
  "title3723": "修改用户邀请关系",
  "title3724": "是否确认删除用户邀请关系编号为",
  "title3725": "是否确认导出所有用户邀请关系数据项",
  "title3726": "会员手机号",
  "title3727": "请输入会员手机号",
  "title3728": "收货号码",
  "title3729": "请输入收货号码",
  "title3730": "请选择是否默认",
  "title3731": "市",
  "title3732": "地址",
  "title3733": "请输入市",
  "title3734": "请输入地址",
  "title3735": "用户编号不能为空",
  "title3736": "地址不能为空",
  "title3737": "添加收货地址",
  "title3738": "修改收货地址",
  "title3739": "是否确认删除收货地址编号为",
  "title3740": "是否确认导出所有收货地址数据项",
  "title3741": "用户昵称",
  "title3742": "请输入用户昵称",
  "title3743": "用户状态",
  "title3744": "是否实名",
  "title3745": "请输入注册",
  "title3746": "最后登录",
  "title3747": "最后登录时间",
  "title3748": "重置短信",
  "title3749": "重置实名",
  "title3750": "重置锁单",
  "title3751": "重置成功",
  "title3752": "添加会员",
  "title3753": "修改会员",
  "title3754": "是否确认导出所有会员数据项",
  "title3755": "商户名称",
  "title3756": "请输入商户名称",
  "title3757": "应用编号",
  "title3758": "支付宝配置",
  "title3759": "微信配置",
  "title3760": "所属商户",
  "title3761": "请选择所属商户",
  "title3762": "支付结果的回调地址",
  "title3763": "请输入支付结果的回调地址",
  "title3764": "退款结果的回调地址",
  "title3765": "请输入退款结果的回调地址",
  "title3766": "应用名不能为空",
  "title3767": "支付结果的回调地址不能为空",
  "title3768": "退款结果的回调地址不能为空",
  "title3769": "商户编号不能为空",
  "title3770": "添加支付应用信息",
  "title3771": "修改支付应用信息",
  "title3772": "应用吗",
  "title3773": "是否确认删除支付应用信息编号为",
  "title3774": "已取消删除",
  "title3775": "是否确认导出所有支付应用信息数据项",
  "title3776": "支付应用信息",
  "title3777": "商户号",
  "title3778": "请输入商户号",
  "title3779": "商户全称",
  "title3780": "请输入商户全称",
  "title3781": "商户简称",
  "title3782": "请输入商户简称",
  "title3783": "商户编号",
  "title3784": "商户号不能为空",
  "title3785": "商户全称不能为空",
  "title3786": "商户简称不能为空",
  "title3787": "添加支付商户信息",
  "title3788": "修改支付商户信息",
  "title3789": "商户吗",
  "title3790": "是否确认删除支付商户信息编号为",
  "title3791": "是否确认导出所有支付商户信息数据项",
  "title3792": "支付商户信息",
  "title3793": "请选择应用信息",
  "title3794": "渠道编码",
  "title3795": "请输入渠道编码",
  "title3796": "商户订单编号",
  "title3797": "请输入商户订单编号",
  "title3798": "渠道订单号",
  "title3799": "请输入渠道订单号",
  "title3800": "请选择支付状态",
  "title3801": "退款状态",
  "title3802": "请选择退款状态",
  "title3803": "回调商户状态",
  "title3804": "请选择订单回调商户状态",
  "title3805": "支付渠道",
  "title3806": "应用名称",
  "title3807": "渠道名称",
  "title3808": "支付订单",
  "title3809": "商户",
  "title3810": "支付",
  "title3811": "商品标题",
  "title3812": "手续金额",
  "title3813": "回调状态",
  "title3814": "商户订单号",
  "title3815": "支付订单号",
  "title3816": "手续费",
  "title3817": "手续费比例",
  "title3818": "回调地址",
  "title3819": "失效时间",
  "title3820": "通知时间",
  "title3821": "退款次数",
  "title3822": "支付通道异步回调内容",
  "title3823": "时间范围最大为",
  "title3824": "天！",
  "title3825": "是否确认导出所有支付订单数据项",
  "title3826": "退款类型",
  "title3827": "请选择退款类型",
  "title3828": "商户退款订单号",
  "title3829": "请输入商户退款订单号",
  "title3830": "退款回调状态",
  "title3831": "请选择通知商户退款结果的回调状态",
  "title3832": "退款",
  "title3833": "交易",
  "title3834": "渠道",
  "title3835": "元",
  "title3836": "退款原因",
  "title3837": "退款成功时间",
  "title3838": "退款订单详情",
  "title3839": "商户退款单号",
  "title3840": "交易订单号",
  "title3841": "退款失效时间",
  "title3842": "回调时间",
  "title3843": "渠道退款单号",
  "title3844": "渠道错误码",
  "title3845": "渠道错误码描述",
  "title3846": "渠道额外参数",
  "title3847": "是否确认导出所有退款订单量据项",
  "title3848": "字典名称",
  "title3849": "字典标签",
  "title3850": "请输入字典标签",
  "title3851": "数据状态",
  "title3852": "字典编码",
  "title3853": "字典键值",
  "title3854": "字典排序",
  "title3855": "颜色类型",
  "title3856": "数据标签",
  "title3857": "请输入数据标签",
  "title3858": "数据键值",
  "title3859": "请输入数据键值",
  "title3860": "显示排序",
  "title3861": "数据标签不能为空",
  "title3862": "数据键值不能为空",
  "title3863": "数据顺序不能为空",
  "title3864": "主要",
  "title3865": "危险",
  "title3866": "添加字典数据",
  "title3867": "修改字典数据",
  "title3868": "是否确认删除字典编码为",
  "title3869": "字典数据",
  "title3870": "请输入字典名称",
  "title3871": "请输入字典类型",
  "title3872": "字典状态",
  "title3873": "字典编号",
  "title3874": "字典名称不能为空",
  "title3875": "字典类型不能为空",
  "title3876": "是否确认删除字典编号为",
  "title3877": "部门名称",
  "title3878": "请输入部门名称",
  "title3879": "菜单状态",
  "title3880": "负责人",
  "title3881": "上级部门",
  "title3882": "选择上级部门",
  "title3883": "请输入负责人",
  "title3884": "部门状态",
  "title3885": "部门名称不能为空",
  "title3886": "显示排序不能为空",
  "title3887": "请输入正确的邮箱地址",
  "title3888": "未设置",
  "title3889": "添加部门",
  "title3890": "修改部门",
  "title3891": "异常处理（错误码）",
  "title3892": "错误码类型",
  "title3893": "请选择错误码类型",
  "title3894": "错误码编码",
  "title3895": "请输入错误码编码",
  "title3896": "错误码提示",
  "title3897": "请输入错误码提示",
  "title3898": "错误码编码不能为空",
  "title3899": "错误码提示不能为空",
  "title3900": "添加错误码",
  "title3901": "修改错误码",
  "title3902": "是否确认删除错误码编号为",
  "title3903": "是否确认导出所有错误码数据项",
  "title3904": "错误码",
  "title3905": "文章类型",
  "title3906": "请选择文章类型",
  "title3907": "文章标题",
  "title3908": "请输入文章标题",
  "title3909": "文章内容",
  "title3910": "文章类型不能为空",
  "title3911": "文章标题不能为空",
  "title3912": "文章内容不能为空",
  "title3913": "添加文章",
  "title3914": "修改文章",
  "title3915": "是否确认删除文章编号为",
  "title3916": "是否确认导出所有文章数据项",
  "title3917": "请输入货币符号",
  "title3918": "货币名称",
  "title3919": "当前页面提供了“历史汇率”修改功能，历史汇率修改将会影响到修改日期内所有“未回款”订单，并对财务报表和利润计算产生影响。如您需要修改“已回款”订单的汇率，请前往利润分析-",
  "title3920": "利润月报",
  "title3921": "页面进行修改。",
  "title3922": "货币符号",
  "title3923": "官方汇率",
  "title3924": "汇率取自官方汇率，每天0",
  "title3925": "更新汇率",
  "title3926": "执行汇率类型",
  "title3927": "固定汇率：用户自定义的固定汇率。",
  "title3928": "汇率折扣：当天官方汇率乘以折扣比例计算的汇率。",
  "title3929": "例如，美元",
  "title3930": "汇率为7",
  "title3931": "并且您设置的汇率折扣比例为98",
  "title3932": "那么实际执行汇率",
  "title3933": "汇率折扣比例：",
  "title3934": "固定汇率：",
  "title3935": "执行汇率",
  "title3936": "历史汇率",
  "title3937": "日志",
  "title3938": "添加模版",
  "title3939": "请输入模板名称",
  "title3940": "模版名称",
  "title3941": "最少选择一个模板",
  "title3942": "是否删除选中模板",
  "title3943": "是否删除",
  "title3944": "请输入登录地址",
  "title3945": "请输入用户名称",
  "title3946": "访问编号",
  "title3947": "日志类型",
  "title3948": "登录日期",
  "title3949": "是否确认导出所有操作日志数据项",
  "title3950": "菜单路由",
  "title3951": "请输入菜单名称",
  "title3952": "菜单名称",
  "title3953": "分组名称",
  "title3954": "图标",
  "title3955": "权限标识",
  "title3956": "组件路径",
  "title3957": "选择上级菜单",
  "title3958": "菜单类型",
  "title3959": "菜单图标",
  "title3960": "点击选择图标",
  "title3961": "请输入分组名称",
  "title3962": "路由地址",
  "title3963": "访问的路由地址，如：",
  "title3964": "如需外网地址时，则以",
  "title3965": "请输入路由地址",
  "title3966": "请输入组件路径",
  "title3967": "方法上的权限字符，如：",
  "title3968": "权限字符",
  "title3969": "请权限标识",
  "title3970": "选择停用时，路由将不会出现在侧边栏，也不能被访问",
  "title3971": "显示状态",
  "title3972": "选择隐藏时，路由将不会出现在侧边栏，但仍然可以访问",
  "title3973": "显示",
  "title3974": "隐藏",
  "title3975": "选择缓存时，则会被",
  "title3976": "缓存，需要匹配组件的",
  "title3977": "和路由地址保持一致",
  "title3978": "是否缓存",
  "title3979": "不缓存",
  "title3980": "菜单名称不能为空",
  "title3981": "菜单顺序不能为空",
  "title3982": "路由地址不能为空",
  "title3983": "主类目",
  "title3984": "添加菜单",
  "title3985": "修改菜单",
  "title3986": "前端必须以",
  "title3987": "前端不能以",
  "title3988": "公告标题",
  "title3989": "请输入公告标题",
  "title3990": "操作人员",
  "title3991": "请输入操作人员",
  "title3992": "公告类型",
  "title3993": "内容类型",
  "title3994": "创建者",
  "title3995": "内容",
  "title3996": "公告标题不能为空",
  "title3997": "公告类型不能为空",
  "title3998": "添加公告",
  "title3999": "修改公告",
  "title4000": "是否确认删除公告编号为",
  "title4001": "系统公告",
  "title4002": "平台公告",
  "title4003": "东南亚资讯",
  "title4004": "全部已读",
  "title4005": "全部标记已读成功",
  "title4006": "发布时间：",
  "title4007": "确认支付",
  "title4008": "确认该订单完成支付？",
  "title4009": "岗位编码",
  "title4010": "请输入岗位编码",
  "title4011": "岗位名称",
  "title4012": "请输入岗位名称",
  "title4013": "岗位状态",
  "title4014": "岗位编号",
  "title4015": "岗位排序",
  "title4016": "请输入编码名称",
  "title4017": "岗位顺序",
  "title4018": "岗位名称不能为空",
  "title4019": "岗位编码不能为空",
  "title4020": "岗位顺序不能为空",
  "title4021": "添加岗位",
  "title4022": "修改岗位",
  "title4023": "是否确认删除岗位编号为",
  "title4024": "是否确认导出所有岗位数据项",
  "title4025": "岗位数据",
  "title4026": "操作模块",
  "title4027": "请输入操作模块",
  "title4028": "操作名",
  "title4029": "请输入操作名",
  "title4030": "操作类型",
  "title4031": "操作状态",
  "title4032": "操作人",
  "title4033": "操作日期",
  "title4034": "操作信息：",
  "title4035": "方法名：",
  "title4036": "方法参数：",
  "title4037": "操作日志",
  "title4038": "请输入地区父",
  "title4039": "地区名称",
  "title4040": "请输入地区名称",
  "title4041": "地区类型",
  "title4042": "请选择地区类型",
  "title4043": "地区父",
  "title4044": "地区名称不能为空",
  "title4045": "地区类型不能为空",
  "title4046": "添加地区",
  "title4047": "修改地区",
  "title4048": "是否确认删除地区编号为",
  "title4049": "是否确认导出所有地区数据项",
  "title4050": "角色编号",
  "title4051": "角色类型",
  "title4052": "修改角色",
  "title4053": "由于",
  "title4054": "平台佣金在订单完成时才结算，此处需要设置一个预估的佣金比例，用于订单未回款时利润的估算",
  "title4055": "佣金计算公式：（商品金额-平台优惠-卖家优惠）",
  "title4056": "店铺佣金百分比",
  "title4057": "注意：每次修改佣金比例后，当未回款订单有同步时会重新计算佣金；默认佣金比例为3",
  "title4058": "平台佣金比例",
  "title4059": "敏感词",
  "title4060": "请输入敏感词",
  "title4061": "请选择标签",
  "title4062": "请选择启用状态",
  "title4063": "请选择文章标签",
  "title4064": "检测敏感词",
  "title4065": "文本",
  "title4066": "请输入测试文本",
  "title4067": "检",
  "title4068": "测",
  "title4069": "敏感词不能为空",
  "title4070": "标签不能为空",
  "title4071": "测试文本不能为空",
  "title4072": "添加敏感词",
  "title4073": "检测文本是否含有敏感词",
  "title4074": "修改敏感词",
  "title4075": "不包含敏感词！",
  "title4076": "包含敏感词：",
  "title4077": "是否确认删除敏感词编号为",
  "title4078": "是否确认导出所有敏感词数据项",
  "title4079": "短信配置",
  "title4080": "短信签名",
  "title4081": "请输入短信签名",
  "title4082": "启用状态",
  "title4083": "短信",
  "title4084": "的账号",
  "title4085": "的密钥",
  "title4086": "短信发送回调",
  "title4087": "请选择渠道编码",
  "title4088": "请输入短信",
  "title4089": "请输入短信发送回调",
  "title4090": "短信签名不能为空",
  "title4091": "渠道编码不能为空",
  "title4092": "启用状态不能为空",
  "title4093": "的账号不能为空",
  "title4094": "添加短信渠道",
  "title4095": "修改短信渠道",
  "title4096": "是否确认删除短信渠道编号为",
  "title4097": "短信渠道",
  "title4098": "请选择短信渠道",
  "title4099": "模板编号",
  "title4100": "请输入模板编号",
  "title4101": "发送状态",
  "title4102": "请选择发送状态",
  "title4103": "发送时间",
  "title4104": "接收状态",
  "title4105": "请选择接收状态",
  "title4106": "接收时间",
  "title4107": "短信内容",
  "title4108": "短信类型",
  "title4109": "短信日志详细",
  "title4110": "短信渠道：",
  "title4111": "短信模板：",
  "title4112": "的模板编号：",
  "title4113": "短信内容：",
  "title4114": "短信参数：",
  "title4115": "发送状态：",
  "title4116": "发送时间：",
  "title4117": "发送结果：",
  "title4118": "短信编号：",
  "title4119": "请求编号：",
  "title4120": "接收状态：",
  "title4121": "接收时间：",
  "title4122": "接收结果：",
  "title4123": "是否确认导出所有短信日志数据项",
  "title4124": "短信日志",
  "title4125": "找不到签名：",
  "title4126": "请输入标签内容",
  "title4127": "标签内容",
  "title4128": "颜色",
  "title4129": "标签排序",
  "title4130": "请输入标签排序",
  "title4131": "请输入颜色",
  "title4132": "标签排序不能为空",
  "title4133": "标签内容不能为空",
  "title4134": "添加标签",
  "title4135": "修改标签",
  "title4136": "是否确认删除标签",
  "title4137": "是否确认导出所有标签数据项",
  "title4138": "多租户",
  "title4139": "客户名",
  "title4140": "请输入客户名",
  "title4141": "联系手机",
  "title4142": "请输入联系手机",
  "title4143": "客户状态",
  "title4144": "请选择客户状态",
  "title4145": "客户编号",
  "title4146": "客户套餐",
  "title4147": "系统租户",
  "title4148": "子账号数",
  "title4149": "过期时间",
  "title4150": "绑定域名",
  "title4151": "请选择客户套餐",
  "title4152": "用户密码",
  "title4153": "请输入用户密码",
  "title4154": "默认入口",
  "title4155": "请选择默认入口",
  "title4156": "请选择过期时间",
  "title4157": "请输入绑定域名",
  "title4158": "客户名不能为空",
  "title4159": "客户套餐不能为空",
  "title4160": "联系人不能为空",
  "title4161": "客户状态不能为空",
  "title4162": "过期时间不能为空",
  "title4163": "绑定域名不能为空",
  "title4164": "添加客户",
  "title4165": "修改客户",
  "title4166": "是否确认删除客户编号为",
  "title4167": "是否确认导出所有客户数据项",
  "title4168": "未知套餐",
  "title4169": "套餐名",
  "title4170": "请输入套餐名",
  "title4171": "套餐编号",
  "title4172": "受限页面",
  "title4173": "勾选的菜单将会被限制，在这些页面将会有弹框弹出显示",
  "title4174": "套餐名不能为空",
  "title4175": "关联的菜单编号不能为空",
  "title4176": "添加租户套餐",
  "title4177": "修改租户套餐",
  "title4178": "是否确认删除租户套餐编号为",
  "title4179": "请选择短信类型",
  "title4180": "模板编码",
  "title4181": "请输入模板编码",
  "title4182": "的模板编号",
  "title4183": "模板内容",
  "title4184": "短信渠道编号",
  "title4185": "请选择短信渠道编号",
  "title4186": "请输入模板内容",
  "title4187": "测试发送短信",
  "title4188": "短信类型不能为空",
  "title4189": "模板编码不能为空",
  "title4190": "模板名称不能为空",
  "title4191": "模板内容不能为空",
  "title4192": "的模板编号不能为空",
  "title4193": "短信渠道编号不能为空",
  "title4194": "手机不能为空",
  "title4195": "添加短信模板",
  "title4196": "修改短信模板",
  "title4197": "是否确认删除短信模板编号为",
  "title4198": "是否确认导出所有短信模板数据项",
  "title4199": "短信模板",
  "title4200": "提交发送成功！发送结果，见发送日志编号：",
  "title4201": "用户体系",
  "title4202": "三方登陆",
  "title4203": "部门",
  "title4204": "归属部门",
  "title4205": "请选择归属部门",
  "title4206": "用户性别",
  "title4207": "岗位",
  "title4208": "用户名称不能为空",
  "title4209": "用户昵称不能为空",
  "title4210": "用户密码不能为空",
  "title4211": "的新密码",
  "title4212": "报表设计器",
  "title4213": "欢迎使用",
  "title4214": "自由对话",
  "title4215": "基于先进的",
  "title4216": "智能模型",
  "title4217": "让你的交流更加智能、高效、便捷！",
  "title4218": "如何更有效的提问？",
  "title4219": "可以试试这样提问：",
  "title4220": "收藏",
  "title4221": "服务生成的所有内容均由人工智能模型生成，其生成内容的准确性和完整性无法保证，不代表我们的态度或观点",
  "title4222": "设定角色",
  "title4223": "赋予专业身份",
  "title4224": "如：你是一位资深的营销大师",
  "title4225": "描述提问",
  "title4226": "输入你的问题，并把背景信息、具体要求、任务目标等详细描述；",
  "title4227": "输出格式",
  "title4228": "告诉",
  "title4229": "需要以什么样的格式，并输出内容。",
  "title4230": "创建智能知识库",
  "title4231": "编辑智能知识库",
  "title4232": "智能知识库名称",
  "title4233": "请输入知识库名称",
  "title4234": "选择平台",
  "title4235": "知识库介绍",
  "title4236": "请输入简介",
  "title4237": "创建模板",
  "title4238": "保存模板",
  "title4239": "已使用",
  "title4240": "文档名称",
  "title4241": "大小",
  "title4242": "请选择文件",
  "title4243": "上传文件大小不能超过",
  "title4244": "请输入知识库介绍",
  "title4245": "汇率列表",
  "title4246": "汇率走势图",
  "title4247": "月均官方汇率",
  "title4248": "月均执行汇率",
  "title4249": "生成即完成",
  "title4250": "在左侧“输入内容”页面输入你的文案诉求，即刻生成专属优质文案",
  "title4251": "更新利润",
  "title4252": "回款汇率设置",
  "title4253": "新增自定义费用",
  "title4254": "导出报表",
  "title4255": "回款对账单",
  "title4256": "仅支持单店铺选择时切换币种，不支持多店铺切换",
  "title4257": "回款订单量",
  "title4258": "订单回款总金额",
  "title4259": "商品支出",
  "title4260": "测评付款金额-测评回款金额",
  "title4261": "自定义费用",
  "title4262": "更多详情",
  "title4263": "订单收入汇总表",
  "title4264": "平台对账单",
  "title4265": "费用",
  "title4266": "买家支付金额",
  "title4267": "总费用",
  "title4269": "卖家优惠",
  "title4270": "有效订单回款金额",
  "title4271": "跨月调整金额",
  "title4272": "搁置订单量",
  "title4273": "测评订单量",
  "title4274": "搁置订单回款金额",
  "title4275": "测评订单回款金额",
  "title4276": "测评回款金额",
  "title4277": "回款金额-商品支出-自定义费用-测评损耗",
  "title4278": "平台收入",
  "title4279": "平台支出",
  "title4280": "佣金、服务费、交易手续费",
  "title4281": "店铺内其它费用",
  "title4282": "测评费用",
  "title4283": "测评订单量",
  "title4284": "项目",
  "title4285": "自定义收支",
  "title4286": "成本支出明细",
  "title4287": "支出占总支出比例",
  "title4288": "支出占有效销售额比例",
  "title4289": "推广费用支出明细",
  "title4290": "其他收支",
  "title4291": "线上商品成本",
  "title4292": "退款金额",
  "title4293": "通过筛选条件选择需要导出的数据，导出报表时会将已选数据全部导出。",
  "title4294": "总数据",
  "title4295": "在所选的期间内，访问过您商店和商品详情页的不重复访客总数",
  "title4296": "已确定订单的总数",
  "title4297": "已确定订单的总金额",
  "title4298": "测评销量",
  "title4299": "测评金额",
  "title4300": "每个订单的销售额",
  "title4301": "店铺转化率",
  "title4302": "排除测评+搁置+取消+未付款的销售额",
  "title4303": "搜索广告费",
  "title4304": "搜索广告转化",
  "title4305": "搜索广告销售额",
  "title4306": "搜索广告点击率",
  "title4307": "搜索广告转化率",
  "title4308": "搜索广告",
  "title4309": "关联广告费",
  "title4310": "关联广告转化",
  "title4311": "关联广告销售额",
  "title4312": "关联广告点击率",
  "title4313": "关联广告转化率",
  "title4314": "推广广告费",
  "title4315": "推广广告转化",
  "title4316": "推广广告销售额",
  "title4317": "推广广告点击率",
  "title4318": "推广广告转化率",
  "title4319": "推广广告",
  "title4320": "预估运费",
  "title4321": "产出",
  "title4322": "有效销售额-搜索广告费-关联广告费-推广广告费-商品成本-测评损耗-预估运费-平台费用-退款金额",
  "title4324": "件数",
  "title4325": "已确定订单的商品总数",
  "title4326": "销售",
  "title4327": "实际订单量",
  "title4328": "总订单量-测评订单量",
  "title4329": "平均客单价",
  "title4330": "已确定订单买家数",
  "title4331": "修改密码",
  "title4332": "旧密码",
  "title4333": "输入旧密码",
  "title4334": "更改手机号需要先验证原手机号，短信验证码将会发送到你的",
  "title4335": "手机号上",
  "title4336": "结束时间：",
  "title4337": "请假类型：",
  "title4338": "原因：",
  "title4339": "参数，无法查看",
  "title4340": "请假信息",
  "title4341": "请假类型",
  "title4342": "请选择请假类型",
  "title4343": "申请时间",
  "title4344": "发起请假",
  "title4345": "申请编号",
  "title4346": "开始时间",
  "title4347": "取消请假",
  "title4348": "审批进度",
  "title4349": "选择开始时间",
  "title4350": "选择结束时间",
  "title4351": "提",
  "title4352": "交",
  "title4353": "开始时间不能为空",
  "title4354": "结束时间不能为空",
  "title4355": "请假类型不能为空",
  "title4356": "请假原因不能为空",
  "title4357": "发起成功",
  "title4358": "广告点击数",
  "title4359": "Shopee店铺支持通过授权广告权限获取店铺级别和商品链接数据（授权后自动获取近30天数据）",
  "title43591": "当前页面展示您Lazada店铺的广告数据，通过授权店铺后自动获取广告数据。",
  "title4360": "的广告数据解析，您可以通过插件轻松获取相关数据。具体操作方法请参阅帮助手册！",
  "title4361": "广告点击数",
  "title4362": "按趋势",
  "title4363": "按数值",
  "title4364": "自然点击数",
  "title4365": "广告浏览数",
  "title4366": "广告销售额占比",
  "title4367": "广告转化占比",
  "title4368": "最多选择二个指标",
  "title4369": "编辑商品成本",
  "title4370": "新增成本",
  "title4371": "新增历史成本",
  "title4372": "商品成本修改是更新当前商品的成本，不会影响历史订单，对未来订单将使用最新成本计算。",
  "title4373": "成本金额",
  "title4374": "修改历史指定日期的商品成本后，系统将自动更新该日期内所有订单中的商品成本为新的值，并重新计算相关报表数据。",
  "title4375": "成本设置说明",
  "title4376": "单个商品的成本建议计算方式如下",
  "title4377": "单个商品成本",
  "title4378": "历史结余商品数",
  "title4379": "历史单件采购成本",
  "title4380": "对应日期内多批次的采购总成本",
  "title4381": "多批次采购商品总数",
  "title4382": "举例说明：",
  "title4383": "月底销售结余了",
  "title4384": "件商品，采购单价为",
  "title4385": "元。",
  "title4386": "月份补货了",
  "title4387": "那么6月份的采购单价计算如下：",
  "title4388": "采购单价",
  "title4389": "如果6月销售后还剩下10件商品，且采购单价为17",
  "title4390": "元，对于7月份的采购单价，如果未使用采购功能，则会引用最后一次采购单价，即为17",
  "title4391": "同仓库有日期重复，请重新选择",
  "title4392": "编辑自定义费用",
  "title4393": "添加自定义费用",
  "title4394": "请选择至少一条数据",
  "title4395": "修改类型",
  "title4396": "修改内容",
  "title4397": "至",
  "title4398": "商品成本：",
  "title4399": "操作账号",
  "title4400": "商品成本修改记录",
  "title4401": "默认成本金额",
  "title4402": "修改成本金额",
  "title4403": "历史成本修改记录",
  "title4404": "成本日期区间",
  "title4405": "历史成本金额",
  "title4406": "上传",
  "title4407": "请按照数据模板的格式准备导入数据",
  "title4408": "模板中的表头名称不可更改",
  "title4409": "表头行不能删除",
  "title4410": "请选择下载要更新的商品数据",
  "title4411": "下载商品数据",
  "title4412": "请按照数据模板的格式编辑数据，模板中的表头名称不可更改，表头行不能删除",
  "title4413": "重新",
  "title4414": "历史成本导入模板",
  "title4415": "选择分组",
  "title4416": "开始采集",
  "title4417": "链接采集仅支持：",
  "title4418": "确认清空操作后，选择的商品将被彻底清空。并且无法恢复，是否确定执行清空操作？",
  "title4419": "退款金额",
  "title4420": "扣除广告费后的利润",
  "title4421": "广告销售量",
  "title4422": "广告转化率",
  "title4423": "图片分类",
  "title4424": "使用情况",
  "title4425": "图片空间基础版",
  "title4426": "图片已存储",
  "title4427": "张",
  "title4428": "增加容量",
  "title4429": "容量实用",
  "title4430": "已用",
  "title4431": "总共",
  "title4432": "已关联",
  "title4433": "商品分析",
  "title4434": "有效订单所对应的商品总成本",
  "title4435": "退款订单量",
  "title4436": "总广告费",
  "title4437": "有效销量排行榜",
  "title4438": "销量趋势",
  "title4439": "活跃度修改",
  "title4440": "销量区间从",
  "title4441": "个到",
  "title4442": "定义为",
  "title4443": "且库存数量在",
  "title4444": "请设置销售区间范围",
  "title4445": "库存请从小到大输入",
  "title4446": "范围请从小到大输入",
  "title4447": "添加线上图片（",
  "title4448": "请输入在线图片地址，一行一个地址",
  "title4449": "竞品信息",
  "title4450": "竞品链接",
  "title4451": "验证地址",
  "title4452": "竞品标题",
  "title4453": "商品备注",
  "title4454": "验证失败",
  "title4455": "货架位导入模板",
  "title4456": "选择本地图片",
  "title4457": "选择线上图片",
  "title4458": "暂无图片",
  "title4459": "请选择本地图片文件或选择在线图片添加，最多可上传8张图片",
  "title4460": "图片格式：",
  "title4461": "图片大小不能超过",
  "title4462": "图片尺寸不得小于",
  "title4463": "仓库信息",
  "title4464": "库存数量",
  "title4465": "补货频率",
  "title4466": "价格信息",
  "title4467": "采购价",
  "title4468": "供应商信息",
  "title4469": "选择供应商",
  "title4470": "供应商链接",
  "title4471": "售后问题",
  "title4472": "退货单号",
  "title4473": "售后原因",
  "title4474": "处理方式",
  "title4475": "退货退款",
  "title4476": "退货处理中",
  "title4477": "操作描述",
  "title4478": "采购时间",
  "title4479": "购买数量",
  "title4480": "下单时间：",
  "title4481": "发货时间：",
  "title4482": "出入库数量",
  "title4483": "出入库原因",
  "title4484": "修改日志",
  "title4485": "当前限制选择",
  "title4486": "个文件，本次选择了",
  "title4487": "个文件，共选择了",
  "title4488": "个文件",
  "title4489": "已选择",
  "title4490": "选择货架位",
  "title4491": "加入采购计划",
  "title4492": "添加采购计划后，请前往“采购-",
  "title4493": "采购计划”页面查看。",
  "title4494": "打印数量",
  "title4495": "条形码",
  "title4496": "二维码",
  "title4497": "标签纸张",
  "title4498": "一列",
  "title4499": "两列",
  "title4500": "三列",
  "title4501": "打印",
  "title4502": "印",
  "title4503": "请选择标签纸张",
  "title4504": "请输入数量",
  "title4505": "最后一个不可删除！",
  "title4506": "分组设置",
  "title4507": "输入关键字进行过滤",
  "title4508": "删除分组",
  "title4509": "同时删除其子分组，并将应用数据的分组调整为未分组。",
  "title4510": "补货建议数据将根据备货时长，计算出建议数据，请根据实际情况填写使用",
  "title4511": "新增日销量",
  "title4512": "备货规则",
  "title4513": "历史销量排除断货数据",
  "title4514": "日销量规则",
  "title4515": "动态销量",
  "title4516": "固定销量",
  "title4517": "百分比销量",
  "title4518": "时间段",
  "title4519": "所有时间",
  "title4520": "日销量",
  "title4521": "计算",
  "title4522": "天日均",
  "title4523": "买家下单时间",
  "title4524": "买家申请时间",
  "title4525": "买家退货原因",
  "title4526": "退货状态",
  "title4527": "未完成",
  "title4528": "已完成",
  "title4529": "未收到商品",
  "title4530": "商品缺件",
  "title4531": "商品发错了",
  "title4532": "商品损坏",
  "title4533": "商品存在瑕疵",
  "title4534": "过期商品",
  "title4535": "假冒商品",
  "title4536": "商品和描述不一致",
  "title4537": "商品不合适",
  "title4538": "改变主意",
  "title4539": "可疑包裹",
  "title4540": "外包装损坏",
  "title4541": "协商退货",
  "title4542": "重复",
  "title4543": "错误订单",
  "title4544": "不想要了",
  "title4545": "平台取消",
  "title4546": "运费太高",
  "title4547": "没有想要的支付方式",
  "title4548": "修改支付方式",
  "title4549": "有更便宜的",
  "title4550": "价格错误",
  "title4551": "买家未支付",
  "title4552": "优惠不如预期",
  "title4553": "修改优惠",
  "title4554": "快递信息不对",
  "title4555": "买家地址快递不到",
  "title4556": "商品未如期送达",
  "title4557": "商品未及时发货",
  "title4558": "商品存在质量问题",
  "title4559": "缺少配件或赠品",
  "title4560": "商品不如预期",
  "title4561": "以下是一些常见的退货原因",
  "title4562": "退货数",
  "title4563": "占比数",
  "title4564": "退货原因占比数",
  "title4565": "按天",
  "title4566": "结算周期",
  "title4567": "结算状态",
  "title4568": "回款记录",
  "title4569": "转账金额",
  "title4570": "转账日期",
  "title4571": "转账状态",
  "title4572": "银行尾号",
  "title4573": "银行转账编码",
  "title4574": "未结算金额",
  "title4575": "已结算金额",
  "title4576": "转账失败金额",
  "title4577": "成本占比",
  "title4578": "多选地区",
  "title4579": "对比分析",
  "title4580": "订单导出",
  "title4581": "人民币",
  "title4582": "当地货币",
  "title4583": "添加自定义模版",
  "title4584": "正在导出，请稍后！",
  "title4585": "正在处理导出数据，请勿关闭当前页面。完成后将自动下载，关闭页面将取消下载。",
  "title4586": "请选择模板",
  "title4587": "标准模板",
  "title4588": "选择标签",
  "title4589": "个订单，仅修改勾选的订单中店铺",
  "title4590": "的匹配关系",
  "title4591": "已匹配商品",
  "title4592": "多选平台",
  "title4593": "主",
  "title4594": "拆",
  "title4595": "订单状态",
  "title4596": "基础信息",
  "title4597": "商品金额",
  "title4598": "规格",
  "title4599": "昵称",
  "title4600": "地址1",
  "title4601": "手机",
  "title4602": "镇",
  "title4603": "地址2",
  "title4604": "电话",
  "title4605": "门牌号",
  "title4606": "地址3",
  "title4607": "物流信息",
  "title4608": "买家选择物流",
  "title4609": "揽货方式",
  "title4610": "我的备注",
  "title4611": "财务信息",
  "title4612": "当前币种",
  "title4613": "兑换",
  "title4614": "固定汇率",
  "title4615": "试算",
  "title4616": "预估收入",
  "title4617": "预估成本",
  "title4618": "运费支出（试算）",
  "title4619": "合计支出",
  "title4621": "合计利润",
  "title4622": "收益",
  "title4623": "暂无订单包裹",
  "title4624": "包裹",
  "title4625": "暂无包裹信息",
  "title4626": "暂无信息",
  "title4627": "拆单",
  "title4628": "取消拆单",
  "title4629": "个包裹，",
  "title4630": "个产品",
  "title4631": "仓库：",
  "title4632": "拆分后包裹",
  "title4633": "主包裹",
  "title4634": "移动到",
  "title4635": "号包裹",
  "title4636": "商品单价",
  "title4637": "至少选择一个产品",
  "title4638": "主包裹至少得有一个产品",
  "title4639": "品牌名称",
  "title4640": "请输入品牌名称",
  "title4641": "品牌编号",
  "title4642": "品牌图片",
  "title4643": "分类图片",
  "title4644": "品牌排序",
  "title4645": "品牌描述",
  "title4646": "请输入品牌排序",
  "title4647": "品牌名称不能为空",
  "title4648": "品牌图片不能为空",
  "title4649": "品牌排序不能为空",
  "title4650": "添加品牌",
  "title4651": "修改品牌",
  "title4652": "是否确认删除品牌编号为",
  "title4653": "缩略图",
  "title4654": "缩略图片",
  "title4655": "跳转链接",
  "title4656": "请输入跳转链接",
  "title4657": "请输入排序",
  "title4658": "标题不能不空",
  "title4659": "图片地址不能为空",
  "title4660": "跳转地址不能为空",
  "title4661": "排序不能为空",
  "title4662": "分类排序",
  "title4663": "上级分类",
  "title4664": "推荐",
  "title4665": "图片分辨率",
  "title4666": "分类描述",
  "title4667": "请输入分类描述",
  "title4668": "请选择上级分类",
  "title4669": "分类名称不能为空",
  "title4670": "分类图片不能为空",
  "title4671": "分类排序不能为空",
  "title4672": "顶级分类",
  "title4673": "添加商品分类",
  "title4674": "修改商品分类",
  "title4675": "是否确认删除商品分类编号为",
  "title4676": "规格名称",
  "title4677": "请输入规格名称",
  "title4678": "属性值",
  "title4679": "属性值不能为空",
  "title4680": "添加规格",
  "title4681": "修改规格",
  "title4682": "是否确认删除规格名称为",
  "title4683": "是否确认导出所有规格名称数据项",
  "title4684": "商品名称、商品编码、商品分类、商品品牌",
  "title4685": "商品销量、商品金额",
  "title4686": "请选择上下架状态",
  "title4687": "参考界面；",
  "title4688": "字段：商品信息、价格、库存、销量、排序、创建时间、状态、操作；",
  "title4689": "分成全部、销售中、仓库中、预警中",
  "title4690": "商品主图地址",
  "title4691": "排序字段",
  "title4692": "点击量",
  "title4693": "价格区间",
  "title4694": "修改商品",
  "title4695": "基础设置",
  "title4696": "商品卖点",
  "title4697": "请输入商品卖点",
  "title4698": "商品主图",
  "title4699": "商品品牌",
  "title4700": "请选择商品品牌",
  "title4701": "商品分类",
  "title4702": "立即上架",
  "title4703": "放入仓库",
  "title4704": "价格库存",
  "title4705": "启用多规格",
  "title4706": "单规格",
  "title4707": "多规格",
  "title4708": "规格项：",
  "title4709": "添加规格项目",
  "title4710": "规格明细",
  "title4711": "规格图片",
  "title4712": "市场价",
  "title4713": "销售价",
  "title4714": "成本价",
  "title4715": "预警库存",
  "title4716": "条码",
  "title4717": "禁用",
  "title4718": "虚拟销量",
  "title4719": "请输入虚拟销量",
  "title4720": "商品详情",
  "title4721": "高级设置",
  "title4722": "请输入排序字段",
  "title4723": "是否展示库存",
  "title4724": "确认",
  "title4725": "商品状态不能为空",
  "title4726": "商品轮播图地址不能为空",
  "title4727": "虚拟销量不能为空",
  "title4728": "渠道费率",
  "title4729": "请输入渠道费率",
  "title4730": "开放平台",
  "title4731": "请输入开放平台",
  "title4732": "渠道状态",
  "title4733": "网关地址",
  "title4734": "算法类型",
  "title4735": "公钥类型",
  "title4736": "商户私钥",
  "title4737": "请输入商户私钥",
  "title4738": "支付宝公钥字符串",
  "title4739": "请输入支付宝公钥字符串",
  "title4740": "商户公钥应用证书",
  "title4741": "请上传商户公钥应用证书",
  "title4742": "支付宝公钥证书",
  "title4743": "请上传支付宝公钥证书",
  "title4744": "根证书",
  "title4745": "请上传根证书",
  "title4746": "请输入开放平台上创建的应用的",
  "title4747": "渠道状态不能为空",
  "title4748": "请传入网关地址",
  "title4749": "请传入签名算法类型",
  "title4750": "公钥类型不能为空",
  "title4751": "请上传指定根证书",
  "title4752": "编辑支付渠道",
  "title4753": "创建支付渠道",
  "title4754": "请上传指定格式",
  "title4755": "文件大小超过",
  "title4756": "公众号",
  "title4757": "请输入公众号",
  "title4758": "商户密钥",
  "title4759": "请输入商户密钥",
  "title4760": "证书",
  "title4761": "请上传",
  "title4762": "请传入商户号",
  "title4763": "版本不能为空",
  "title4764": "密钥值",
  "title4765": "请输入执行汇率",
  "title4766": "您设置的执行汇率波动较大，请核对后再提交",
  "title4767": "货币符号：",
  "title4768": "货币名称：",
  "title4769": "操作明细",
  "title4770": "自定义模板",
  "title4771": "请选择至少一种参数",
  "title4772": "回款汇率设置仅对“已回款”订单产生影响，并对财务报表和利润计算产生影响。",
  "title4773": "修改汇率后，预计10至15分后生效，请您耐心等待。",
  "title4774": "选择月",
  "title4775": "回款汇率",
  "title4776": "更新中",
  "title4777": "修改汇率",
  "title4778": "国际实时汇率",
  "title4779": "请输入回款汇率",
  "title4780": "您设置的回款汇率波动较大，请核对后再提交",
  "title4781": "编辑汇率",
  "title4782": "汇率折扣",
  "title4783": "温馨提示：",
  "title4784": "如何使用汇率折扣？",
  "title4785": "选择汇率折扣时，汇率则按汇率折扣执行，实际应用在订单等信息计算汇率的地方。",
  "title4786": "汇率折扣怎么得来？",
  "title4787": "汇率折扣通常通过将回款汇率除以官方汇率计算得出。",
  "title4788": "例如，如果官方汇率为7",
  "title4789": "而回款汇率为7",
  "title4790": "则汇率折扣比例",
  "title4791": "四舍五入至小数点后四位，即得99",
  "title4792": "在输入框中输入99",
  "title4793": "即可。",
  "title4794": "请输入固定汇率",
  "title4795": "您设置的汇率波动较大，请核对后再提交",
  "title4796": "请输入比例",
  "title4797": "修改执行汇率",
  "title4798": "修改历史执行汇率，预计10至15分钟后生效，请您耐心等待。",
  "title4799": "汇率折扣：",
  "title4800": "请先选择日期",
  "title4801": "不可选择当天",
  "title4802": "客户端编号",
  "title4803": "客户端密钥",
  "title4804": "应用图标",
  "title4805": "访问令牌的有效期",
  "title4806": "刷新令牌的有效期",
  "title4807": "授权类型",
  "title4808": "请输入客户端编号",
  "title4809": "请输入客户端密钥",
  "title4810": "应用描述",
  "title4811": "单位：秒",
  "title4812": "请输入授权类型",
  "title4813": "授权范围",
  "title4814": "请输入授权范围",
  "title4815": "自动授权范围",
  "title4816": "可重定向的",
  "title4817": "请输入可重定向的",
  "title4818": "权限",
  "title4819": "请输入权限",
  "title4820": "资源",
  "title4821": "请输入资源",
  "title4822": "附加信息",
  "title4823": "请输入附加信息，",
  "title4824": "格式数据",
  "title4825": "客户端编号不能为空",
  "title4826": "客户端密钥不能为空",
  "title4827": "应用图标不能为空",
  "title4828": "访问令牌的有效期不能为空",
  "title4829": "刷新令牌的有效期不能为空",
  "title4830": "授权类型不能为空",
  "title4831": "客户端",
  "title4832": "是否确认删除客户端编号为",
  "title4833": "访问令牌",
  "title4834": "刷新令牌",
  "title4835": "强退",
  "title4836": "是否确认强退令牌为",
  "title4837": "强退成功",
  "title4838": "请输入旧密码",
  "title4839": "新密码",
  "title4840": "请输入新密码",
  "title4841": "请确认密码",
  "title4842": "两次输入的密码不一致",
  "title4843": "旧密码不能为空",
  "title4844": "新密码不能为空",
  "title4845": "长度在",
  "title4846": "确认密码不能为空",
  "title4847": "指纹",
  "title4848": "社交平台",
  "title4849": "已绑定",
  "title4850": "解绑",
  "title4851": "未绑定",
  "title4852": "绑定",
  "title4853": "绑定成功",
  "title4854": "解绑成功",
  "title4855": "资料修改",
  "title4856": "头像",
  "title4857": "更换头像",
  "title4858": "男",
  "title4859": "女",
  "title4860": "手机验证",
  "title4861": "您绑定的手机",
  "title4862": "验证码",
  "title4863": "点击上传头像",
  "title4864": "修改头像",
  "title4865": "文件格式错误，请上传图片类型",
  "title4866": "后缀的文件。",
  "title4867": "商品采集",
  "title4868": "数据采集进行中，请勿关闭当前页面。若关闭页面，将取消商品采集操作。",
  "title4869": "商品正在采集中，请稍后",
  "title4870": "采集成功，请在采集列表中查看结果",
  "title4871": "长描述",
  "title4872": "短描述",
  "title4873": "根据各平台对产品图数量的限制，将选择排序靠前的图片作为商品图。",
  "title4874": "本地上传",
  "title4875": "线上地址",
  "title4876": "红色",
  "title4877": "黄色",
  "title4878": "蓝色",
  "title4879": "当前页",
  "title4880": "核心指标",
  "title4881": "库存情况",
  "title4882": "成本销量走势",
  "title4883": "销售情况",
  "title4884": "费用明细",
  "title4885": "评价情况",
  "title4886": "数据分布",
  "title4887": "自定义费用明细",
  "title4888": "暂无费用",
  "title4889": "占该地区的",
  "title4890": "占全部店铺的",
  "title4891": "指标排序",
  "title4892": "拖动指标进行排序",
  "title4893": "总利润，不包含取消、测评、搁置的订单。",
  "title4894": "买家已下单的金额汇总，包含平台补贴。",
  "title4895": "有效订单下的总销售额，不包含取消、测评、搁置的订单，包含平台补贴。",
  "title4896": "买家已下订单的总数量。",
  "title4897": "订单总量，不包含取消、测评、搁置的订单。",
  "title4898": "按图表查看",
  "title4899": "按表格查看",
  "title4900": "最多显示15条数据",
  "title4901": "成本共计",
  "title4902": "复合图",
  "title4903": "对比图",
  "title4904": "好评",
  "title4905": "中差评",
  "title4906": "评论正在收集中，请稍后查看",
  "title4907": "分析评论",
  "title4908": "评论时间",
  "title4909": "成本与销售额对比",
  "title4910": "走势图",
  "title4911": "天平均日销量",
  "title4912": "天总销量",
  "title4913": "预估可售天数",
  "title4914": "在途库存）",
  "title4915": "库龄",
  "title4916": "库存总价值",
  "title4917": "总可用库存",
  "title4918": "总冻结库存",
  "title4919": "总在途中",
  "title4920": "平均可售天数",
  "title4921": "第",
  "title4922": "组最大天数不能为空",
  "title4923": "数值必须大于上一组",
  "title4924": "退款金额",
  "title4925": "微信支付",
  "title4926": "店铺信息",
  "title4927": "预估回款金额",
  "title4928": "预估手续费",
  "title5000": "匹配",
  "title5001": "字母必须包含大写",
  "title5002": "字母、数字和字符至少包含2种",
  "title5003": "密码不能是相同的用户名",
  "title5004": "退出",
  "title5005": "恭喜您注册成功！为了提供更好的体验，请填写以下信息。",
  "title5006": "请选择销售地区并确定主币种，一旦确定账号销售地区和主币种，将无法再次修改，请核对无误后提交。",
  "title5007": "选择销售地区",
  "title5008": "账号币种",
  "title5010": "联系我们",
  "title5011": "添加专属客服，及时解决问题",
  "title5012": "提供",
  "title5013": "对",
  "title5014": "的产品操作演示",
  "title5015": "让您和您的团队迅速掌握和使用",
  "title5016": "扫码获取心舰专业服务",
  "title5017": "请选择销售区域",
  "title5018": "中国台湾",
  "title5019": "印尼",
  "title5020": "马来西亚",
  "title5021": "菲律宾",
  "title5022": "新加坡",
  "title5023": "泰国",
  "title5024": "越南",
  "title5025": "确定退出系统吗？",
  "title5026": "提示",
  "title5027": "脚本",
  "title5028": "对应的描述文案",
  "title5029": "是否必填",
  "title5030": "默认值",
  "title5031": "模板",
  "title5032": "模板编号为",
  "title5033": "模板数据项",
  "title5034": "模板分类",
  "title5035": "模板分类编号为",
  "title5036": "模板分类数据项",
  "title5037": "天",
  "title5038": "个",
  "title5039": "合计",
  "title5040": "评论分析次数",
  "title5041": "点数",
  "title5042": "有效时长",
  "title5043": "请在新弹出页面完成支付，支付完成后点击下方按钮",
  "title5044": "温馨提示",
  "title5045": "支付完成",
  "title5046": "恭喜您，套餐购买成功",
  "title5047": "套餐购买失败，支付未成功",
  "title5048": "搜索用户",
  "title5049": "续需要执行如下",
  "title5050": "个步骤：",
  "title5051": "参数，无法查看流程信息",
  "title5052": "不能为空",
  "title5053": "自然访客数",
  "title5054": "自然销量",
  "title5055": "自然销售额",
  "title5056": "数据项",
  "title5057": "商品",
  "title5058": "小时",
  "title5059": "价格",
  "title5060": "请输入商品名称",
  "title5061": "商品名称",
  "title5062": "高，",
  "title5063": "天不能为空",
  "title5064": "天商品利润",
  "title5065": "天商品利润编号为",
  "title5066": "天商品利润数据项",
  "title5067": "删除后不可恢复，同时会同步删除关联的库存及配对关系",
  "title5068": "编号为",
  "title5069": "箱子：猫咪专属私人空间，让它们更健康，生活更美好！",
  "title5070": "如何使用？",
  "title5071": "匹配关系帮助信息",
  "title5072": "同步线上产品：",
  "title5073": "将已授权店铺的产品",
  "title5074": "更新到本地。",
  "title5075": "快速生成匹配：",
  "title5076": "省去手动创建本地商品",
  "title5077": "的繁琐流程。",
  "title5078": "生成商品",
  "title5079": "在同步完成后，快速为同步的产品",
  "title5080": "并自动建立配对关系。",
  "title5081": "合并商品",
  "title5082": "将选中需要合并的产品",
  "title5083": "合并为一个本地商品",
  "title5084": "自动配对",
  "title5085": "通过自动识别规则，实现线上和线下",
  "title5086": "的自动配对关系。",
  "title5087": "解除配对",
  "title5088": "取消产品",
  "title5089": "与本地商品",
  "title5090": "的配对关系。",
  "title5091": "温馨提示：",
  "title5092": "目前只支持生成单",
  "title5093": "我知道了",
  "title5094": "商品不能为空",
  "title5095": "创建货架位",
  "title5096": "单个添加",
  "title5097": "批量添加",
  "title5098": "导入货架位",
  "title5099": "打印货架标签",
  "title5100": "货架规格",
  "title5101": "最大100列",
  "title5102": "列数",
  "title5103": "最大100层",
  "title5104": "层数",
  "title5105": "连接符",
  "title5106": "平面图",
  "title5107": "货架底部",
  "title5108": "请输入数量",
  "title5109": "请输入1-100的正整数",
  "title5110": "全部",
  "title5111": "空置",
  "title5112": "已存放",
  "title5113": "已存满",
  "title5114": "可清理",
  "title5115": "请选择项目",
  "title5116": "吗？",
  "title5117": "失败",
  "title5118": "平均日销量",
  "title5119": "海运天数",
  "title5120": "额外备货天数）",
  "title5121": "近15天平均日销量",
  "title5122": "调拨在途",
  "title5123": "扫描入库",
  "title5124": "打印标签",
  "title5125": "运输天数",
  "title5126": "缺货数量",
  "title5127": "天店铺利润",
  "title5128": "天店铺利润编号为",
  "title5129": "天店铺利润数据项",
  "title5130": "扫描操作",
  "title5131": "扫描发货",
  "title5132": "商品金额",
  "title5135": "面单",
  "title5136": "拣货单",
  "title5137": "州",
  "title5138": "县",
  "title5139": "新成功数量：",
  "title5140": "新失败数量：",
  "title5141": "错误日志数据项",
  "title5142": "访问日志数据项",
  "title5143": "匹配的",
  "title5144": "的对象内容",
  "title5145": "资源路径",
  "title5146": "重复的问题。",
  "title5147": "模板过于复杂，不利于胖友二次开发",
  "title5148": "折扣",
  "title5149": "随机",
  "title5150": "为",
  "title5151": "时需要添加",
  "title5152": "时可选择性添加",
  "title5153": "领取之日起或者次日",
  "title5154": "天内有效",
  "title5155": "优惠券仅原价购买商品时可用",
  "title5156": "满减",
  "title5157": "随机不能为空",
  "title5158": "有门槛不能为空",
  "title5159": "代表无限制不能为空",
  "title5160": "当",
  "title5161": "时需要添加不能为空",
  "title5162": "时可选择性添加不能为空",
  "title5163": "领取之日固定日期后过期",
  "title5164": "领取次日固定日期后过期不能为空",
  "title5165": "为2或者3时需要添加",
  "title5166": "天内有效不能为空",
  "title5167": "是不能为空",
  "title5168": "开启不能为空",
  "title5169": "天提醒不能为空",
  "title5170": "不限制",
  "title5171": "优惠券仅原价购买商品时可用不能为空",
  "title5172": "天！",
  "title5173": "开头",
  "title5174": "的账号不能为空",
  "title5175": "的模板编号不能为空",
  "title5176": "赋予专业身份",
  "title5177": "如：你是一位资深的营销大师",
  "title5178": "需要以什么样的格式，并输出内容。",
  "title5179": "测评成本",
  "title5180": "利润月报帮助信息",
  "title5181": "检查订单：",
  "title5182": "检查订单是否配对或成本是否维护，在",
  "title5183": "利润分析-订单利润明细】",
  "title5184": "中筛选【无商品成本】的订单进行维护。",
  "title5185": "标记测评和搁置订单：",
  "title5186": "在订单管理中，对需要测评或搁置的订单进行相应的标记",
  "title5187": "订单管理-订单处理】",
  "title5188": "设置回款汇率：",
  "title5189": "根据最新的汇率设定进行调整，确保回款金额的准确计算",
  "title5190": "利润分析-利润月报-回款汇率设置】",
  "title5191": "测评汇率设置错误：",
  "title5192": "测评付款汇率设置错误的时候，在",
  "title5193": "设置-测评参数】",
  "title5194": "里进行修改，修改完成后，在",
  "title5195": "找到相应的订单并选择【批量操作-刷新最新成本】。",
  "title5196": "当以上任意内容发生修改时，需要点击【更新利润】按钮来更新利润数据。",
  "title5197": "系统每月1日会自动更新数据，如需要提前统计需要点击【更新利润】按钮更新数据。",
  "title5198": "空包费",
  "title5199": "验证原手机号",
  "title5200": "验证",
  "title5201": "新手机号码",
  "title5202": "手机号码",
  "title5203": "验证码错误，重新输入",
  "title5204": "参数，无法查看",
  "title5205": "请假信息",
  "title5206": "日",
  "title5207": "个文件，本次选择了",
  "title5208": "个文件，共选择了",
  "title5209": "个文件",
  "title5210": "同时删除其子分组，并将应用数据的分组调整为未分组。",
  "title5211": "错误订单",
  "title5212": "接收仓库会自动选择默认仓库，而非其中一个订单的出库仓库。处理单个包裹扫描2次可执行入库操作。扫描单次上限处理500个退货包裹。",
  "title5213": "是否入库",
  "title5215": "全部不入库",
  "title5216": "接收仓库",
  "title5217": "扫描或输入订单号",
  "title5218": "运单号",
  "title5219": "批量导入运单号",
  "title5220": "扫描结果",
  "title5221": "请输入退货单号",
  "title6000": "请输入退货运单号",
  "title6001": "扫描或输入退货单号",
  "title6002": "退货运单号",
  "title6003": "请先扫描或输入退货单号",
  "title5222": "暂无扫描数据",
  "title5223": "平台",
  "title5224": "退货单号",
  "title5225": "退货运单号",
  "title5226": "产品",
  "title5228": "接收货架",
  "title5230": "操作",
  "title5231": "移除",
  "title5232": "温馨提示：一旦处理入库，退货单是无法取消入库，",
  "title5233": "请谨慎操作",
  "title5234": "关闭",
  "title5235": "该订单已在扫描列表",
  "title5236": "已处理成功",
  "title5237": "扫描前请先将输入法切换成 [EN] 输入法，扫描发货后，包裹将自动移入【待揽收】列表",
  "title5238": "确定发货",
  "title5240": "扫描成功",
  "title5241": "清空继续扫描",
  "title5242": "当前扫描",
  "title5243": "运单号：",
  "title5244": "物流方式：",
  "title5245": "请连接扫描枪扫描包裹",
  "title5246": "信息",
  "title5247": "数量",
  "title5248": "扫描记录",
  "title5249": "已扫描：",
  "title5250": "已发货成功：",
  "title5251": "订单号",
  "title5252": "总数",
  "title5253": "发货状态",
  "title5254": "发货成功",
  "title5255": "扫描1次表示扫描1次执行发货，扫描2次表示第1次显示订单信息，第2次扫描执行发货。",
  "title5256": "扫描次数",
  "title5257": "扫描1次",
  "title5258": "扫描2次",
  "title5259": "扫描错误，请将鼠标点击到输入框，使其变为蓝色",
  "title5260": "系统提示",
  "title5261": "确认清空所有扫描记录吗",
  "title5262": "金额明细",
  "title5263": "当前币种",
  "title5264": "切换",
  "title5265": "当前汇率：",
  "title5266": "买家实付",
  "title5267": "折扣后商品小计",
  "title5268": "折扣前商品小计",
  "title5269": "卖家商品折扣",
  "title5270": "商品折扣",
  "title5271": "折扣后运费",
  "title5272": "折扣前运费",
  "title5273": "卖家运费折扣",
  "title5274": "运费折扣",
  "title5275": "税收",
  "title5276": "平台回款",
  "title5278": "卖家折扣后小计",
  "title5279": "卖家折扣后退款小计",
  "title5280": "总费用",
  "title5281": "交易手续费",
  "title5282": "佣金",
  "title5283": "卖家运费",
  "title5284": "用户运费",
  "title5285": "实际运费",
  "title5286": "运费补贴",
  "title5287": "实际退货运费",
  "title5288": "退还客户运费",
  "title5289": "达人佣金",
  "title5290": "服务费",
  "bonus_cashback_service_fee": 'Bonus cashback service fee',
  "title5292": "预估回款金额",
  "title5293": "回款金额",
  "title5296": "打包费用",
  "title5297": "预估运费",
  "title5298": "预估手续费",
  "title5301": "利润",
  "title5302": "利润率",
  "title5303": "运费",
  "title5304": "原始运费",
  "title5305": "券",
  "title5306": "卖家券",
  "title5307": "商品金额",
  "title5308": "跨境物流成本（实际）",
  "title5311": "虾皮运费返还",
  "title5312": "卖家优惠券金额",
  "title5313": "费用与收费",
  "title5314": "联盟营销方案佣金",
  "title5315": "原来运费",
  "title5316": "平台运费折扣",
  "title5317": "卖家折扣",
  "title5318": "调整费",
  "title5319": "财务信息",
  "title5320": "号包裹",
  "title5321": "证书",
  "title5322": "密钥值",
  "title5323": "成功",
  "title5324": "地址不能为空",
  "title5325": "客户端",
  "title5326": "到",
  "title5327": "个字符",
  "title5328": "如：",
  "title5329": "后缀的文件。",
  "title5330": "跨月调整",
  "title5332": "组最大天数不能为空",
  "title5333": "最近",
  "title5334": "天总销量",
  "title5335": "请输入订购内容",
  "title5336":"更多详情",
  "title5337":"商品SKU种数",
  "title5338":"达人搜索",
  "title5339":"达人ID",
  "title5340":"国家地区",
  "title5341":"达人分类",
  "title5342":"达人信息",
  "title5343":"粉丝信息",
  "title5344":"带货数据",
  "title5345":"仅看带货达人",
  "title5346":"商品SKU种数",
  "title5347":"带货信息",
  "title5348":"个性筛选",
  "title5349":"达人收录",
  "title5350":"达人昵称",
  "title5351":"粉丝数",
  "title5352":"视频数",
  "title5353":"带货商品数",
  "title5354":"近30日销量",
  "title5355":"总销售额",
  "title5356":"近30日销售额",
  "title5357":"视频GPM",
  "title5358":"直播GPM",
  "title5359":"分配",
  "title5360":"跟进",
  "title5361":"已在公海",
  "title5362":"达人入录",
  "title5363":"请输入达人ID/达人主页链接",
  "title5364":"收录结果可以在右边【任务中心】查看",
  "title5365":"达人",
  "title5366":"当前跟进达人数",
  "title5367":"商务",
  "title5368":"达人地区",
  "title5369":"请选择商务",
  "title5370":"确定批量操作跟进达人吗？",
  "title5371":"请选择达人",
  "title5372":"添加达人",
  "title5373":"手动添加达人",
  "title5374":"新增达人",
  "title5375":"批量导入达人",
  "title5376":"收录达人",
  "title5377":"联系方式",
  "title5378":"达人标签",
  "title5379":"设置标签",
  "title5380":"选择商品组",
  "title5381":"归属店铺",
  "title5382":"出单达人数",
  "title5383":"达人成交金额",
  "title5384":"完成/寄样",
  "title5385":"商品组",
  "title5386":"同步时间",
  "title5387":"日期筛选",
  "title5388":"分类搜索",
  "title5389":"商品总数",
  "title5390":"寄样总数",
  "title5391":"新增分类",
  "title5392":"添加标签组",
  "title5393":"请输入达人标签名称",
  "title5394":"添加标签",
  "title5395":"标签组",
  "title5396":"标签名称",
  "title5397":"第一个标签必须填写",
  "title5398":"新增标签",
  "title5399":"删除公海达人",
  "title5400":"若已经被商务跟进的达人，将不会从对方的达人管理列表删除，仅删除达人公海内的记录",
  "title5401":"直播带货为主",
  "title5402":"视频带货为主",
  "title5403":"搜索达人",
  "title5404":"出单店铺",
  "title5405":"成交商品数",
  "title5406":"成交金额",
  "title5407":"预估佣金",
  "title5408":"实际佣金",
  "title5409":"已跟进",
  "title5410":"收录方式",
  "title5411":"收录状态",
  "title5412":"收录成功",
  "title5413":"收录中",
  "title5414":"收录失败",
  "title5415":"达人数据",
  "title5416":"结算佣金",
  "title5417":"达人销售额",
  "title5418":"包裹追踪",
  "title5419":"全部评论",
  "title5420": "刷新全部最新成本",
  "title5421": "去配对",
  "title5422": "当前商品",
  "curSku": "当前产品",
  "title5423": "请先选择",
  "title5424": "设置补货策略",
  "title5425": "设置采购天数",
  "title5426": "妥投时间",
  "title5427": "导入商品SKU及成本",
  "title5428": "导入更新成本",
  "title5429": "更新成本导入模版",
  "title5430": "SKU及商品成本导入模版",
  "title5431": "商品监控",
  "title5432": "采购策略修改成功！是否根据新的策略计算采购建议？",
  "title5433": "变更为",
  "title5434": "取消时间",
  "title5435": "取消原因",
  "title5436": "正在计算，请稍后",
  "title5437": "待支付",
  "title5438": "将本地商品与线上店铺SKU匹配，实现数据匹配",
  "title5439": "创建本地仓库并管理不同仓库的商品成本",
  "title5440": "终止成功",
  "title5441": "分配组员",




  "title10000": "首页",
  "title10001": "小时，允许的通配符",
  "title10002": "周期从",
  "title10003": "小时",
  "title10004": "从",
  "title10005": "小时开始，每",
  "title10006": "小时执行一次",
  "title10007": "指定",
  "title10008": "可多选",
  "title10009": "分钟，允许的通配符",
  "title10010": "分钟",
  "title10011": "分钟开始，每",
  "title10012": "分钟执行一次",
  "title10013": "秒",
  "title10014": "日",
  "title10015": "月",
  "title10016": "周",
  "title10017": "年",
  "title10018": "时间表达式",
  "title10019": "表达式",
  "title10021": "重置",
  "title10022": "关闭",
  "title10023": "来自组件",
  "title10024": "改变了",
  "title10025": "准备还原",
  "title10026": "一",
  "title10027": "二",
  "title10028": "三",
  "title10029": "四",
  "title10030": "五",
  "title10031": "六",
  "title10032": "最近5次运行时间",
  "title10033": "计算结果中",
  "title10034": "没有达到条件的结果！",
  "title10035": "最近100年内只有上面",
  "title10036": "条结果！",
  "title10037": "日，允许的通配符",
  "title10038": "不指定",
  "title10039": "号开始，每",
  "title10040": "日执行一次",
  "title10041": "每月",
  "title10042": "号最近的那个工作日",
  "title10043": "本月最后一天",
  "title10044": "秒，允许的通配符",
  "title10045": "秒开始，每",
  "title10046": "秒执行一次",
  "title10047": "周，允许的通配符",
  "title10048": "周期从星期",
  "title10049": "第",
  "title10050": "周的星期",
  "title10051": "本月最后一个星期",
  "title10052": "星期一",
  "title10053": "星期二",
  "title10054": "星期三",
  "title10055": "星期四",
  "title10056": "星期五",
  "title10057": "星期六",
  "title10058": "星期日",
  "title10059": "不填，允许的通配符",
  "title10060": "每年",
  "title10061": "年开始，每",
  "title10062": "年执行一次",
  "title10063": "月，允许的通配符",
  "title10064": "月开始，每",
  "title10065": "月月执行一次",
  "title10066": "文档地址：",
  "title10067": "请输入内容",
  "title10068": "上传文件大小不能超过",
  "title10069": "图片插入失败",
  "title10070": "请输入链接地址",
  "title10071": "保存",
  "title10072": "请输入视频地址",
  "title10073": "文本",
  "title10074": "标题1",
  "title10075": "标题2",
  "title10076": "标题3",
  "title10077": "标题4",
  "title10078": "标题5",
  "title10079": "标题6",
  "title10080": "标准字体",
  "title10081": "衬线字体",
  "title10082": "等宽字体",
  "title10083": "单行文本",
  "title10084": "手机号格式错误",
  "title10085": "请输入手机号",
  "title10086": "选取文件",
  "title10087": "请上传",
  "title10088": "大小不超过",
  "title10089": "格式为",
  "title10090": "的文件",
  "title10091": "删除",
  "title10092": "文件格式不正确",
  "title10093": "格式文件",
  "title10094": "正在上传文件，请稍候",
  "title10095": "上传文件数量不能超过",
  "title10096": "个",
  "title10097": "上传图片失败，请重试",
  "title10098": "请输入",
  "title10099": "多行文本",
  "title10100": "密码",
  "title10101": "计数器",
  "title10102": "编辑器",
  "title10103": "下拉选择",
  "title10104": "选项一",
  "title10105": "选项二",
  "title10106": "请选择",
  "title10107": "级联选择",
  "title10108": "选项1",
  "title10109": "选项1-1",
  "title10110": "单选框组",
  "title10111": "多选框组",
  "title10112": "开关",
  "title10113": "滑块",
  "title10114": "时间选择",
  "title10115": "时间范围",
  "title10116": "至",
  "title10117": "开始时间",
  "title10118": "结束时间",
  "title10119": "日期选择",
  "title10120": "日期范围",
  "title10121": "开始日期",
  "title10122": "结束日期",
  "title10123": "评分",
  "title10124": "颜色选择",
  "title10125": "上传",
  "title10126": "点击上传",
  "title10127": "行容器",
  "title10128": "按钮",
  "title10129": "主要按钮",
  "title10130": "表格",
  "title10131": "开发中",
  "title10133": "地址",
  "title10135": "操作",
  "title10136": "请输入图标名称",
  "title10137": "请至少选择一个",
  "title10138": "不能为空",
  "title10139": "文件大小超过",
  "title10140": "应该选择",
  "title10141": "类型的文件",
  "title10142": "搜索",
  "title10143": "提交",
  "title10144": "只能上传不超过",
  "title10145": "的",
  "title10146": "文件",
  "title10147": "预览",
  "title10148": "图片格式文件",
  "title10149": "上传头像图片大小不能超过",
  "title10150": "正在上传图片，请稍候",
  "title10151": "今天",
  "title10152": "昨天",
  "title10153": "最近7天",
  "title10154": "最近14天",
  "title10155": "最近30天",
  "title10156": "本月",
  "title10157": "上月",
  "title10158": "最近半年",
  "title10159": "最近1年",
  "title10160": "状态",
  "title10161": "正在处理请勿关闭当前窗口",
  "title10162": "已完成",
  "title10163": "正在处理数据",
  "title10164": "导出",
  "title10165": "数据导出时请勿关闭当前窗口，导出完成后可下载报表",
  "title10166": "下载",
  "title10167": "关闭",
  "title10168": "准备导出",
  "title10169": "导出中",
  "title10170": "处理完成可下载",
  "title10171": "标题",
  "title10172": "同步",
  "title10173": "数据同步时请勿关闭当前窗口，同步完成后可关闭窗口",
  "title10174": "升级为付费用户后使用",
  "title10175": "预约演示",
  "title10176": "查看帮助",
  "title10177": "立即升级",
  "title10178": "没有与",
  "title10179": "匹配的",
  "title10180": "你的浏览器不支持全屏",
  "title10181": "隐藏搜索",
  "title10182": "显示搜索",
  "title10183": "刷新",
  "title10184": "显隐列",
  "title10185": "显示",
  "title10186": "隐藏",
  "title10187": "同意并继续",
  "title10188": "更多",
  "title10189": "初始化教程",
  "title10190": "轻松管理店铺，提升效率，降低业务成本。",
  "title10191": "操作教程",
  "title10192": "授权三方仓",
  "title10194": "请输入店铺别名",
  "title10195": "仓库",
  "title10196": "选择仓库",
  "title10197": "您在注册账号时，系统自动给您生成了一个仓库并且设置为默认仓库，如需修改请在【库存-库存管理-仓库列表】设置。",
  "title10198": "授权店铺",
  "title10199": "添加商品",
  "title10200": "维护本地商品信息到系统中，便于统计利润、成本等数据",
  "title10201": "商品配对",
  "title10202": "匹配在线商品，将本地商品与线上平台",
  "title10203": "匹配",
  "title10204": "线上商品配对",
  "title10205": "仓库管理",
  "title10206": "初始化库存，便于后续的采购、补货、发货等操作",
  "title10207": "添加仓库",
  "title10208": "人员管理",
  "title10209": "为您公司的运营、采购、财务、主管等角色配置对应的权限",
  "title10210": "参数设置",
  "title10211": "为您系统的货币显示设置对应国家汇率，测评订单汇率设置",
  "title10212": "设置汇率",
  "title10213": "不再提示",
  "title10214": "开启引导",
  "title10215": "返回",
  "title10216": "下一步",
  "title10217": "店铺别名不能为空",
  "title10218": "仓库不能为空",
  "title10219": "新手介绍",
  "title10220": "欢迎使用系统！",
  "title10221": "店铺授权",
  "title10222": "添加店铺授权",
  "title10223": "将您的店铺授权到系统中，便于店铺订单、在线商品等同步到系统内",
  "title10224": "授权店铺可通过【系统-授权管理-店铺管理】授权，新增添加店铺授权。",
  "title10225": "三方授权页面为店铺官方页面，卖家账号和密码仅用于店铺授权，平台不会保存您的密码。",
  "title10226": "商品",
  "title10227": "可通过【库存-商品",
  "title10228": "商品",
  "title10229": "创建，可手动新增，可导入表格创建商品。",
  "title10230": "可创建单",
  "title10231": "商品和组合",
  "title10232": "商品，创建商品时需规范填写",
  "title10233": "与线上",
  "title10234": "配对，用于实现系统自动扣减库存，统计相关数据",
  "title10235": "商品配对可通过【库存-商品",
  "title10236": "线上商品配对】设置。",
  "title10237": "自有仓】您在注册账号时，系统自动给您生成了一个仓库并且设置为默认仓库，如需修改请在【库存-仓库管理-仓库列表】设置。",
  "title10238": "三方仓】授权三方仓，在【库存-仓库管理-仓库列表】新建仓库，创建时选择已授权的三方仓。",
  "title10239": "三方仓授权后，可以将订单推送到三方仓，并支持从三方仓同步商品库存。同时采购单也支持自动推送入库处理。",
  "title10240": "人员设置",
  "title10241": "人员管理可通过【系统-账号管理-子账号管理】设置，设置子账号可分配不同角色权限，分配店铺权限，仓库权限等。",
  "title10242": "角色设置可通过【系统-账号管理-角色管理】创建。",
  "title10243": "汇率设置可通过【系统-设置-汇率设置】修改，系统默认获取实时汇率，可自定义设置汇率，汇率设置完成后订单按最新设置的汇率显示价格。",
  "title10244": "测评参数可通过【系统-设置-测评参数】设置，设置完成后，测评订单的费用将按设置的参数统计测评成本。",
  "title10245": "暂无权限",
  "title10246": "请先添加商品",
  "title10247": "请先完成店铺授权",
  "title10248": "请完成安全验证",
  "title10249": "拖动下方滑块万完成拼图",
  "title10250": "中文",
  "title10251": "当前会员",
  "title10252": "购买",
  "title10253": "基本资料",
  "title10254": "我的套餐",
  "title10255": "我的订单",
  "title10256": "退出角色扮演",
  "title10257": "退出登录",
  "title10258": "林珍",
  "title10259": "请输入登录密码",
  "title10260": "使用其他账号登录",
  "title10261": "版权所有",
  "title10262": "闽",
  "title10263": "备2023007214号-2",
  "title10264": "确定注销并退出系统吗？",
  "title10265": "确定退出角色扮演吗？",
  "title10266": "提示",
  "title10267": "消息启动事件",
  "title10268": "定时启动事件",
  "title10269": "条件启动事件",
  "title10270": "信号启动事件",
  "title10271": "错误启动事件",
  "title10272": "升级启动事件",
  "title10273": "补偿启动事件",
  "title10274": "非中断",
  "title10275": "测试按钮1",
  "title10276": "测试按钮1】点击事件里可以访问当前表单：",
  "title10277": "所以",
  "title10278": "可以拿到当前表单的",
  "title10279": "组件",
  "title10280": "表单的",
  "title10281": "提交数据：",
  "title10282": "在这里输入文字",
  "title10283": "配置文档参阅：",
  "title10284": "验证成功",
  "title10285": "验证失败",
  "title10286": "请依次点击【",
  "title10287": "向右滑动完成验证",
  "title10288": "主题风格设置",
  "title10289": "图标",
  "title10290": "主题颜色",
  "title10291": "系统布局配置",
  "title10292": "开启",
  "title10293": "固定",
  "title10294": "动态标题",
  "title10295": "保存配置",
  "title10296": "重置配置",
  "title10297": "正在保存到本地，请稍候",
  "title10298": "正在清除设置缓存并刷新，请稍候",
  "title10299": "心舰",
  "title10300": "刷新页面",
  "title10301": "关闭当前",
  "title10302": "关闭其他",
  "title10303": "关闭左侧",
  "title10304": "关闭右侧",
  "title10305": "全部关闭",
  "title10306": "测试任务",
  "title10307": "打开文件",
  "title10308": "下载为",
  "title10309": "下载文件",
  "title10310": "退出模拟",
  "title10311": "开启模拟",
  "title10312": "模拟",
  "title10313": "向左对齐",
  "title10314": "向右对齐",
  "title10315": "向上对齐",
  "title10316": "向下对齐",
  "title10317": "水平居中",
  "title10318": "垂直居中",
  "title10319": "缩小视图",
  "title10320": "放大视图",
  "title10321": "重置视图并居中",
  "title10322": "撤销",
  "title10323": "恢复",
  "title10324": "重新绘制",
  "title10325": "保存模型",
  "title10326": "业务流程",
  "title10327": "请按住",
  "title10328": "键选择多个元素对齐",
  "title10329": "自动对齐可能造成图形变形，是否继续？",
  "title10330": "警告",
  "title10331": "保存模型失败，请重试！",
  "title10332": "发起人：",
  "title10333": "部门：",
  "title10334": "创建时间：",
  "title10335": "审批人：",
  "title10336": "结果：",
  "title10337": "结束时间：",
  "title10338": "审批建议：",
  "title10339": "常规",
  "title10340": "消息与信号",
  "title10341": "流转条件",
  "title10342": "表单",
  "title10343": "友情提示：使用",
  "title10344": "流程表单",
  "title10345": "替代，提供更好的表单设计功能",
  "title10346": "任务",
  "title10347": "多实例",
  "title10348": "执行监听器",
  "title10349": "任务监听器",
  "title10350": "扩展属性",
  "title10351": "其他",
  "title10352": "赋予专业身份",
  "title10353": "如：你是一位资深的营销大师",
  "title10354": "需要以什么样的格式，并输出内容。",
  "title10355": "如何使用？",
  "title10356": "测评成本",
  "title10357": "利润月报帮助信息",
  "title10358": "检查订单：",
  "title10359": "检查订单是否配对或成本是否维护，在",
  "title10361": "中筛选【无商品成本】的订单进行维护。",
  "title10362": "标记测评和搁置订单：",
  "title10363": "在订单管理中，对需要测评或搁置的订单进行相应的标记",
  "title10364": "订单管理-订单处理】",
  "title10365": "设置回款汇率：",
  "title10366": "根据最新的汇率设定进行调整，确保回款金额的准确计算",
  "title10367": "利润分析-利润月报-回款汇率设置】",
  "title10368": "测评汇率设置错误：",
  "title10369": "测评付款汇率设置错误的时候，在",
  "title10370": "设置-测评参数】",
  "title10371": "里进行修改，修改完成后，在",
  "title10372": "找到相应的订单并选择【批量操作-刷新最新成本】。",
  "title10373": "温馨提示：",
  "title10374": "当以上任意内容发生修改时，需要点击【更新利润】按钮来更新利润数据。",
  "title10375": "系统每月1日会自动更新数据，如需要提前统计需要点击【更新利润】按钮更新数据。",
  "title10376": "不再显示",
  "title10377": "我知道了",
  "title10378": "字母必须包含大写",
  "title10379": "字母、数字和字符至少包含2种",
  "title10380": "天",
  "title10381": "个文件，本次选择了",
  "title10382": "个文件，共选择了",
  "title10383": "个文件",
  "title10384": "同时删除其子分组，并将应用数据的分组调整为未分组。",
  "title10385": "错误订单",
  "title10386": "扫描入库",
  "title10387": "接收仓库会自动选择默认仓库，而非其中一个订单的出库仓库。处理单个包裹扫描2次可执行入库操作。扫描单次上限处理500个退货包裹。",
  "title10388": "是否入库",
  "title10390": "全部不入库",
  "title10391": "接收仓库",
  "title10392": "扫描或输入订单号",
  "title10393": "运单号",
  "title10394": "批量导入运单号",
  "title10395": "扫描结果",
  "title10396": "暂无扫描数据",
  "title10397": "请连接扫描枪扫描包裹",
  "title10398": "平台",
  "title10399": "退货单号",
  "title10400": "退货运单号",
  "title10401": "产品",
  "title10403": "接收货架",
  "title10405": "移除",
  "title10407": "扫描1次表示扫描1次执行发货，扫描2次表示第1次显示订单信息，第2次扫描执行发货。",
  "title10408": "扫描次数",
  "title10409": "金额明细",
  "title10410": "当前币种",
  "title10411": "切换",
  "title10412": "当前汇率：",
  "title10413": "买家实付",
  "title10414": "折扣后商品小计",
  "title10415": "折扣前商品小计",
  "title10416": "卖家商品折扣",
  "title10417": "商品折扣",
  "title10418": "折扣后运费",
  "title10419": "折扣前运费",
  "title10420": "卖家运费折扣",
  "title10421": "运费折扣",
  "title10422": "税收",
  "title10423": "平台回款",
  "title10425": "卖家折扣后小计",
  "title10426": "卖家折扣后退款小计",
  "title10427": "总费用",
  "title10428": "交易手续费",
  "title10429": "佣金",
  "title10430": "卖家运费",
  "title10431": "用户运费",
  "title10432": "实际运费",
  "title10433": "运费补贴",
  "title10434": "实际退货运费",
  "title10435": "退还客户运费",
  "title10436": "达人佣金",
  "title10437": "服务费",
  "title10438": "预估回款金额",
  "title10439": "回款金额",
  "title10441": "打包费用",
  "title10442": "预估运费",
  "title10443": "预估手续费",
  "title10444": "去设置",
  "title10446": "利润",
  "title10447": "利润率",
  "title10448": "商品金额",
  "title10449": "运费",
  "title10450": "原始运费",
  "title10451": "券",
  "title10452": "卖家券",
  "title10453": "商品金额",
  "title10454": "跨境物流成本（实际）",
  "title10457": "虾皮运费返还",
  "title10458": "卖家优惠券金额",
  "title10459": "费用与收费",
  "title10460": "联盟营销佣金花费",
  "title10461": "原来运费",
  "title10462": "平台运费折扣",
  "title10463": "折扣",
  "title10464": "卖家折扣",
  "title10465": "调整费",
  "title10466": "财务信息",
  "title10467": "扫描发货",
  "title10468": "扫描前请先将输入法切换成 [EN] 输入法，扫描发货后，包裹将自动移入【待揽收】列表",
  "title10469": "确定发货",
  "title10470": "扫描成功",
  "title10471": "清空继续扫描",
  "title10472": "当前扫描",
  "title10473": "运单号：",
  "title10474": "物流方式：",
  "title10475": "信息",
  "title10476": "数量",
  "title10477": "扫描记录",
  "title10478": "已扫描：",
  "title10479": "已发货成功：",
  "title10480": "订单号",
  "title10481": "总数",
  "title10482": "发货状态",
  "title10483": "发货成功",
  "title10484": "扫描1次",
  "title10485": "扫描2次",
  "title10486": "扫描错误，请先将鼠标点击输入框，输入框呈蓝色",
  "title10487": "系统提示",
  "title10488": "确认清空所有扫描记录吗？",
  "title10489": "成功",
  "title10490": "证书",
  "title10491": "密钥值",
  "title10492": "号包裹",
  "title10493": "如何实现实现会签、或签？",
  "title10494": "流程标识",
  "title10495": "请输入流标标识",
  "title10496": "流程名称",
  "title10497": "请输入流程名称",
  "title10498": "流程标识不能为空",
  "title10499": "流程名称不能为空",
  "title10500": "不满足",
  "title10501": "规则，所以不进行赋值",
  "title10502": "满足",
  "title10503": "规则，所以进行赋值",
  "title10504": "表单标识",
  "title10505": "业务标识",
  "title10506": "无",
  "title10507": "表单字段",
  "title10508": "序号",
  "title10509": "字段名称",
  "title10510": "字段类型",
  "title10511": "默认值",
  "title10513": "添加字段",
  "title10514": "字段配置",
  "title10515": "字段",
  "title10516": "类型",
  "title10517": "请选择字段类型",
  "title10518": "类型名称",
  "title10519": "时间格式",
  "title10520": "枚举值列表：",
  "title10521": "添加枚举值",
  "title10522": "枚举值编号",
  "title10523": "枚举值名称",
  "title10524": "约束条件列表：",
  "title10525": "添加约束",
  "title10526": "约束名称",
  "title10527": "约束配置",
  "title10528": "字段属性列表：",
  "title10529": "添加属性",
  "title10530": "属性编号",
  "title10531": "属性值",
  "title10532": "取",
  "title10534": "保",
  "title10535": "存",
  "title10536": "编号",
  "title10537": "配置",
  "title10538": "值",
  "title10539": "确 定",
  "title10540": "",
  "title10541": "长整型",
  "title10542": "字符串",
  "title10543": "布尔类",
  "title10544": "日期类",
  "title10545": "枚举类",
  "title10546": "自定义类型",
  "title10547": "属性配置",
  "title10548": "枚举值配置",
  "title10549": "约束条件配置",
  "title10550": "流转类型",
  "title10551": "普通流转路径",
  "title10552": "默认流转路径",
  "title10553": "条件流转路径",
  "title10554": "条件格式",
  "title10555": "脚本",
  "title10556": "脚本语言",
  "title10557": "脚本类型",
  "title10558": "内联脚本",
  "title10559": "外部脚本",
  "title10560": "资源地址",
  "title10561": "事件类型",
  "title10562": "监听器类型",
  "title10563": "添加监听器",
  "title10564": "类",
  "title10565": "代理表达式",
  "title10566": "脚本格式",
  "title10567": "请填写脚本格式",
  "title10568": "请选择脚本类型",
  "title10569": "脚本内容",
  "title10570": "请填写脚本内容",
  "title10571": "请填写资源地址",
  "title10572": "定时器类型",
  "title10573": "持续时长",
  "title10574": "循环",
  "title10575": "定时器",
  "title10576": "注入字段：",
  "title10577": "字段值",
  "title10578": "字段名称：",
  "title10579": "字段类型：",
  "title10580": "字段值：",
  "title10581": "表达式：",
  "title10582": "回路特性",
  "title10583": "并行多重事件",
  "title10584": "时序多重事件",
  "title10585": "循环事件",
  "title10586": "循环基数",
  "title10587": "集合",
  "title10588": "元素变量",
  "title10589": "完成条件",
  "title10590": "异步状态",
  "title10591": "异步前",
  "title10592": "异步后",
  "title10593": "排除",
  "title10594": "重试周期",
  "title10595": "确认移除该字段吗？",
  "title10596": "认",
  "title10597": "操作取消",
  "title10598": "确认移除该监听器吗？",
  "title10599": "事件",
  "title10600": "监听器",
  "title10601": "请填写定时器配置",
  "title10602": "元素文档：",
  "title10603": "创建",
  "title10604": "指派",
  "title10605": "完成",
  "title10606": "更新",
  "title10607": "超时",
  "title10608": "属性名",
  "title10609": "属性名：",
  "title10610": "属性值：",
  "title10611": "确认移除该属性吗？",
  "title10612": "消息列表",
  "title10613": "创建新消息",
  "title10614": "消息",
  "title10615": "消息名称",
  "title10616": "信号列表",
  "title10617": "创建新信号",
  "title10618": "信号",
  "title10619": "信号名称",
  "title10620": "创建消息",
  "title10621": "创建信号",
  "title10622": "该消息已存在，请修改",
  "title10623": "后重新保存",
  "title10624": "该信号已存在，请修改",
  "title10625": "异步延续",
  "title10626": "跨月调整",
  "title10627": "空包费",
  "title10629": "追加结束事件",
  "title10630": "追加网关",
  "title10631": "追加任务",
  "title10632": "追加中间抛出事件",
  "title10633": "边界事件",
  "title10634": "激活全局连接工具",
  "title10635": "添加",
  "title10636": "在上面添加道",
  "title10637": "分割成两个道",
  "title10638": "分割成三个道",
  "title10639": "在下面添加道",
  "title10640": "追加补偿活动",
  "title10641": "修改类型",
  "title10642": "使用关联连接",
  "title10643": "使用顺序",
  "title10644": "消息流或者关联连接",
  "title10645": "使用数据输入关联连接",
  "title10646": "激活抓手工具",
  "title10647": "激活套索工具",
  "title10648": "激活创建",
  "title10649": "删除空间工具",
  "title10650": "创建扩展子过程",
  "title10651": "创建中间抛出事件",
  "title10652": "创建池",
  "title10653": "参与者",
  "title10654": "数据对象参考",
  "title10655": "数据存储参考",
  "title10656": "即席",
  "title10657": "发送任务",
  "title10658": "接收任务",
  "title10659": "用户任务",
  "title10660": "手工任务",
  "title10661": "业务规则任务",
  "title10662": "服务任务",
  "title10663": "脚本任务",
  "title10664": "调用活动",
  "title10665": "子流程（折叠的）",
  "title10666": "子流程（展开的）",
  "title10667": "开始事件",
  "title10668": "中间事件",
  "title10669": "结束事件",
  "title10670": "创建开始事件",
  "title10671": "创建结束事件",
  "title10672": "创建任务",
  "title10673": "创建用户任务",
  "title10674": "创建网关",
  "title10675": "创建数据对象",
  "title10676": "创建数据存储",
  "title10677": "创建分组",
  "title10678": "创建中间",
  "title10679": "消息开始事件",
  "title10680": "定时开始事件",
  "title10681": "条件开始事件",
  "title10682": "信号开始事件",
  "title10683": "错误开始事件",
  "title10684": "升级开始事件",
  "title10685": "补偿开始事件",
  "title10686": "消息开始事件（非中断）",
  "title10687": "定时开始事件（非中断）",
  "title10688": "条件开始事件（非中断）",
  "title10689": "信号开始事件（非中断）",
  "title10690": "升级开始事件（非中断）",
  "title10691": "消息中间捕获事件",
  "title10692": "消息中间抛出事件",
  "title10693": "定时中间捕获事件",
  "title10694": "升级中间抛出事件",
  "title10695": "条件中间捕获事件",
  "title10696": "链接中间捕获事件",
  "title10697": "链接中间抛出事件",
  "title10698": "补偿中间抛出事件",
  "title10699": "信号中间捕获事件",
  "title10700": "信号中间抛出事件",
  "title10701": "消息结束事件",
  "title10702": "定时结束事件",
  "title10703": "错误结束事件",
  "title10704": "取消结束事件",
  "title10705": "补偿结束事件",
  "title10706": "信号结束事件",
  "title10707": "终止结束事件",
  "title10708": "消息边界事件",
  "title10709": "消息边界事件（非中断）",
  "title10710": "定时边界事件",
  "title10711": "定时边界事件（非中断）",
  "title10712": "升级边界事件",
  "title10713": "升级边界事件（非中断）",
  "title10714": "条件边界事件",
  "title10715": "条件边界事件（非中断）",
  "title10716": "错误边界事件",
  "title10717": "取消边界事件",
  "title10718": "信号边界事件",
  "title10719": "信号边界事件（非中断）",
  "title10720": "补偿边界事件",
  "title10721": "互斥网关",
  "title10722": "并行网关",
  "title10723": "相容网关",
  "title10724": "复杂网关",
  "title10725": "事件网关",
  "title10726": "转运",
  "title10727": "子流程",
  "title10728": "事件子流程",
  "title10729": "折叠池",
  "title10730": "展开池",
  "title10731": "在",
  "title10732": "里，",
  "title10733": "没有父类",
  "title10734": "没有指定的形状类型",
  "title10735": "流元素必须是池",
  "title10736": "参与者的子类",
  "title10737": "子道大于",
  "title10738": "元素不能为空",
  "title10739": "流程图不符合",
  "title10740": "规范",
  "title10741": "没有可展示的流程图",
  "title10742": "没有可展示的流程",
  "title10743": "协作",
  "title10744": "由",
  "title10745": "引用的",
  "title10746": "元素仍未绘制",
  "title10747": "已被渲染",
  "title10748": "导入",
  "title10749": "失败",
  "title10750": "详情",
  "title10751": "创建者",
  "title10752": "持续异步",
  "title10753": "工作配置",
  "title10754": "工作优先级",
  "title10755": "重试时间周期",
  "title10756": "文档",
  "title10757": "元素文档",
  "title10758": "历史配置",
  "title10759": "历史的生存时间",
  "title10760": "业务",
  "title10761": "校验",
  "title10762": "属性",
  "title10763": "执行监听",
  "title10764": "必须提供一个值",
  "title10765": "资源",
  "title10766": "字段注入",
  "title10767": "扩展",
  "title10768": "输入",
  "title10769": "输出",
  "title10770": "输入参数",
  "title10771": "输出参数",
  "title10772": "参数",
  "title10773": "定时器定义类型",
  "title10774": "定时器定义",
  "title10775": "持续",
  "title10776": "升级",
  "title10777": "错误",
  "title10778": "链接名称",
  "title10779": "条件名称",
  "title10780": "变量名称",
  "title10781": "变量事件",
  "title10782": "多个变量事件以逗号隔开",
  "title10783": "等待完成",
  "title10784": "活动参考",
  "title10785": "版本标签",
  "title10786": "可执行文件",
  "title10787": "扩展任务配置",
  "title10788": "任务优先级",
  "title10789": "外部",
  "title10790": "连接器",
  "title10791": "必须配置连接器",
  "title10792": "连接器编号",
  "title10793": "实现方式",
  "title10794": "结果变量",
  "title10795": "主题",
  "title10796": "配置连接器",
  "title10797": "代理人",
  "title10798": "候选用户",
  "title10799": "候选组",
  "title10800": "到期时间",
  "title10801": "跟踪日期",
  "title10802": "优先级",
  "title10803": "跟踪日期必须符合",
  "title10804": "表达式，如：",
  "title10805": "或者一个",
  "title10806": "标准日期，如：2015-06-26",
  "title10807": "变量",
  "title10808": "候选人起动器配置",
  "title10809": "候选人起动器组",
  "title10810": "这映射到流程定义键。",
  "title10811": "候选人起动器的用户",
  "title10812": "指定多个用户作为逗号分隔的列表。",
  "title10813": "启动",
  "title10814": "指定多个组作为逗号分隔的列表。",
  "title10815": "消息实例",
  "title10816": "外部资源",
  "title10817": "跟踪时间",
  "title10818": "友情提示：任务的分配规则，使用",
  "title10819": "流程模型",
  "title10820": "下的【分配规则】替代，提供指定角色、部门负责人、部门成员、岗位、工作组、自定义脚本等",
  "title10821": "种维护的任务分配维度，更加灵活！",
  "title10822": "最近",
  "title10823": "天总销量",
  "title10824": "组最大天数不能为空",
  "title10825": "退款金额",
  "title10826": "请输入第三方服务商名称",
  "title10827": "批量推送",
  "title10828": "推送所选商品",
  "title10829": "推送全部商品",
  "title10830": "商品SKU种数",
  "title10831": "产品",
  "title10832": "条",
  "title10833": "导出已选订单",
  "title10834": "导出全部页订单",
  "title10835": "数据导入时请勿关闭当前窗口，导入完成后可关闭窗口",
  "title10836": "生成商品SKU时请勿关闭当前窗口，生成完成后可关闭窗口",
  "title10837": "自动配对商品SKU时请勿关闭当前窗口，生成完成后可关闭窗口",
  "title10838": "刷新订单商品成本",
  "title10839": "刷新订单商品成本时请勿关闭当前窗口，生成完成后可关闭窗口",
  "title10840": "打印结果确认",
  "title10841": "标记为已打印",
  "title10842": "拣货单(含汇总)已成功生成",
  "title10843": "请点击",
  "title10844": "如果已成功打印",
  "title10845": "已成功生成",
  "title10846": "面单和配货单",
  "title10847": "面单",
  "title11001": "号",
  "title11002": "共",
  "title11003": "条",
  "title11004": "隐藏销量为0的商品",
  "title11005": "显示停售商品",
  "title11006": "配置表格字段",
  "title11007": "可勾选您想在页面上展示的列，系统将按勾选内容展示列内容。",
  "title11008": "已选列，拖动指标进行排序，最多可固定5项",
  "title11009": "本地状态是退货退款中和退款订单或退款金额大于0的订单数量",
  "title11010": "本地状态是退货退款中和退款订单或退款金额大于0的退款金额",
  "title11011": "排除测评+搁置+取消+未付款的销售额",
  "title11012": "排除测评+搁置+取消+未付款的订单量",
  "title11013": "已下单的销售数量",
  "title11014": "销量",
  "title11015": "已下单的订单数量",
  "title11016": "日均有效销量",
  "title11017": "有效销量/天数",
  "title11018": "已下单的总销售额（未取消订单销售额+取消订单销售额 ）/天数",
  "title11019": "已下单的总销售额（未取消订单销售额+取消订单销售额 ）",
  "title11020": "本地状态是退货退款中和退款订单或退款金额大于0的销售数量",
  "title11021": "买家实付金额",
  "title11022": "平台回款金额",
  "title11023": "卖家",
  "title11024": "优惠券金额",
  "title11025": "币",
  "title11026": "抵扣",
  "title11027": "运费返还",
  "title11028": "运费折扣",
  "title11029": "支付运费",
  "title11030": "对应账单 Commission Fee",
  "title11031": "对应账单 Service Fee",
  "title11032": "对应账单 Transaction Fee",
  "title11033": "库存列表维护的商品成本",
  "title11034": "库存列表维护的打包费用",
  "title11035": "平台回款-商品成本-打包费用-调整费用-测评损耗-退款费用-自定义费用",
  "title11036": "货款",
  "title11037": "卖家优惠券",
  "title11038": "多件多折优惠",
  "title11039": "超级联盟",
  "title11040": "包邮促销费用",
  "title11041": "达人合作伙伴佣金",
  "title11042": "平台回款-商品成本-打包费-测评损耗-头程费用-尾程费用",
  "title11043": "常用模版",
  "title11044": "面单打印",
  "title11045": "发票模版",
  "title11046": "标签模版",
  "title11047": "打印方式",
  "title11048": "自动高速打印功能介绍",
  "title11049": "开启该功能，代表打印面单将同时支持常规面单打印和自动高速打印。",
  "title11050": "标记设置",
  "title11051": "自动标记为已打印",
  "title11052": "自动高速打印",
  "title11053": "自定义面单模板",
  "title11054": "添加模板",
  "title11055": "打印设置",
  "title11056": "自定义面单",
  "title11057": "字体大小",
  "title11058": "公用信息",
  "title11059": "打印序号",
  "title11060": "打印次数",
  "title11061": "卖家备注",
  "title11062": "客服备注",
  "title11063": "订单标记",
  "title11064": "面单备注",
  "title11065": "自定义图片",
  "title11066": "无易碎信息",
  "title11067": "有易碎信息",
  "title11068": "近14天",
  "title11069": "近半年",
  "title11070": "日均单量",
  "title11071": "店铺构成",
  "title11072": "店铺构成数据明细",
  "title11073": "精确",
  "title11074": "模糊",
  "title11075": "店铺",
  "title11076": "规则",
  "title11077": "监控规则",
  "title11078": "上期",
  "title11079": "驳回原因",
  "title11080": "驳回说明",
  "title11081": "搁置的订单不能标记测评",
  "title11082": "测评的订单不能标记搁置",
  "title11083": "请先选择三方仓，再进行库存同步操作",
  "title11084": "更换供应商会清空商品列表，是否更换？",
  "title11085": "营销费用",
  "title11086": "最近3个月",
  "title11087": "刷新的订单超过50000,请重新选择条件",
  "title11088": "是否确认",
  "title11089": "请输入产品属性搜索",
  "title11090": "显示{num}条",
  "title11091": "成本价分摊比",
  "title11092": "按人员",
  "title11093": "组合SKU独立统计",
  "title11094": "组合SKU按子SKU统计",
  "title11095": "选择人员",
  "title11096": "交易类型",
  "title11097": "资金流动",
  "title11098": "进账",
  "title11099": "支出",
  "title11100": "子类型",
  "title11101": "请输入订单编号",
  "title11102": "请输入流水编号",
  "title11103": "调整维度",
  "title11104": "流水编号",
  "title11105": "期初库存",
  "title11106": "期末库存",
  "title11107": "入库数量",
  "title11108": "出库数量",
  "title11109": "调拨入库",
  "title11110": "采购入库",
  "title11111": "手动入库",
  "title11112": "退货入库",
  "title11113": "盘盈入库",
  "title11114": "取消单入库",
  "title11115": "三方仓同步入库",
  "title11116": "手动出库",
  "title11117": "销售出库",
  "title11118": "调拨出库",
  "title11119": "盘亏出库",
  "title11120": "三方仓同步出库",
  "title11121": "全部运营人员",
  "title11122": "月销售战绩",
  "title11123": "目标进度",
  "title11124": "请先设置业绩目标",
  "title11125": "运营名称",
  "title11126": "有效销售额(CNY)",
  "title11127": "利润(CNY)",
  "title11128": "利润率",
  "title11129": "当前有效销售额(CNY)",
  "title11130": "月目标有效销售额(CNY)",
  "title11131": "当前利润(CNY)",
  "title11132": "月目标利润(CNY)",
  "title11133": "当前利润率",
  "title11134": "月目标利润率",
  "title11135": "业绩目标设置",
  "title11136": "选择运营人员",
  "title11137": "考核月份",
  "title11138": "请选择考核月份",
  "title11139": "店铺钱包单据来源于Shopee财务-我的余额交易明细",
  "title11140": "店铺钱包单据来源于Lazada财务-我的余额交易明细",
  "title11141": "退款日期",
  "title11142": "请输入平台单号",
  "title11143": "新增线下退款",
  "title11144": "订单实付金额",
  "title11145": "线下退款金额",
  "title11146": "导入线下退款订单",
  "title11147": "线下退款订单导入模板",
  "title11148": "是否确认导出所有线下退款数据项",
  "title11149": "是否删除该条数据",
  "title11150": "是否删除选中数据",
  "title11151": "添加线下退款",
  "title11152": "转账类型",
  "title11153": "转账账号",
  "title11154": "银行",
  "title11155": "买家姓名",
  "title11156": "用途",
  "title11157": "导入线下退款",
  "title11158": "导出线下退款",
  "title11159": "银行卡号",
  "title11160": "请输入银行",
  "title11161": "请输入银行卡号",
  "title11162": "请输入买家姓名",
  "title11163": "请输入线下退款金额",
  "title11164": "请输入用途",
  "title11165": "附件",
  "title11166": "多变种",
  "title11167": "变种信息",
  "title11168": "点击可设置属性和属性值",
  "title11169": "变种属性",
  "title11170": "属性管理",
  "title11171": "添加属性",
  "title11172": "导入属性",
  "title11173": "属性名称",
  "title11174": "属性值(中文)",
  "title11175": "属性值(英文)",
  "title11176": "请输入中文属性值",
  "title11177": "请输入英文属性值",
  "title11178": "编辑属性",
  "title11179": "添加新项",
  "title11180": "自动生成",
  "title11181": "确认删除属性和属性值?",
  "title11182": "添加现有商品",
  "title11183": "请先选择属性",
  "title11184": "请先完善基本信息",
  "title11185": "请先添加SKU",
  "title11186": "变种",
  "title11187": "确认删除该条SPU信息?",
  "title11188": "请添加属性",
  "title11189": "至少保留一条属性值",
  "title11190": "新增属性",
  "title11191": "不可重复",
  "title11192": "确认删除SPU?",
  "title11193": "设置的税费只对Shopee和Lazada店铺生效，税费=广告花费X税率/（1-税率），并在【利润月报】中汇总税费支出项",
  "title11194": "店铺税费",
  "title11195": "广告税费",
  "title11196": "您正在授权店铺广告",
  "title11197": "是否已完成店铺广告授权?",
  "title11198": "添加自定义列",
  "title11199": "新增自定义列",
  "title11200": "自定义列名称",
  "title11201": "计算结果设置",
  "title11202": "显示位置",
  "title11203": "保留两位小数",
  "title11204": "保留整数",
  "title11205": "列表前面",
  "title11206": "列表后面",
  "title11207": "自定义数值",
  "title11208": "税率",
  "title11209": "编辑自定义列",
  "title11210": "百分比",
  "title11211": '（所有计算均以当地货币进行，并输出结果）',
  "title11212": "是否免运",
  "title11213": "达人佣金",
  "title11214": "批量设置",
  "title11215": "将{actPlatform}所有店铺{type}的佣金比例调整为{batchSet}%",
  "title11216": "Shopee需要设置是否免运费，设置后预估利润不会扣减预估运费",
  "title11217": "添加调整费用",
  "title11218": "编辑调整费用",
  "title11219": "分摊店铺",
  "title11220": "分摊日期",
  "title11221": "调整费用会按日期分摊到店铺中，显示在“自定义费用”列，可在“财务管理-成本中心”维护修改",
  "title11222": "仅支持单地区选择时切换币种，不支持多地区切换。",
  "title11223": "切换币种时，根据【设置】-【汇率设置】中的今日执行汇率进行计算。",
  "title11224": "无论是按区间还是某一天来分摊，都会以今日执行汇率为准。",
  "title11225": "因此，在汇率切换后可能存在汇率偏差，不应仅凭此数据作为唯一比较标准。",
  "title11226": "回款对账帮助信息",
  "title11227": "成本中心帮助信息",
  "title11228": "手工订单",
  "title11229": "监控",
  


  "title12001": "面单模板",
  "title13001": "访客数",
  "title13002": "订单量",
  "title13003": "销售额",
  "title13004": "一键交运",
  "title15001": "导出已选商品",
  "title15002": "导出全部商品",
  "title15003": "导出无商品成本",
  "title15004": "已选商品",
  "title15005": "全部商品",
  "title15006": "数据正在加载中,请稍后再点击...",
  "title15007": '物流方式',
  "title15008": '取消',
  "title15009": '移入已妥投',
  "title15010": '是否取消该订单',
  "title15011": '请选择完整的地区',
  "title15012": '金额信息',
  "title15013": '产品总价',
  "title15014": '订单优惠金额',
  "title15015": '请填写收件人',
  "title15016": '安排订单',
  "title15017": '安排',
  "title15018": '同步所选商品库存',
  "title15019": '同步整仓商品库存',
  "title15020": '导入创建匹配关系',
  "title15021": '导出需要创建配对关系的店铺SKU',
  "title15022": '导入修改的Excel表格',
  "title15023": '* 请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除',
  "title15024": '文件大小不得大于',
  "title15025": '非0正整数',
  "title15026": '商品配对关系模版',
  "title15027": '关联供应商',
  "title15028": '设置采购策略',
  "title15029": '按选中设置',
  "title15030": '全局设置',
  "title15031": '建议备货数量 = （采购频率 + 采购天数 + 安全天数） * 预测日销量 + 订单缺货数量 - （在途数量 + 可用库存）',
  "title15032": '触发条件：（采购天数 + 安全天数） * 预测日销量 > （可用库存 + 在途库存）',
  "title15033": '温馨提示：未设置采购天数/安全天数的商品SKU，则不生成采购建议。',
  "title15034": '采购频率',
  "title15035": '日常多少天采购一次',
  "title15036": '从采购下单到采购入库的预估时间，采购天数=生产天数+运输天数',
  "title15037": '为了避免由于采购产品未能按时到达而设置的缓冲天数',
  "title15038": '采购信息',
  "title15039": '采购建议生成机制：',
  "title15040": '默认供应商',
  "title15041": '请选择供应商',
  "title15042": '按固定',
  "title15043": '按比例',
  "title15044": '请先选择供应商',
  "title15045": '总体积：',
  "title15046": '总重量：',
  "title15047": '替换字符的【店铺',
  "title15048": '替换字符',
  "title15049": '费用分摊方式一共有五种，分摊方式的计算逻辑如下：',
  "title15050": '1、按数量分摊：分摊费用={对应费用}/总数量',
  "title15051": '2、按价格分摊：分摊费用=[(采购数量*单价) / 总的商品价值] x [ {对应费用} / 采购数量]',
  "title15052": '3、按体积分摊：分摊费用=[单个SKU体积/所有SKU总体积]x{对应费用}',
  "title15053": '4、按重量分摊：分摊费用=单个SKU重量/所有SKU总重量x{对应费用}',
  "title15054": '例如:运费按数量分摊时，则“对应费用”即为运费，计算逻辑为：运费/总数量。',
  "title15055": '什么是费用分摊方式？',
  "title15056": '此商品SKU在该仓库的现有库存数量;',
  "title15057": '现有库存=可用库存+冻结库存。',
  "title15058": '此商品SKU在该仓库的可用库存数量;',
  "title15059": '可用库存即为还可以分配给订单的库存数。',
  "title15060": '此商品SKU在该仓库的冻结数量;冻结库存',
  "title15061": '即为已经分配给订单(已经冻结)的库存数。',
  "title15062": '商品SKU当前的可用库存还支持售卖多少天，',
  "title15063": '可售天数=可用库存/预测日销量',
  "title15064": '5、税费按比例分摊时：对应费用 = 采购总额 x 税费比例',
  "title15065": '统计哪些订单数据？',
  "title15066": '地区分布仅对已配对的订单进行统计分析',
  "title15067": '更改盘点类型将清空已选。确认更改吗？',
  "title15068": '若商品SKU存在锁定库存，修改货架位将导致已打印的拣货单或面单上的货架位信息不准确。',
  "title15069": '不支持设置货架位的商品会被自动过滤，如组合SKU、三方仓商品等',
  "title15070": '新',
  "title15071": '旧',
  "title15072": '所选商品均不支持设置货架位，如组合SKU、三方仓商品等',
  "title15073": '未回款订单',
  "title15074": '平台调整订单',
  "title15075": '请确认订单是否回款，如果已回款建议同步订单后重新比对',
  "title15073a": '新增分组',
  "title15074a": '编辑分组',
  "title15075a": '是否确定删除分组',
  "title15076": '，删除后该分组数据将被清空。',
  "title15077": '删除分组',
  "title15078": '分组列表',
  "title15079": '最多只能选择10个分组',
  "title15080": '最少选择1个分组',
  "title15081": '分组商品列表',
  "title15082": '新增分组商品',
  "title15083": '第一步：下载达人模板，按照模板填写内容',
  "title15084": '第二步：点击上传已填好的达人模板',
  "title15085": '通用达人模板',
  "title15086": '全部',
  "title15087": '待建联',
  "title15088": '待申样',
  "title15089": '寄样中',
  "title15090": '待出单',
  "title15091": '合作中',
  "title15092": '合作完成',
  "title15093": '转移达人',
  "title15094": '建联达人',
  "title15095": '放入公海',
  "title15096": '加入黑名单',
  "title15097": '团队数据',
  "title15098": '跟进商务',
  "title15099": '转移达人',
  "title15100": '账号数据',
  "title15101": '合作数据',
  "title15102": '支付销量',
  "title15103": '成交商品',
  "title15104": '销售额环比',
  "title15105": '转出商务',
  "title15106": '接管商务',
  "title15107": '转移原因',
  "title15108": '转移成功后，该达人将立即与接管商务绑定，同时履约进度中的履约单会在当日终止，系统为新商务创建新的履约单，数据将从次日开始计算；',
  "title15109": '普通商务只能转移本人达人；',
  "title15110": '管理员可转移公司所有商务的达人。',
  "title15111": '转移成功后，该商务所属所有达人将立即与接管商务进行',
  "title15112": '绑定，同时交付中的原商务履约单会在当日终止，系统为',
  "title15113": '新商务创建新的履约单，数据将从次日开始计算。',
  "title15114": '请选择商务',
  "title15115": '批量转移',
  "title15116": '请选择至少选择一个标签',
  "title15117": '确认建联',
  "title15118": '跟进记录',
  "title15119": '建联',
  "title15120": '寄样',
  "title15121": '合作',
  "title15122": '联系方式是与达人建联的重要信息，填写后仅填写人以及管理员可见',
  "title15123": '建联成功',
  "title15124": '请选择转移达人',
  "title15125": '请选择接管商务',
  "title15126": '转移成功',
  "title15127": '转移后保留达人状态',
  "title15128": '勾选后，达人全部信息进行转移，履约单，寄样',
  "title15129": '单的归属直接调整为接管商务，达人状态不变。',
  "title15130": '批量放入公海',
  "title15131": '您选择了',
  "title15132": '位达人',
  "title15133": '是否确定批量放入公海？',
  "title15134": '放入公海成功',
  "title15135": '编辑资料',
  "title15136": '放入公海',
  "title15137": '申请寄样',
  "title15138": '拉黑达人',
  "title15139": '跟进合作',
  "title15140": '放入公海成功',
  "title15141": '跳过寄样直接合作',
  "title15142": '待审核',
  "title15143": '等待达人出单…',
  "title15144": '若出单的商品与商品库商品一致，系统将自动为您生成合作信息',
  "title15145": '您也可以选择',
  "title15146": '新建合作单',
  "title15147": '待交付',
  "title15148": '交付中',
  "title15149": '已结束',
  "title15150": '已取消',
  "title15151": '合作单',
  "title15152": '合作单详情',
  "title15153": '删除合作单',
  "title15154": '删除后该合作单的所有数据将被删除，对应商务的绩效也会减少，是否继续删除？',
  "title15155": '新增合作单',
  "title15156": '编辑合作单',
  "title15157": '取消创建',
  "title15158": '取消编辑',
  "title15159": '开始合作时间',
  "title15160": '交付时间',
  "title15161": '提交合作单',
  "title15162": '申请原因',
  "title15163": '请填写未寄样创建合作单的原因，以便管理员审核',
  "title15164": '合作商品',
  "title15165": '佣金模式',
  "title15166": '仅需要给达人支付商品佣金，无需支付坑位费等额外达人坑位费',
  "title15167": '一口价坑位费',
  "title15168": '除商品佣金外，需额外支付达人坑位费，坑位费在合作完成后全额支付给达人',
  "title15169": '请选择合作模式',
  "title15170": '合作模式',
  "title15171": '新增样品单',
  "title15172": '提交样品单',
  "title15173": '请添加商品',
  "title15174": '选择规格',
  "title15175": '请',
  "title15176": '样品单',
  "title15177": '删除样品单',
  "title15178": '是否删除该样品单',
  "title15179": '规格',
  "title15180": '订单号',
  "title15181": '是否确定放入公海',
  "title15182": '加入黑名单',
  "title15183": '是否确定加入黑名单',
  "title15184": '加入黑名单成功',
  "title15185": '取消寄样',
  "title15186": '批量建联达人',
  "title15187": '未输入联系方式的达人提交后将不会改变建联状态',
  "title15188": '编辑资料',
  "title15189": '请选择转出商务',
  "title15190": '请选择接管商务',
  "title15191": '转出商务与接管商务不可重复',
  "title15192": '请输入昵称或达人ID',
  "title15193": '拉黑原因',
  "title15194": '请输入把达人加入黑名单的原因，录入内容可在达人跟进记录查看',
  "title15195": '达人加入黑名单后，所有跟进记录自动关闭，并且不能跟进合作，可以在【黑名单】中恢复后重新跟进',
  "title15196": '请上传大小不超过',
  "title15197": '格式为',
  "title15198": '的图片，最多上传4张',
  "title15199": '暂无样品单',
  "title15200": '跳过寄样,',
  "title15201": '直接合作',
  "title15202": '位达人',
  "title15203": '批量',
  "title15204": '请输入员工姓名搜索',
  "title15205": '已提交样品申请单，等待主管审核',
  "title15206": '已通过样品单审核，等待发货。',
  "title15207": '样品单已发货，等待达人签收。',
  "title15208": '已签收样品，等待达人出单',
  "title15209": '驳回了样品单申请，驳回理由：',
  "title15210": '待发货',
  "title15211": '已发货',
  "title15212": '已签收',
  "title15213": '已关闭',
  "title15214": '去发货',
  "title15215": '如何在TikTok后台获取达人申样订单号？',
  "title15216": '合作联系人',
  "title15217": '达人坑位费',
  "title15218": '合作信息备注',
  "title15219": '达人状态流转单',
  "title15220": '已寄样',
  "title15221": '寄样备注',
  "title15222": '请填写寄样备注信息',
  "title15223": '申请收录成功，系统将在30分钟内为您收录数据，稍后您可通过搜索查看达人数据',
  "title15224": '成交数据',
  "title15225": '跟进记录数量',
  "title15226": '最近跟进记录',
  "title15227": '更多联系方式',
  "title15228": '审核未通过',
  "title15229": '结算销量',
  "title15230": '结算',
  "title15231": '直播场次',
  "title15232": '去寄样',
  "title15233": '请输入商务姓名',
  "title15234": '取消金额',
  "title15235": '该商品存在重复寄样',
  "title15236": '结算销量',
  "title15237": '结算销量',
  "title15238": '视频数量',
  "title15239": '橱窗商品数',
  "title15240": '取消订单数',
  "title15241": "直播",
  "title15242": "进行中",
  "title15243": "任务中心",
  "title15244": "合作详情",
  "title15245": "驳回理由",
  "title15246": "或者",
  "title15247": "达人建联",
  "title15248": "回填发货订单号",
  "title15249": "获取TikTok获取订单号",
  "title15250": "获取订单号流程",
  "title15251": "1、登录TikTok店铺，点击头部导航 [Affiliate Marketing]菜单",
  "title15252": "2、点击左下角[Sample Requests]菜单，筛选或找到对应达人订单，点击Approve同意达人申请",
  "title15253": "3、点击[Ready to Ship]切换页面，在列表找到对应达人订单，复制订单号。",
  "title15254": "请填写寄样备注信息",
  "title15255": "申样详情",
  "title15256": "样品单号",
  "title15257": "或",
  "title15258": "达人联建",
  "title15259": "重点关注",
  "title15260": "取消关注",
  "title15261": "关注成功",
  "title15262": "取消关注成功",
  "title15263": "直播中",
  "title15264": "是否有履约单",
  "title15265": "带货视频产生的订单总成交金额",
  "title15266": "带货视频产生的订单总销量",
  "title15267": "带货视频产生的订单总退款金额",
  "title15268": "达人在该带货视频中获得的预估佣金(根据商品公开佣金计算)",
  "title15269": "点赞/评论/收藏",
  "title15270": "所属媒介",
  "title15271": "直播时长",
  "title15272": "直播达人数",
  "title15273": "直播场次",
  "title15274": "视频达人数",
  "title15275": "预估佣金",
  "title15276": "数据统计",
  "title15277": "统计在",
  "title15278": "期间发布的带货视频数据",
  "title15279": "有出单",
  "title15280": "未出单",
  "title15281": "播放量",
  "title15282": "开播时间",
  "title15283": "视频时长",
  "title15284": "期间开播的达人直播间数据",
  "title15285": "观看人次",
  "title15286": "预估uv价值",
  "title15287": "未出单",
  "title15288": "未出单",
  "title15289": "未出单",
  "title15290": "批量收录",
  "title15291": "请输入商品名称或者商品ID",
  "title15292": "请输入SKUID或SKU或规格",
  "title15293": "黑名单",
  "title15294": "搜索信息",
  "title15295": "新建收藏夹",
  "title15296": "发送总览",
  "title15297": "邀约任务",
  "title15298": "邀约达人数",
  "title15299": "发送邮件数",
  "title15300": "邮件已读数",
  "title15301": "邮件回复数",
  "title15302": "邮件模板",
  "title15303": "群发邮件",
  "title15304": "请输入任务名称",
  "title15305": "收件人",
  "title15306": "删除收藏夹",
  "title15307": "删除收藏夹后无法撤销，确定删除收藏夹吗？",
  "title15308": "编辑收藏夹",
  "title15309": "新建收藏夹",
  "title15310": "收藏夹名称不能为空",
  "title15311": "收藏夹名称",
  "title15312": "请输入收藏夹名称",
  "title15313": "按创建时间排序",
  "title15314": "按收藏夹名称排序",
  "title15315": "按达人数量排序",
  "title15316": "发信达人",
  "title15317": "邀约发信",
  "title15318": "已发送",
  "title15319": "未发送",
  "title15320": "发送中",
  "title15321": "已经到底了",
  "title15322": "已送达",
  "title15323": "发送失败",
  "title15324": "已读",
  "title15325": "未读",
  "title15326": "回复状态",
  "title15327": "已回复",
  "title15328": "未回复",
  "title15329": "发送次数",
  "title15330": "收藏成功",
  "title15331": "选择收藏夹达人",
  "title15332": "添加收件人",
  "title15333": "移除全部",
  "title15334": "从收藏夹添加达人",
  "title15335": "近7天邀约记录",
  "title15336": "未邀约",
  "title15337": "已邀约",
  "title15338": "添加已选",
  "title15339": "排除7天内已邀约达人",
  "title15340": "有效收件人",
  "title15341": "共选择",
  "title15342": "位达人发送邮件",
  "title15343": "邮件模版",
  "title15344": "邮件主题",
  "title15345": "邮件内容",
  "title15346": "批量建联",
  "title15347": "本次建联",
  "title15348": "个达人，未建联",
  "title15349": "个达人，是否确认提交？",
  "title15350": "达人收录",
  "title15351": "请输入达人ID/达人主页链接进行搜索",
  "title15352": "加载中",
  "title15353": "很抱歉，我们目前无法找到您提供的达人，请核实信息是否正确",
  "title15354": "申请收录",
  "title15355": "第一步：下载建联模板，按照模板填写内容",
  "title15356": "批量建联模板",
  "title15357": "上传模板",
  "title15358": "* 表头名称不可更改，表头行不能删除",
  "title15359": "导入为",
  "title15360": "每次",
  "title15361": "未跟进",
  "title15362": "即",
  "title15363": "选择收藏夹",
  "title15364": "带货分类",
  "title15365": "近30天销售数量",
  "title15366": "近30天总销售额",
  "title15367": "热门视频",
  "title15368": "选择收藏夹",
  "title15369": "立即发送",
  "title15371": "定时发送",
  "title15372": "存草稿",
  "title15373": "预览邮件",
  "title15374": "邮件标题",
  "title15375": "邮件内容",
  "title15376": "批量寄样",
  "title15377": "选定的达人并非您跟进的达人，请选择您跟进的达人，再进行批量寄样操作",
  "title15378": "选择达人",
  "title15379": "预览",
  "title15380": "近30天每千次直播或视频播放所产生的销售额",
  "title15381": "平均销售额",
  "title15382": "近30天内每笔交易的平均销售额",
  "title15383": "达人列表",
  "title15384": "暂无达人信息",
  "title15385": "请先进行搜索",
  "title15386": "未录入",
  "title15387": "已录入",
  "title15388": "该达人已跟进",
  "title15389": "模板语言",
  "title15390": "邮件内容模板",
  "title15391": "是否删除该模版",
  "title15392": "删除模版",
  "title15393": "再次邀约",
  "title15394": "查看邮件",
  "title15395": "发送邮件数",
  "title15396": "送达邮件数",
  "title15397": "已读人数",
  "title15398": "回复人数",
  "title15399": "送达率",
  "title15400": "已读率",
  "title15401": "回复率",
  "title15402": "建联达人模板",
  "title15403": "您正在删除店铺",
  "title15404": "修改套餐",
  "title15405": "广告",
  "title15406": "授权该店铺广告数据权限后，您可以BI【广告分析】里查看该店铺的广告概览等数据",
  "title15407": "恭喜您，您已完成广告授权",
  "title15408": "广告授权成功",
  "title15409": "广告授权失败",
  "title15410": "店铺授权失败",
  "title15411": "标记已打印发票",
  "title15412": "标记未打印发票",
  "title15413": "打印发票",
  "title15414": "匹配方式",
  "title15415": "完全匹配",
  "title15416": "包含任意",
  "title15417": "营销",
  "title15418": "发生日期",
  "title15419": "设置费用类型",
  "title15420": "费用日期",
  "title15421": "选择费用日期",
  "title15422": "添加类型",
  "title15423": "系统类型",
  "title15424": "费用名称",
  "title15425": "编辑类型",
  "title15426": "是否删除该费用类型",
  "title15427": "费用类型设置",
  "title15428": "修改类型",
  "title15429": "费用{str}不能为空",
  "title15430": "按SKU ID",
  "title15431": "按店铺SKU",
  "title15432": "同比",
  "title15433": "占比是指项目费用总和占有效销售额的比例",
  "title15434": "环比是指与前一个时期相比的百分比变化幅度",
  "title15435": "同比是指与上年同期相比的百分比变化幅度",
  "title15436": "显示占比销售额",
  "title15437": "显示环比",
  "title15438": "显示同比",
  "title15439": "开发员",
  "title15440": "销售员",
  "title15441": "权限划分",
  "title15442": "可查看全部商品",
  "title15443": "可查看指定人员商品",
  "title15444": "批量设置销售员",
  "title15445": "批量设置开发员",
  "title15446": "覆盖",
  "title15447": "是否覆盖",
  "title15448": "权限划分",
  "title15449": "可查看全部商品",
  "title15450": "可查看指定人员商品",
  "title15451": "有权查看",
  "title15452": "请选择岗位",
  "title15453": "本地全部商品",
  "title15454": "的商品",
  "title15455": "请选择人员",
  "title15456": "用于管理用户商品权限，设置权限的用户才可以查看该商品",
  "title15457": "用于管理用户商品权限，设置权限的用户才可以查看该商品",
  "title15458": "居上",
  "title15459": "居左",
  "title15460": "二维码位置",
  "title15461": "按组合SKU拆分导出",
  "title15462": "日历视图",
  "title15463": "* 请按照数据模板的格式准备导入数据，模板中的表头名称不可更改，表头行不能删除",
  "title15464": "如何维护商品SPU？",
  "title15465": "1、请先在【商品SKU-多变种-属性管理】添加维护属性和属性值。",
  "title15466": "2、新增商品SPU可选择变种属性自动生成变种商品，SPU内所生成的商品SKU会在单SKU列表创建。",
  "title15467": "3、批量导入请参考表格案例数据填写，同一个SPU则复制多条相同SPU信息，修改其中变种属性和所包含的SKU信息即可。",
  "title15468": "多变种使用帮助信息",
  "title15469": "请给子账号分配仓库",
  "title15470": "设置默认",
  "title15471": "尺寸",
  "title15472": "商品条码",
  "title15473": "条码类型",
  "title15474": "二维码位置",
  "title15475": "按商品SKU",
  "title15476": "预览效果",
  "title15477": "实际效果以打印结果为准",
  "title15478": "请求失败",
  "title15479": "模版在【设置】-【打印设置】里添加修改",
  "title15480": "生成方式",
  "title15481": "标签打印",
  "title15482": "标签生成时请勿关闭当前窗口，生成完成后可下载标签PDF",
  "title15483": "您正在授权店铺",
  "title15484": "是否已完成店铺授权？",
  "title15485": "是否确认终止采购单",
  "title15486": "填写内容不符合运算规则，请重新填写",
  "title15487": "公式合法",
  "title15488": "长按可拖动编辑排序",
  "title15489": "确定删除{name}吗？",
  "title15490": "请先输入公式",
  "title15491": "列名称冲突",
  "title15492": "对账结果",
  "title15493": "是否登录",
  "title15494": "采集",
  "title15495": "批量采集",
  "title15496": "未授权店铺广告API接口",
  "title15497": "授权后支持采集近30天数据",
  "title15498": "每小时更新一次数据，更新时间：",
  "title15499": "按时统计是统计店铺当天24小时的广告数据表现",
  "title15500": "自动充值：",
  "title15501": "开",
  "title15502": "关",
  "title15503": "查看每日广告数据",
  "title15504": "请先授权店铺",
  "title15505": "广告余额",
  "title15506": "店铺广告余额",
  "title15507": "转化漏斗",
  "title15508": "展现量",
  "title15509": "点击查看转化漏斗图表",
  "title15510": "无结束日期",
  "title15511": "商品广告",
  "title15512": "店铺广告",
  "title15513": "自动竞价",
  "title15514": "手动竞价",
  "title15515": "标准",
  "title15516": "全站推广广告",
  "title15517": "每日预算",
  "title15518": "请输入计划",
  "title15519": "“回款对账”和“利润月报”数据每天更新一次，如需手动更新点击“更新数据”按钮。建议运营人员进行对账，财务人员进行复核。复核完成后，可在【利润月报】中查看最终结果。",
  "title15520": "查看使用帮助",
  "title15521": "月份",
  "title15522": "来源BI数据",
  "title15523": "来源平台数据",
  "title15524": "复核状态",
  "title15525": "最后更新时间",
  "title15526": "对账",
  "title15527": "复核",
  "title15528": "回款对账页面是协助用户上传店铺后台回款订单数据，并与心舰BI数据进行核对。用户可以处理不一致的订单数据，以确保数据一致性。通过复核完成数据对账，确保数据的准确性。",
  "title15529": "数据来源",
  "title15530": "BI数据（列表左侧），平台数据（列表右侧），未核对数据时平台数据为空。",
  "title15531": "操作步骤",
  "title15532": "1、点击【对账】按钮，打开回款对账窗口。您可以上传店铺回款数据进行核对。",
  "title15533": "2、系统将通过上传的数据进行比对，以便进行对账。",
  "title15534": "3、核对完成后，核对状态将显示为“一致”或“不一致”。可对”不一致”数据进行处理。",
  "title15535": "4、完成核对后您可以点击【复核】按钮确认数据核对完成，同时利润月报的复核状态将变更为“已复核”。",
  "title15536": "只统计已经回款的数据，不包含统计周期内未回款数据。计算公式：",
  "title15537": "有效订单回款金额+跨月调整金额-商品支出-营销费用-自定义费用=利润",
  "title15538": "计算公式",
  "title15539": "利润计算公式",
  "title15540": "有效订单回款金额+跨月调整金额-商品支出-营销费用-自定义费用=利润",
  "title15541": "当前取值方式",
  "title15542": "按广告花费的方式：统计每日广告投放产生的广告花费并进行计算",
  "title15543": "按店铺钱包充值的方式：统计店铺钱包当月充值的广告费用并进行计算。",
  "title15544": "设置方法：左下角设置-广告花费设置",
  "title15545": "只有取值方式设置为【按广告花费的方式】的时候，才有统计广告税费，在【设置】-【税费设置】设置过税费的地区，会自动计算税费，税费=花费*税率/（1-税率）",
  "title15546": "配置过税费的店铺会计算税费，在左下角设置里的税费设置配置",
  "title15547": "调整费用",
  "title15548": "平台调整（自动汇总）",
  "title15549": "平台已回款订单汇总数据和成本中心录入的自定义费用。",
  "title15550": "当查看利润月报数据时，先在回款对账页面完成对账的操作，核对后点击【复核】按钮确认数据核对完成，同时利润月报的复核状态将变更为“已复核”。之后如果需要维护其他费用请在成本中心维护。",
  "title15551": "1、点击【回款汇率设置】按钮，完成月报汇率的设置，避免汇率波动导致的利润偏高或偏低的问题",
  "title15552": "2、点击【新增自定义费用】添加其他自定义费用，或在成本中心维护自定义费用。",
  "title15553": "3、完成后以上操作后，点击【更新数据】完成数据更新。",
  "title15554": "无论是选择区间还是某一天的统计数据，都会以今日执行汇率为准。",
  "title15555": "需要注意的是，系统同步订单执行的是当时同步时间的汇率。",
  "title15556": "因此，在汇率切换后可能存在汇率偏差，不应仅凭此数据作为唯一比较标准。",
  "title15557": "利润月报计算利润时，利润和有效销售额只统计已经回款的数据，不包含统计周期内未回款数据。",
  "title15558": "当先前月份已经完成回款的订单，在当前月份有费用调整时，这种调整会被记录在'跨月调整费用'中",
  "title15559": "修改自定义费用",



  "magellan_600001": "家居用品",
  "magellan_600024": "厨房用具",
  "magellan_600154": "纺织与软装饰",
  "magellan_600942": "家用电器",
  "magellan_601152": "女装与内衣",
  "magellan_601303": "穆斯林时尚",
  "magellan_601352": "鞋子",
  "magellan_601450": "美容与个人护理",
  "magellan_601739": "手机与电子产品",
  "magellan_601755": "计算机与办公设备",
  "magellan_602118": "宠物用品",
  "magellan_602284": "婴儿与孕妇用品",
  "magellan_603014": "运动与户外",
  "magellan_604206": "玩具与爱好",
  "magellan_604453": "家具",
  "magellan_604579": "工具与五金",
  "magellan_604968": "家居装修",
  "magellan_605196": "汽车与摩托车",
  "magellan_605248": "时尚配饰",
  "magellan_700437": "食品与饮料",
  "magellan_700645": "健康",
  "magellan_801928": "书籍、杂志与音频",
  "magellan_802184": "儿童时尚",
  "magellan_824328": "男装与内衣",
  "magellan_824584": "箱包与旅行包",
  "magellan_834312": "虚拟产品",
  "magellan_856720": "旧货",
  "magellan_951432": "收藏品",
  "magellan_953224": "珠宝配饰及衍生品",
  "magellan_851848": "家居收纳",
  "magellan_851976": "浴室用品",
  "magellan_852104": "家居装饰",
  "magellan_852232": "家居护理用品",
  "magellan_852360": "洗衣工具及配件",
  "magellan_852488": "节日派对用品",
  "magellan_852616": "其他家居用品",
  "magellan_858504": "茶具与咖啡器具",
  "magellan_858632": "厨房刀具",
  "magellan_858760": "烧烤用品",
  "magellan_858888": "酒吧与酒具",
  "magellan_859016": "烘焙用品",
  "magellan_859144": "烹饪器具",
  "magellan_859272": "餐具与餐桌用品",
  "magellan_859400": "饮具",
  "magellan_859528": "厨房用具与小工具",
  "magellan_808328": "床上用品",
  "magellan_809992": "家用纺织品",
  "magellan_811016": "面料与缝纫用品",
  "magellan_844168": "厨房电器",
  "magellan_844808": "家居电器",
  "magellan_845064": "大型家用电器",
  "magellan_845320": "商用电器",
  "magellan_842248": "女士上衣",
  "magellan_842376": "女士下装",
  "magellan_842504": "女士裙装",
  "magellan_842632": "女士特殊服装",
  "magellan_842760": "女士套装与连体装",
  "magellan_842888": "女士内衣",
  "magellan_843016": "女士睡衣与休闲服",
  "magellan_601304": "头巾",
  "magellan_601310": "女士伊斯兰服装",
  "magellan_601325": "男士伊斯兰服装",
  "magellan_601331": "外套",
  "magellan_601339": "儿童伊斯兰服装",
  "magellan_601343": "伊斯兰配饰",
  "magellan_601348": "礼拜服饰与用品",
  "magellan_838920": "伊斯兰运动服",
  "magellan_839176": "朝觐用品",
  "magellan_900488": "女鞋",
  "magellan_900616": "男鞋",
  "magellan_900744": "鞋类配件",
  "magellan_848648": "彩妆",
  "magellan_848776": "护肤",
  "magellan_848904": "头发护理与造型",
  "magellan_849032": "手足与美甲护理",
  "magellan_849160": "沐浴与身体护理",
  "magellan_849288": "男士护理",
  "magellan_849416": "个人护理电器",
  "magellan_849544": "眼部与耳部护理",
  "magellan_849672": "鼻部与口腔护理",
  "magellan_849800": "女性护理",
  "magellan_856208": "香水",
  "magellan_981128": "特殊个人护理",
  "magellan_909064": "手机配件",
  "magellan_909192": "相机与摄影",
  "magellan_909320": "音频与视频",
  "magellan_909448": "游戏与游戏机",
  "magellan_909576": "智能与可穿戴设备",
  "magellan_909704": "教育设备",
  "magellan_978952": "通用配件",
  "magellan_984584": "平板与电脑配件",
  "magellan_995976": "手机与平板电脑",
  "magellan_824840": "台式电脑、笔记本电脑与平板电脑",
  "magellan_825352": "台式电脑与笔记本配件",
  "magellan_826760": "电脑配件",
  "magellan_828168": "数据存储与软件",
  "magellan_829192": "网络组件",
  "magellan_830344": "办公设备",
  "magellan_831112": "办公用品与耗材",
  "magellan_812168": "宠物食品",
  "magellan_812808": "宠物家具",
  "magellan_813960": "宠物服装",
  "magellan_815624": "宠物猫砂",
  "magellan_816392": "宠物美容",
  "magellan_818184": "宠物保健",
  "magellan_818696": "宠物配件",
  "magellan_819848": "水族用品",
  "magellan_821000": "爬行动物与两栖动物用品",
  "magellan_821896": "鸟类用品",
  "magellan_822792": "小动物用品",
  "magellan_1001992": "农场动物与家禽用品",
  "magellan_877320": "婴儿服装与鞋子",
  "magellan_877576": "婴儿旅行必需品",
  "magellan_877832": "哺乳与喂养",
  "magellan_878216": "婴儿家具",
  "magellan_878600": "婴儿安全",
  "magellan_878984": "婴儿玩具",
  "magellan_879112": "婴儿护理与健康",
  "magellan_879496": "配方奶粉与婴儿食品",
  "magellan_880008": "孕妇用品",
  "magellan_961928": "婴儿时尚配饰",
  "magellan_834568": "运动与户外服装",
  "magellan_834696": "运动鞋",
  "magellan_834824": "运动与户外配件",
  "magellan_834952": "球类运动装备",
  "magellan_835080": "水上运动装备",
  "magellan_835208": "冬季运动装备",
  "magellan_835336": "健身器材",
  "magellan_835464": "露营与徒步装备",
  "magellan_835592": "休闲与户外娱乐装备",
  "magellan_846224": "泳装、冲浪服与潜水服",
  "magellan_936712": "球迷用品",
  "magellan_859656": "玩偶与填充玩具",
  "magellan_859784": "教育玩具",
  "magellan_859912": "运动与户外游戏",
  "magellan_860040": "电动与遥控玩具",
  "magellan_860168": "游戏与拼图",
  "magellan_860296": "经典与新奇玩具",
  "magellan_860552": "乐器与配件",
  "magellan_951560": "DIY",
  "magellan_871048": "室内家具",
  "magellan_871176": "户外家具",
  "magellan_871304": "儿童家具",
  "magellan_871432": "商用家具",
  "magellan_871560": "电动工具",
  "magellan_871688": "手动工具",
  "magellan_871816": "测量工具",
  "magellan_871944": "园艺工具",
  "magellan_872072": "焊接设备",
  "magellan_872200": "工具收纳",
  "magellan_872328": "五金",
  "magellan_980488": "泵与管道",
  "magellan_808208": "太阳能与风能",
  "magellan_872456": "灯具与照明",
  "magellan_872584": "电气设备与配件",
  "magellan_872712": "厨房装置",
  "magellan_872840": "智能家居系统",
  "magellan_872968": "建筑材料",
  "magellan_873096": "浴室装置",
  "magellan_873224": "安全与防护",
  "magellan_873352": "园艺用品",
  "magellan_809488": "汽车替换部件",
  "magellan_809616": "摩托车部件",
  "magellan_847504": "摩托车",
  "magellan_929928": "汽车电子",
  "magellan_930056": "汽车外饰配件",
  "magellan_930184": "汽车内饰配件",
  "magellan_940296": "汽车维修工具",
  "magellan_940424": "汽车灯具",
  "magellan_940680": "四轮摩托车、房车与船只",
  "magellan_940808": "汽车清洗与保养",
  "magellan_940936": "摩托车配件",
  "magellan_810128": "发饰与假发",
  "magellan_843144": "缝纫面料",
  "magellan_888592": "婚礼配饰",
  "magellan_905224": "服装配饰",
  "magellan_905352": "眼镜",
  "magellan_905480": "手表与配件",
  "magellan_905608": "时尚珠宝与配饰",
  "magellan_905864": "发饰",
  "magellan_809744": "牛奶与乳制品",
  "magellan_914696": "啤酒、葡萄酒与烈酒",
  "magellan_914824": "饮料",
  "magellan_914952": "速食食品",
  "magellan_915080": "主食与烹饪必需品",
  "magellan_915208": "烘焙",
  "magellan_915336": "零食",
  "magellan_915464": "新鲜与冷冻食品",
  "magellan_700646": "食品补充剂",
  "magellan_924424": "医疗用品",
  "magellan_924552": "性健康",
  "magellan_949384": "非处方药物与治疗",
  "magellan_950792": "替代药物与治疗",
  "magellan_927112": "人文与社会科学",
  "magellan_985736": "杂志与报纸",
  "magellan_986760": "文学与艺术",
  "magellan_989320": "经济与管理",
  "magellan_989704": "儿童与婴幼儿书籍",
  "magellan_990216": "科学与技术",
  "magellan_992392": "生活与爱好",
  "magellan_992904": "教育与学校",
  "magellan_997384": "视频与音乐",
  "magellan_802312": "男童服装",
  "magellan_803592": "女童服装",
  "magellan_805128": "男童鞋类",
  "magellan_806024": "女童鞋类",
  "magellan_806792": "儿童时尚配饰",
  "magellan_839944": "男士上衣",
  "magellan_840072": "男士下装",
  "magellan_840328": "男士特殊服装",
  "magellan_840456": "男士内衣",
  "magellan_840584": "男士睡衣与休闲服",
  "magellan_840712": "男士套装与连体装",
  "magellan_902408": "女士包包",
  "magellan_902536": "男士包包",
  "magellan_902664": "行李与旅行包",
  "magellan_902792": "功能包",
  "magellan_902920": "包袋配件",
  "magellan_888720": "实体代金券",
  "magellan_996360": "通信服务",
  "magellan_856976": "收藏卡",
  "magellan_857104": "时尚配饰",
  "magellan_857232": "包袋",
  "magellan_857360": "鞋类",
  "magellan_857488": "男装",
  "magellan_857616": "女装",
  "magellan_857744": "翻新手机与电子产品",
  "magellan_864656": "行李与旅行",
  "magellan_865296": "手表",
  "magellan_809872": "当代文化收藏品",
  "magellan_810000": "交易卡与配件",
  "magellan_937736": "体育收藏品",
  "magellan_952712": "收藏币与货币",
  "magellan_953352": "娱乐",
  "magellan_954888": "铂金与克拉金",
  "magellan_955016": "黄金",
  "magellan_955144": "白银",
  "magellan_955272": "钻石",
  "magellan_955400": "天然水晶",
  "magellan_961800": "人造水晶",
  "magellan_963848": "玉石",
  "magellan_964104": "红宝石、蓝宝石与绿宝石",
  "magellan_964232": "半宝石",
  "magellan_964360": "人造宝石",
  "magellan_964488": "珍珠",
  "magellan_964616": "琥珀",
  "magellan_964744": "蜜蜡",
  "showcase_gmv": "橱窗销售额",
  "video_gmv": "视频销售额",
  "live_gmv": "直播销售额",
  "Beauty & Personal Care": "美容及个人护理",
  "Computers & Office Equipment": "电脑及办公设备",
  "Womenswear & Underwear": "女装和内衣",
  "Food & Beverages": "视频饮料",
  "Kitchenware": "厨房用具",
  "Home Supplies": "家居用品",



  // 2024.3.5 物流功能新增翻译
  "可按物流方式分别设置揽收方式":"可按物流方式分别设置揽收方式。",
  "物流方式":"物流方式",
  "物流名称":"物流名称",
  "状态":"状态",
  "操作":"操作",
  "设置":"设置",
  "揽货方式":"揽货方式",
  "快递取件":"快递取件",
  "自行寄件":"自行寄件",
  "请选择揽收方式":"请选择揽收方式",
  "全部":"全部",
  "印尼":"印尼",
  "马来西亚":"马来西亚",
  "菲律宾":"菲律宾",
  "泰国":"泰国",
  "越南":"越南",
  "中国台湾":"中国台湾",
  "新加坡":"新加坡",
  "线上物流":"线上物流",
  "操作成功！":"操作成功！",
  "打印拣货单(含汇总)":"打印拣货单(含汇总)",
  "商品SKU":"商品SKU",
  "产品SKU":"产品SKU",
  "店铺SKU":"店铺SKU",
  "包裹数量":"包裹数量",
  "SKU数量":"SKU数量",
  "SKU编号":"SKU编号",
  "货品总数":"货品总数",
  "打印时间":"打印时间",
  "货品信息":"货品信息",
  "变种名称":"变种名称",
  "货架位":"货架位",
  "数量":"数量",
  "包裹信息":"包裹信息",
  "包裹号":"包裹号",
  "总计":"总计",
  "打印":"打印",
  "拣货单":"拣货单",
  "包裹数量":"包裹数量",
  "货品总数":"货品总数",
  "打单时间":"打单时间",
  "货品信息":"货品信息",
  "买家信息":"买家信息",
  "备注信息":"备注信息",
  "总数":"总数",
  "订单号":"订单号",
  "运单号":"运单号",
  "物流方式":"物流方式",
  "店铺名称":"店铺名称",
  "货品总数":"货品总数",
  "打印面单和配货单":"打印面单和配货单",
  "稳定出单的商品，销量下滑":"稳定出单的商品，销量下滑",
  "爆款商品":"爆款商品",
  "出单较为稳定，销量暴涨的潜力商品":"出单较为稳定，销量暴涨的潜力商品",
  "稳定出单的商品，销量持续上涨":"稳定出单的商品，销量持续上涨",
  "上架时间长，销量较低":"上架时间长，销量较低",
  "高退货率":"高退货率",
  "高差评率":"高差评率",
  "低回复率":"低回复率",
  "取消率过高":"取消率过高",
  "样品费用":"样品费用",
  "空包费用":"空包费用",
  "测评佣金":"测评佣金",
  "测评付款佣金/手续费":"测评付款佣金/手续费",
  "修改自定义费用":"修改自定义费用",
  "小件包裹":"小件包裹",
  "测试刷单":"测试刷单",
  "漏单":"漏单",
  "预售":"预售",
  "海运":"海运",
  "不发":"不发",
  "补寄":"补寄",
  "已采":"已采",
  "未分组":"未分组",
  "测评佣金":"测评佣金",
  "测评付款佣金/手续费":"测评付款佣金/手续费",
  "提现支出":"提现支出",
  "线下售后退款":"线下售后退款",
  "线下补发运费":"线下补发运费",
  "线下补发成本":"线下补发成本",
  "退件处理费":"退件处理费",
  "活动包裹费用":"活动包裹费用",
  "其他杂费":"其他杂费",
  "仓库费用":"仓库费用",
  "未推送":"未推送",
  "推送中":"推送中",
  "推送失败":"推送失败",
  "待盘点":"待盘点",
  "按仓库":"按仓库",
  "按商品SKU":"按商品SKU",
  "调拨计划":"调拨计划",
  "不分摊":"不分摊",
  "按价格分摊":"按价格分摊",
  "按数量分摊":"按数量分摊",
  "按体积分摊":"按体积分摊",
  "待出库":"待出库",
  "已出库":"已出库",
  "采购入库":"采购入库",
  "销售出库":"销售出库",
  "手动入库":"手动入库",
  "手动出库":"手动出库",
  "回滚出库":"回滚出库",
  "导入入库":"导入入库",
  "盘点入库":"盘点入库",
  "盘点出库":"盘点出库",
  "调拨入库":"调拨入库",
  "调拨出库":"调拨出库",
  "取消调拨入库":"取消调拨入库",
  "三方仓同步入库":"三方仓同步入库",
  "三方仓同步出库":"三方仓同步出库",
  "退货入库":"退货入库",
  "到付":"到付",
  "现结":"现结",
  "分期付款":"分期付款",
  "周结":"周结",
  "月结":"月结",
  "到期支付":"到期支付",
  "未扣除广告费后的利润":"未扣除广告费后的利润",
  "排除测评搁置取消未付款的订单量":"排除测评+搁置+取消+未付款的订单量",
  "排除测评搁置取消未付款的销量":"排除测评+搁置+取消+未付款的销量",
  "有效销售额释义":"有效销售额=买家支付+平台补贴+运费补贴（排除测评+搁置+取消+未付款的销售额）",
  "有效销售额释义1":"有效销售额=排除测评+搁置+取消+未付款的销售额",
  "销售额释义":"销售额=已下单的总销售额（未取消订单销售额+取消订单销售额 ）",
  "订单量=已下单的订单数量":"订单量=已下单的订单数量",
  "有效订单量释义":"有效订单量=排除测评+搁置+取消+未付款的订单量",
  "共条":"共{cont}条",
  "系统消息":"系统消息",
  "今日不再提醒":"今日不再提醒",
  "最多置顶5个":"最多置顶5个",
  "去授权或删除":"去授权或删除",
  "已下单的订单数量 （包含取消和退款订单）":"已下单的订单数量 （包含取消和退款订单）",
  "已下单的总销售额（未取消订单销售额+取消订单销售额 ）":"已下单的总销售额（未取消订单销售额+取消订单销售额 ）",
  "排除测评+搁置+取消+未付款的订单量":"排除测评+搁置+取消+未付款的订单量",
  "排除测评+搁置+取消+未付款的销售额":"排除测评+搁置+取消+未付款的销售额",
  "仅统计已付款的订单销售数量":"仅统计已付款的订单销售数量",
  "已取消订单数量":"已取消订单数量",
  "已下单的用户数量（按买家姓名/邮箱去重）":"已下单的用户数量（按买家姓名/邮箱去重）",
  "付款客户数":"付款客户数",
  "已退款的用户数（包含部分退款）":"已退款的用户数（包含部分退款）",
  "退款客户数":"退款客户数",
  "销售额/客户数":"销售额/客户数",
  "退款金额":"退款金额",
  "店铺详情":"店铺详情",
  "为避免使用及数据分析受影响，请尽快处理过期的店铺数据，您可以":"为避免使用及数据分析受影响，请尽快处理过期的店铺数据，您可以",
  "个店铺授权已过期":"{cont}个店铺授权已过期",
  "发现新版本，点击更新完成升级。体验新版本":"发现新版本，点击更新完成升级。体验新版本",
  "每次最多可选取50条数据进行打印。":"每次最多可选取50条数据进行打印。",
  "导出已选商品": "导出已选商品",
  "导出全部页商品": "导出全部页商品",
  "导出SKU配对关系": "导出SKU配对关系",
  "显示下架商品":"显示下架商品",
  "tips001": '拆单操作的要求和前提条件',
  "tips002": 'Shopee拆单',
  "tips003": '暂不支持，需前往店铺后台进行拆单操作',
  "tips004": 'Lazada、TikTok拆单公共前提',
  "tips005": '订单类型为整单且为待处理',
  "tips006": 'Lazada原订单SKU种类≥1（不包含追加商品和已取消的商品）',
  "tips007": 'Tiktok原订单SKU种类≥2（不包含追加商品和已取消的商品）',
  "tips008": '拆单各平台前提',
  "tips009": 'Lazada订单',
  "tips010": '订单买家指定物流非',
  "tips011": '揽收方式',
  "tips012": '平台状态',
  "tips013": 'TikTok订单',
  "tips014": '支付方式',
  "tips015": '平台状态',
  "tips016": '新包裹',
  "tips017": '未推送的订单是指在其他平台安排，但尚未在本平台安排的订单，本平台不会推送这些订单，请确认订单在其他平台没有操作推送到指定的三方仓，避免重复推送造成重复发货。',
  "非追加产品主包裹至少保留一个":"非追加产品主包裹至少保留一个",
  "扫描或输入包裹号": "扫描或输入包裹号/运单号",
  "拆单成功": "拆单成功",
  "已确定订单销售额除以已确定订单订单数": "已确定订单销售额除以已确定订单订单数",
  "访客数": "访客数",
  "订单数": "订单数",
  "销售额": "销售额",
  "平台回款金额":"平台回款金额",
  "买家实付金额":"买家实付金额",
  "优惠券金额":"{platform}优惠券金额",
  "币抵扣":"{platform}币抵扣",
  "平台回款金额":"平台回款金额",
  "3PL运费折扣":"3PL运费折扣",
  "运费返还":"{platform}运费返还",
  "卖家运费支出":"卖家运费支出",
  "运费成本":"运费成本",
  "商品价格":"商品价格",
  "商品明细":"商品明细",
  "对应账单":"对应账单 {name}",
  "库存列表维护的商品成本":"库存列表维护的商品成本",
  "库存列表维护的打包费用":"库存列表维护的打包费用",
  "调整费用":"调整费用",
  "退款费用":"退款费用",
  "货款":"货款",
  "Lazada折扣":"Lazada折扣",
  "卖家优惠券":"卖家优惠券",
  "多件多折优惠":"多件多折优惠",
  "超级联盟":"超级联盟",
  "包邮促销费用":"包邮促销费用",
  "支付手续费":"支付手续费",
  "TikTok商品折扣":"TikTok商品折扣",
  "达人合作伙伴佣金":"达人合作伙伴佣金",
  "SFP服务费":"SFP服务费",
  "货款":"货款",
  "环比":"环比",
  "本地状态是退货退款中和退货订单或退款金额大于0的退款金额":"本地状态是退货退款中和退货订单或退款金额大于0的退款金额",
  "退款金额=本地状态是退货退款中和退货订单或退款金额大于0的退款金额":"退款金额=本地状态是退货退款中和退货订单或退款金额大于0的退款金额",
  "退货订单量=本地状态是退货退款中和退货订单或退款金额大于0的订单数量":"退货订单量=本地状态是退货退款中和退货订单或退款金额大于0的订单数量",
  "有效订单量=排除测评+搁置+取消+未付款的订单量":"有效订单量=排除测评+搁置+取消+未付款的订单量",
  "有效销售额=排除测评+搁置+取消+未付款的销售额":"有效销售额=排除测评+搁置+取消+未付款的销售额",
  "销售额=已下单的总销售额（未取消订单销售额+取消订单销售额 ）":"销售额=已下单的总销售额（未取消订单销售额+取消订单销售额 ）",
  "订单量=已下单的订单数量":"订单量=已下单的订单数量",
  "共{cont}条":"共{cont}条",
  "高级版":"高级版",
  "发现新版本，是否更新":"发现新版本，是否更新",
  "您有62个店铺授权过期，请点击重新授权":"您有62个店铺授权过期，请点击重新授权",
  "亏损商品":"亏损商品",
  "利润":"利润",
  "利润偏低":"利润偏低",
  "利润率":"利润率",
  "销量暴跌":"销量暴跌",
  "稳定出单的商品，销量下滑":"稳定出单的商品，销量下滑",
  "销量环比":"销量环比",
  "出单较为稳定，销量暴涨的潜力商品":"出单较为稳定，销量暴涨的潜力商品",
  "稳定出单的商品，销量持续上涨":"稳定出单的商品，销量持续上涨",
  "销量":"销量",
  "销量环比":"销量环比",
  "上架时间长，销量较低":"上架时间长，销量较低",
  "高退货率":"高退货率",
  "退货率":"退货率",
  "高差评率":"高差评率",
  "差评率":"差评率",
  "低回复率":"低回复率",
  "回复率":"回复率",
  "回应时间":"回应时间",
  "回应时间>1小时":"回应时间>1小时",
  "逾期发货率过高":"逾期发货率过高",
  "逾期发货率":"逾期发货率",
  "取消率过高":"取消率过高",
  "取消率":"取消率",
  "联系我们":"联系我们",
  "修改自定义费用":"修改自定义费用",
  "快递取件":"快递取件",
  "自行取件":"自行取件",
  "未分组":"未分组",
  "更新时间":"更新时间",
  "默认仓库":"默认仓库",
  "商品SKU推送失败:2000035000: 授权失败":"商品SKU推送失败:2000035000: 授权失败",
  "商品SKU推送失败:2000035000: 授权失败,请检查appKey和appSecret是否正确":"商品SKU推送失败:2000035000: 授权失败,请检查appKey和appSecret是否正确",
  "调拨计划":"调拨计划",
  "在途中":"在途中",
  "部分收货":"部分收货",
  "已完成":"已完成",
  "已取消":"已取消",
  "待出库":"待出库",
  "已出库":"已出库",
  "待出库":"待出库",
  "已入库":"已入库",
  "订单 销售出库1":"订单 销售出库1",
  "联系我们":"联系我们",
  "采购订单推送失败:2000035000: 授权失败":"采购订单推送失败:2000035000: 授权失败",
  "采购订单推送失败:2000035000: 客户SKU 不存在。":"采购订单推送失败:2000035000: 客户SKU 不存在。",
  "采购订单推送失败:2000035000: Item not exist: sku = Testing Product K, name = null;":"采购订单推送失败:2000035000: Item not exist: sku = Testing Product K, name = null;",
  "AI模板管理":"AI模板管理",
  "修改":"修改",
  "AI模板分类管理":"AI模板分类管理",
  "自由会话":"自由会话",
  "模板会话":"模板会话",
  "shopee listing写作与优化6。":"shopee listing写作与优化6。",
  "根据提供的关键词、类目、商品卖点信息，为你生成地道流畅的语言表达，提升商品曝光量；使用客观和中立的语言，避免夸张和绝对化的词汇。":"根据提供的关键词、类目、商品卖点信息，为你生成地道流畅的语言表达，提升商品曝光量；使用客观和中立的语言，避免夸张和绝对化的词汇。",
  "商品特性的条数":"商品特性的条数",
  "商品关键词":"商品关键词",
  "商品品牌":"商品品牌",
  "商品类目":"商品类目",
  "商品卖点":"商品卖点",
  "我的收藏":"我的收藏",
  "知识库":"知识库",
  "发现新版本，点击更新完成升级。体验新版本":"发现新版本，点击更新完成升级。体验新版本",
  "系统消息":"系统消息",
  "今日不再提醒":"今日不再提醒",
  "去授权或删除":"去授权或删除",
  "为避免使用及数据分析受影响，请尽快处理过期的店铺数据，您可以":"为避免使用及数据分析受影响，请尽快处理过期的店铺数据，您可以",
  "已过期的店铺":"已过期的店铺",
  "最多置顶5个":"最多置顶5个",
  "综合分析":"综合分析",
  "受限页面":"受限页面",
  "已下单的订单数量 （包含取消和退款订单）":"已下单的订单数量 （包含取消和退款订单）",
  "已下单的用户数量（按买家姓名/邮箱去重）":"已下单的用户数量（按买家姓名/邮箱去重）",
  "付款客户数":"付款客户数",
  "退款客户数":"退款客户数",
  "已退款的用户数（包含部分退款）":"已退款的用户数（包含部分退款）",
  "已下单的用户数量（按买家姓名/邮箱去重）":"已下单的用户数量（按买家姓名/邮箱去重）",
  "已取消订单数量":"已取消订单数量",
  "仅统计已付款的订单销售数量":"仅统计已付款的订单销售数量",
  "销售额/客户数":"销售额/客户数",
  "退款金额":"退款金额",
  "店铺销售详情":"店铺销售详情",
  "店铺详情":"店铺详情",
  "商品销量统计":"商品销量统计",
  "订单日志":"订单日志",
  "推送调拨单":"推送调拨单",
  "千仓":"千仓",
  "请输入服务商提供的客户编码":"请输入服务商提供的客户编码",
  "操作成功，请查看推送状态":"操作成功，请查看推送状态",
  "请先选择接收仓库":"请先选择接收仓库",
  "数据处理时请勿关闭当前窗口，处理完成后可关闭窗口":"数据处理时请勿关闭当前窗口，处理完成后可关闭窗口",
  "是否确认删除商品SKU为的库存":"是否确认删除商品SKU为{sku}的库存",
  "仓库为三方仓调拨单不支持手工收货，系统会自动同步三方仓的收货结果。":"仓库为三方仓调拨单不支持手工收货，系统会自动同步三方仓的收货结果。",
  "非追加产品主包裹至少保留一个":"非追加产品主包裹至少保留一个",
  "扮演":"扮演",
  "商家备注":"商家备注",
  "预估利润率":"预估利润率",
  "店铺库存":"店铺库存",
  "店铺产品信息":"店铺产品信息",
  "产品链接":"产品链接",
  "移除预警":"移除预警",
  "打开链接":"打开链接",
  "至少保留一个":"至少保留一个",
  "是否样品":"是否样品",
  "样品":"样品",
  "非样品":"非样品",
  "批量添加标记":"批量添加标记",
  "添加标记":"添加标记",
  "安排":"安排",
  "什么是商品完整性":"什么是商品完整性",
  "什么是店铺库存预警":"什么是店铺库存预警",
  "预警机制":"预警机制",
  "您当前套餐不能购买加油包服务，请升级套餐后再试":"您当前套餐不能购买加油包服务，请升级套餐后再试",
  "操作指引":"操作指引",
  "参考价":"参考价",
  "移除预警提示":"移除预警提示",
  "数据更新频率":"数据更新频率",
  "价格不能为负数":"价格不能为负数",
  "店铺库存预警帮助信息":"店铺库存预警帮助信息",
  "确定移除SKUID--id--的产品的预警提示？":"确定移除SKUID：{id}的产品的预警提示？",
  "系统会列出所有店铺产品库存为0的商品。":"系统会列出所有店铺产品库存为0的商品。",
  "根据预警信息，快速处理店铺库存不足产品，确保库存完备。":"根据预警信息，快速处理店铺库存不足产品，确保库存完备。",
  "处理完库存后，可通过操作移除预警提示，有效管理库存信息":"处理完库存后，可通过操作移除预警提示，有效管理库存信息",
  "系统每小时更新一次数据，有助于及时反映最新的库存情况。同时，将移除库存大于零的商品，确保预警系统的有效性和准确性。":"系统每小时更新一次数据，有助于及时反映最新的库存情况。同时，将移除库存大于零的商品，确保预警系统的有效性和准确性。",
  "商品完整性是指商品的重量和尺寸信息是否有维护。若其中任一项未维护，则商品被视为不完整。":"商品完整性是指商品的重量和尺寸信息是否有维护。若其中任一项未维护，则商品被视为不完整。",
  "预占库存":"预占库存",
  "在途库存":"在途库存",
  "选择标记":"选择标记",
  "关联SKU数量":"关联SKU数量",
  "采购次数":"采购次数",
  "关联商品":"关联商品",
  "是否加工厂商":"是否加工厂商",
  "上次采购价":"上次采购价",
  "最低采购价":"最低采购价",
  "历史采购记录":"历史采购记录",
  "已设为默认":"已设为默认",
  "采购记录":"采购记录",


  "驳回审核":"驳回审核",
  "驳回备注":"驳回备注",
  "确定驳回采购单号为":"确定驳回采购单号为",
  "的采购单":"的采购单",
  "审核操作":"审核操作",
  "备注说明":"备注说明",
  "最小采购数":"最小采购数",
  "批量":"批量",
  "草稿箱":"草稿箱",
  "个数":"个数",
  "待审核":"待审核",
  "总分摊费":"总分摊费",
  "请输入驳回原因":"请输入驳回原因",
  "是否确认删除采购单号为no的采购单?":"是否确认删除采购单号为{no}的采购单?",
  "设置一个最小采购商品数量，建议数量小于设置最小采购数时，则不生成采购建议":"设置一个最小采购商品数量，建议数量小于设置最小采购数时，则不生成采购建议",
  "确定审核采购单号为no的采购单？":"确定审核采购单号为{no}的采购单？",
  "共计个商品":"共计{count}个商品",
  "累计跟进":"累计跟进",
  "跟进人员":"跟进人员",
  "总计数量":"总计数量",
  "合作记录":"合作记录",
  "拉黑后是否出单":"拉黑后是否出单",
  "拉黑后GMV":"拉黑后GMV",
  "添加跟进记录":"添加跟进记录",
  "合作历史":"合作历史",
  "达人地区":"达人地区",
  "达人标签":"达人标签",
  "达人简介":"达人简介",
  "粉丝年龄":"粉丝年龄",
  "粉丝性别":"粉丝性别",
  "打开tiktok扫码查看":"打开tiktok扫码查看",
  "带货方式":"带货方式",
  "直播场均销售额":"直播场均销售额",
  "拉黑操作人":"拉黑操作人",
  "拉黑原因":"拉黑原因",
  "计佣金额":"计佣金额",
  "归属人":"归属人",
  "结算时间":"结算时间",
  "结算销售额":"结算销售额",
  "付款时间":"付款时间",
  "佣金比例":"佣金比例",
  "全部媒介":"全部商务",
  "媒介":"商务",
  "批量导入":"批量导入",
  "批量审核":"批量审核",
  "批量发货":"批量发货",
  "重复寄样":"重复寄样",
  "领样商品":"领样商品",
  "样品状态":"样品状态",
  "寄样对象":"寄样对象",
  "寄样单":"寄样单",
  "合作商品数":"合作商品数",
  "寄样商品":"寄样商品",
  "样品回收":"样品回收",
  "寄样规格":"寄样规格",
  "合作信息":"合作信息",
  "回复":"回复",
  "近30日销量":"近30日销量",
  "近30日销售额":"近30日销售额",
  "30日销量":"30日销量",
  "请输入三方仓服务商提供的客户编码":"请输入三方仓服务商提供的客户编码",
  "请输入三方仓服务商授权的公司前缀":"请输入三方仓服务商授权的公司前缀",
  "在未选择店铺和仓库筛选项时，默认导入全店铺和全仓库商品成本":"在未选择店铺和仓库筛选项时，默认导入全店铺和全仓库商品成本",
  "预估":"预估",
  "数据更新时间":"数据更新时间",
  "申样时间":"申样时间",
  "视频数据":"视频数据",
  "视频GPM":"视频GPM",
  "平均视频浏览量":"平均视频浏览量",
  "平均视频点赞数":"平均视频点赞数",
  "平均视频评论数":"平均视频评论数",
  "直播数据":"直播数据",
  "直播场次":"直播场次",
  "平均直播观看数":"平均直播观看数",
  "平均直播点赞数":"平均直播点赞数",
  "平均直播评论数":"平均直播评论数",
  "挂链商品数":"挂链商品数",
  "直播销量":"直播销量",
  "直播销售额":"直播销售额",
  "视频销量":"视频销量",
  "视频销售额":"视频销售额",
  "视频信息":"视频信息",
  "销量分析":"销量分析",
  "带货商品":"带货商品",
  "达人粉丝画像":"达人粉丝画像",
  "女性":"女性",
  "男性":"男性",
  "样品单状态":"样品单状态",
  "数据总览":"数据总览",
  "样品单列表":"样品单列表",
  "订单列表":"订单列表",
  "商品列表":"商品列表",
  "来自TikTok商户后台达人广场首页简介的公开联系方式":"来自TikTok商户后台达人广场首页简介的公开联系方式",
  "该达人无简介联系方式":"该达人无简介联系方式",
  "手动录入联系方式":"手动录入联系方式",
  "通过我的达人编辑主动录入":"通过我的达人编辑主动录入",
  "该达人无手动联系方式":"该达人无手动联系方式",
  "建联成功联系方式":"建联成功联系方式",
  "建联成功录入的联系方式":"建联成功录入的联系方式",
  "该达人无建联联系方式":"该达人无建联联系方式",
  "导入为":"导入为",



  "delShopTipsTitle":"您即将删除店铺,",
  "delShopTips": `请注意，删除店铺后将导致以下情况：<br> · 店铺的商品信息将被永久删除且不可恢复。<br> · 店铺的订单信息将被清空，无法恢复。<br> · 店铺的相关设置信息将被永久删除，不可恢复。<br> 您确定要继续删除吗？`,
  "empowerTip":`您是否想重新授权店铺，若是请点击"重新授权"进行授权。`,

  "白名单导入提示1": '第一步：下载白名单模板，按照模板填写内容',
  "白名单导入提示2": '第二步：点击上传已填好的白名单模板',
  "请输入达人ID": '请输入达人ID',
  "请输入达人ID或达人昵称": '请输入达人ID或达人昵称',
  "shopeeCoinsRedeemed":"Shopee Coins Redeemed"
}
